import { useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./styles.module.css";
import EditPointsWorldContainer from "./EditPointsWorldContainer";

export default function EditCanvas(props) {
  const [canvasDecider, setCanvasDecider] = useState(550);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  useEffect(() => {
    if (!isMobile) {
      setCanvasDecider(550);
    }
    if (isMobile) {
      setCanvasDecider(400);
    }
  }, [isMobile]);
  return (
    <div
      style={{
        // paddingTop: "20px",
        // visibility: "hidden",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        width: canvasDecider,
        height: canvasDecider,
      }}
    >
      <Canvas
        className={styles.grabbable}
        style={{
          outline: "none !important",
        }}
        shadows
        dpr={[1, 2]}
        camera={{
          position: [0, 0, 10],
          // position: cameraPositionDecider,
          near: 0.001,
          far: 2000,
        }}
        linear
        flat
      >
        {/* <color attach="background" args={[color]} />    */}
        <color attach="background" args={["#000000"]} />
        {/* <Leva fill /> */}

        <>
          <ambientLight intensity={2} />

          <OrbitControls
            enablePan={false}
            // enableDamping={true}
            // dampingFactor={-0.01}
            minDistance={150}
            maxDistance={1000}
            rotateSpeed={0.4}
            zoomSpeed={0.4}
            // autoRotate
            // autoRotateSpeed={4}
          />
        </>

        <EditPointsWorldContainer
          palette={props.palette}
          bgProp={props.background}
          useThisImage={props.image}
          style={props.effects}
        />
      </Canvas>
    </div>
  );
}
