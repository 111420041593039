import * as React from "react";
import * as THREE from "three";
import { useEffect, useRef, useState } from "react";
import glsl from "babel-plugin-glsl/macro";
import { Point, Points, shaderMaterial, Stars } from "@react-three/drei";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { browserName } from "react-device-detect";
import { suspend } from "suspend-react";
import useStore2 from "./store2";

import * as misc from "maath/misc";
const hundredColors = require("./Mantine/hundredColors.json");
const rotationAxis = new THREE.Vector3(0, 1, 0).normalize();
const q = new THREE.Quaternion();

let firstPalette = hundredColors[1];
firstPalette = firstPalette.map((color) => new THREE.Color(color));

const MyPointsMaterial = shaderMaterial(
  {
    u: 1,
    amplitude: 0.5,
  },
  /* glsl */ `
    attribute float size;
    attribute vec3 color;

    varying vec3 vColor;

    uniform float amplitude;

    attribute vec3 vertexColor;

    varying vec4 varColor;

    void main()
    {
    vColor = color;
    varColor = vec4(vertexColor, 1.0);

    vec4 pos = vec4(position, 1.0);
    pos.z *= amplitude;

    vec4 mvPosition = modelViewMatrix * pos;

    gl_PointSize = 1.0;
    gl_Position = projectionMatrix * mvPosition;
    }

  `,
  /* glsl */ `
    varying vec3 vColor;

    void main() {
      gl_FragColor = vec4( vColor, 1.0 );

    //   #include <tonemapping_fragment>
    //   #include <encodings_fragment>
    }
  `
);

extend({ MyPointsMaterial });

// @ts-ignore
const makeBuffer = (...args) => Float32Array.from(...args);

export default function PointsWorld6(props) {
  const enabledAudio = useStore2((state) => state.enabledAudio);
  useEffect(() => {
    if (props.palette) {
      let palette = hundredColors[props.palette];
      palette = palette.map((color) => new THREE.Color(color));
      gradientRef.current.material.uniforms.uColor.value = palette;
    }
  }, [props.palette]);
  const [dreamVisible, setDreamVisible] = useState(false);
  const [astraVisible, setAstraVisible] = useState(false);
  const [amethystVisible, setAmethystVisible] = useState(true);
  useEffect(() => {
    // console.log(props.bgProp);
    // if (props.bgProp === "default") {
    //   setDreamVisible(false);
    //   setAstraVisible(false);
    //   setAmethystVisible(false);
    // }
    // if (props.bgProp === "dream") {
    //   setDreamVisible(true);
    //   setAstraVisible(false);
    //   setAmethystVisible(false);
    // }
    // if (props.bgProp === "astra") {
    //   setAstraVisible(true);
    //   setDreamVisible(false);
    //   setAmethystVisible(false);
    // }
    // if (props.bgProp === "amethyst") {
    //   setAmethystVisible(true);
    //   setDreamVisible(false);
    //   setAstraVisible(false);
    // }
    // console.log(props.bgProp);
  }, [props.bgProp]);

  var x, y;

  var weights = [0.2126, 0.7152, 0.0722];
  var c = 0;
  x = props.imageWidth * -0.5;
  y = props.imageHeight * 0.5;
  var zRange = 400;
  var vertices2 = [];

  const imgRef = React.useRef();

  const [imageSwitch, setImageSwitch] = React.useState("./rufus.jpeg");

  const { camera } = useThree();

  let kick = 1;
  let bass = 1;
  let synth1 = 1;
  let synth2 = 1;
  let otherMusic = 1;
  let vocal = 1;
  const musicWorld = 10;

  var newerArray = [];
  var verticesFloat = [];
  var floatArray = [];
  React.useMemo(() => {
    for (var i = 0; i < props.imageHeight; i++) {
      for (var j = 0; j < props.imageWidth; j++) {
        var color6 = new THREE.Color();
        color6.setRGB(
          props.imageData[c] / 255,
          props.imageData[c + 1] / 255,
          props.imageData[c + 2] / 255
        );

        var newArray = color6.toArray();
        newerArray.push(newArray);

        var weight =
          color6.r * weights[0] + color6.g * weights[1] + color6.b * weights[2];

        var vertex = new THREE.Vector3();

        vertex.x = x;
        vertex.y = y;
        vertex.z = zRange * -0.5 + zRange * weight;

        vertices2.push(vertex);

        c += 4;

        x++;
      }

      x = props.imageWidth * -0.5;
      y--;
    }
    const vertices3 = [];
    for (var i = 0; i < vertices2.length; i++) {
      vertices3.push(vertices2[i].x);
      vertices3.push(vertices2[i].y);
      vertices3.push(vertices2[i].z);
    }
    verticesFloat = new Float32Array(vertices3);

    var newestArray = [];
    for (var i = 0; i < newerArray.length; i++) {
      for (var j = 0; j < 3; j++) newestArray.push(newerArray[i][j]);
    }
    floatArray = new Float32Array(newestArray);
  });

  const n = 3000;

  const pointsRef = React.useRef();

  const starRef = useRef();
  const codropsRef = useRef();
  const theRef = useRef();
  const gradientRef = useRef();

  var x;
  var y;
  let sinYo = 0;
  let cosYo = 0;
  let sin = 0;
  let cos = 0;
  const groupRef = React.useRef();
  useFrame((state, delta) => {
    let avgPlease = update();
    // let avgPlease = 0;

    starRef.current.rotation.y += -delta / 15;
    starRef.current.rotation.x += -delta / 15;
    x += delta / 1.5;
    if (enabledAudio === true) {
      y = avgPlease / 30000;
    }
    if (enabledAudio === false) {
      y = 0;
    }

    // gradientRef.current.material.uniforms.time.value += delta / 40;
    codropsRef.current.position.z = state.camera.position.z - 50;
    codropsRef.current.position.x = state.camera.position.x;
    codropsRef.current.position.y = state.camera.position.y;
    sinYo += delta / 2;
    cosYo += delta / 2;
    sin = Math.sin(sinYo);
    cos = Math.cos(cosYo);

    // theRef.current.uFrequency = sin;
    // theRef.current.uAmplitude = cos;
    // theRef.current.uDensity = sin;
    // theRef.current.uStrength = cos;
    // theRef.current.uDeepPurple = sin;

    const t = misc.remap(Math.sin(x), [-1, 1], [0, 1]);
    gradientRef.current.material.uniforms.time.value += y / 2 + delta / 90;
    // codropsRef.current.rotation.y += delta / 12;
    // codropsRef.current.rotation.x += delta / 15;

    codropsRef.current.scale.x =
      codropsRef.current.scale.y =
      codropsRef.current.scale.z =
        11;
    theRef.current.uFrequency = (avgPlease / 10) * cos * 5;
    theRef.current.uAmplitude = (avgPlease / 10) * sin * 5;
    theRef.current.uDensity = 0.8 + (avgPlease / 10) * cos * 5;
    theRef.current.uStrength = (avgPlease / 10) * sin * 2;
    theRef.current.uDeepPurple = (avgPlease / 4) * sin * 5 - 8;
    if (audioTrig === true && enabledAudio === true) {
      pointsRef.current.material.uniforms.amplitude.value =
        avgPlease / 6 + Math.sin(x / 1.9) * 16;
    }
    if (audioTrig === false || enabledAudio === false) {
      pointsRef.current.material.uniforms.amplitude.value =
        Math.sin(x / 1.9) * 16;
    }

    // pointsRef.current.material.uniforms.amplitude.value =
    //   avgPlease / 13 + Math.sin(x) / 1;

    // console.log(props);
  });

  //   const [width, setWidth] = React.useState(window.innerWidth);

  //   function handleWindowSizeChange() {
  //     setWidth(window.innerWidth);
  //   }
  //   useEffect(() => {
  //     window.addEventListener("resize", handleWindowSizeChange);
  //     return () => {
  //       window.removeEventListener("resize", handleWindowSizeChange);
  //     };
  //   }, []);
  //   const isMobile = width <= 768;

  const [mobileOffset, setMobileOffset] = React.useState(0);
  const [audioTrig, setAudioTrig] = React.useState(true);
  // useEffect(() => {
  //   if (props.audioInput === true) {
  //     // console.log("YEOH");
  //     setAudioTrig(true);
  //   } else {
  //     setAudioTrig(false);
  //   }
  // }, [props.audioInput]);

  const { gainNode, context, update, data, source, avg } = suspend(
    () => createAudio(),
    []
  );

  return (
    <>
      <group ref={codropsRef} visible={false} position={[0, 0, 700]}>
        <mesh>
          <icosahedronBufferGeometry args={[200, 64]} />
          <waveShaderMaterial
            wireframe={true}
            transparent={true}
            blending={THREE.AdditiveBlending}
            ref={theRef}
            uFrequency={1}
            uAmplitude={1}
            uDensity={1}
            uStrength={1}
            uDeepPurple={1}
            uOpacity={0.2}
          />
        </mesh>
      </group>
      <group ref={starRef} visible={astraVisible}>
        <Stars
          radius={500}
          depth={50}
          count={5000}
          factor={4}
          saturation={100}
          fade
          speed={2}
        />
      </group>
      <mesh ref={gradientRef} visible={dreamVisible}>
        <sphereBufferGeometry args={[1100, 128, 128]} />
        <gradientMaterial
          side={THREE.BackSide}
          // wireframe={true}
          extensions={{
            derivatives: "#extension GL_OES_standard_derivatives : enable",
          }}
        />
      </mesh>
      <group
        onClick={() => setAudioTrig(true)}
        scale={0.5}
        position={[0, mobileOffset, -20]}
        ref={groupRef}
      >
        <Points positions={verticesFloat} colors={floatArray} ref={pointsRef}>
          {/* @ts-ignore */}
          <myPointsMaterial />
        </Points>
      </group>
    </>
  );
}

async function createAudio(userInput, audioTrig, { threshold, expire } = {}) {
  //   console.log(userInput);

  // if (audioTrig === true) {
  //   console.log("YEOHEOHE");
  // }
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false,
  });
  //   const res = await fetch(url);
  //   const buffer = await res.arrayBuffer();
  const context = new (window.AudioContext || window.webkitAudioContext)();
  const analyser = context.createAnalyser();
  analyser.smoothingTimeConstant = 0.9;
  analyser.fftSize = 2048;
  const data = new Uint8Array(analyser.frequencyBinCount);
  const source = context.createMediaStreamSource(stream);

  // metronome start
  let notesInQueue = []; // notes that have been put into the web audio and may or may not have been played yet {note, time}
  let currentBeatInBar = 0;
  let beatsPerBar = 4;
  let tempo = 121;
  let lookahead = 25; // How frequently to call scheduling function (in milliseconds)
  let scheduleAheadTime = 0.1; // How far ahead to schedule audio (sec)
  let nextNoteTime = 0.0; // when the next note is due
  let isRunning = false;
  let intervalID = null;
  // let test = 0;
  let test = 0;
  let starter = 0;

  //   source.buffer = await new Promise((res) =>
  //     context.decodeAudioData(buffer, res)
  //   );

  source.loop = true;
  const gainNode = context.createGain();
  gainNode.gain.value = 0;
  gainNode.connect(context.destination);
  source.connect(analyser);
  analyser.connect(gainNode);

  isRunning = true;

  currentBeatInBar = 0;
  nextNoteTime = context.currentTime + 0.05;

  // intervalID = setInterval(() => scheduler(), lookahead);

  let time = Date.now();
  let state = {
    context,
    source,
    data,
    gainNode,
    gain: 1,
    signal: false,
    avg: 0,
    avgFreq: 0,
    avgGainNormalized: 0,
    avgFreqNormalized: 0,
    avgRounded: 0,
    tempoTrigger: 0,
    low: 0,
    medium: 0,
    high: 0,
    finalAvg: 0,

    update: () => {
      // test = 0;
      // console.log(test);
      // console.log(isRunning);
      state.tempoTrigger = test;
      // console.log(tempoTrigger);
      let now = Date.now();
      let value = 0;
      let value2 = 0;
      let value3 = 0;
      let lowBand = 0;
      let mediumBand = 0;
      let highBand = 0;
      // let start = context.currentTime;
      // let startRounded = start.toFixed(1);
      // let tempoRaw = 130 / 60;
      // let tempoRounded = tempoRaw.toFixed(1);
      // if (startRounded === tempoRounded) {
      //   console.log("beat");
      //   startRounded = startRounded - startRounded;
      // }
      // if (startRounded % tempoRounded === 0) {
      //   console.log("beat");
      // }
      // console.log(startRounded % tempoRounded);
      // console.log(startRounded);

      analyser.getByteFrequencyData(data);
      // console.log(data);
      // console.log(context.currentTime);
      // console.log(context.startedAt);
      // if (context.currentTime)
      for (let i = 0; i < data.length; i++) {
        value += data[i];
        value2 += (i + 1) * data[i];
        value3 += data[i];
      }
      for (let i = 0; i < 30; i++) {
        lowBand += data[i];
      }
      for (let i = 30; i < 650; i++) {
        mediumBand += data[i];
      }
      for (let i = 650; i < 1024; i++) {
        highBand += data[i];
      }

      const low = (state.low = lowBand / data.length);
      const medium = (state.medium = mediumBand / data.length);
      const high = (state.high = highBand / data.length);

      const avg = (state.avg = value / data.length);
      // const avgPlease = (state.avg = value / data.length);
      // return avgPlease;

      const avgRounded = (state.avgRounded = Math.round(avg * 10) / 10);
      const avgFreq = (state.avgFreq = value2 / value3);
      const avgGainNormalized = (state.avgGainNormalized =
        (10 * Math.pow(avg, 0.5)) / 160);
      // (7 * Math.pow(avg, 0.6)) / 194);
      const avgFreqNormalized = (state.avgFreqNormalized =
        (10.24 * Math.pow(avgFreq, 0.75)) / 1024);
      if (threshold && avg > threshold && now - time > expire) {
        time = Date.now();
        state.signal = true;
      } else state.signal = false;
      return value / data.length;
    },
    // setGain(level) {
    //   gainNode.gain.setValueAtTime((state.gain = level), context.currentTime);
    // },
  };

  return state;
}
