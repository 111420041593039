import { IconHeart } from "@tabler/icons";
import { useEffect, useState } from "react";
import {
  Card,
  Image,
  Text,
  Group,
  Badge,
  Button,
  ActionIcon,
  createStyles,
  Container,
  Center,
  List,
  Stack,
  ThemeIcon,
  SegmentedControl,
} from "@mantine/core";
import AOS from "aos";
import { fb } from "../Firebase/Shared/Service";
import { useAuth } from "../Firebase/hooks";

import styles from "../styles.module.css";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { Indicator } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  card: {
    height: "600px",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  section: {
    // borderBottom: `1px solid ${
    //   theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    // }`,
    width: "80%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    paddingBottom: theme.spacing.md,
  },

  like: {
    color: theme.colors.red[6],
  },

  label: {
    textTransform: "uppercase",
    fontSize: theme.fontSizes.xs,
    fontWeight: 700,
  },
}));

// interface BadgeCardProps {
//   image: string;
//   title: string;
//   country: string;
//   description: string;
//   badges: {
//     emoji: string,
//     label: string,
//   }[];
// }

export default function Pricing2() {
  const authUser = useAuth();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
    });
  }, []);
  const { classes, theme } = useStyles();
  const image = "";
  const title = "";
  const country = "";
  const description = "";
  const badges = [{ emoji: "hello", label: "yo" }];

  const features = badges.map((badge) => (
    <Badge
      color={theme.colorScheme === "dark" ? "dark" : "gray"}
      key={badge.label}
      leftSection={badge.emoji}
    >
      {badge.label}
    </Badge>
  ));
  const [value, setValue] = useState("monthly");
  const [time, setTime] = useState("month");
  const [price1, setPrice1] = useState("$5");
  const [price2, setPrice2] = useState("$10");
  useEffect(() => {
    if (value === "monthly") {
      setPrice1("$5");
      setPrice2("$10");
      setTime(" / month");
    } else {
      setPrice1("$24");
      setPrice2("$48");
      setTime(" / year");
    }
  }, [value]);

  const checkoutPro = () => {
    setLoading(true);

    if (value === "monthly") {
      checkoutProMonth();
    }
    if (value === "yearly") {
      checkoutProYear();
    }
  };

  const checkoutProPlus = () => {
    setLoading2(true);

    if (value === "monthly") {
      checkoutProPlusMonth();
    }
    if (value === "yearly") {
      checkoutProPlusYear();
    }
  };

  const checkoutProMonth = async () => {
    // if (authUser.uid) {
    const docRef = await fb.firestore
      .collection("customers")
      .doc(authUser.authUser.uid)
      .collection("checkout_sessions")
      .add({
        // price: "price_1Lz2KvLFZvJ24cvWKY0MMro8",
        price: "price_1LzQ4cLFZvJ24cvWWmzL2zeR",
        allow_promotion_codes: true,

        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
    // }
  };
  const checkoutProYear = async () => {
    // if (authUser.uid) {
    const docRef = await fb.firestore
      .collection("customers")
      .doc(authUser.authUser.uid)
      .collection("checkout_sessions")
      .add({
        // price: "price_1Lz2LuLFZvJ24cvWfgQJIo1M",
        price: "price_1LzQ4TLFZvJ24cvW5SFtRoyT",
        allow_promotion_codes: true,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
    // }
  };

  const checkoutProPlusMonth = async () => {
    // if (authUser.uid) {
    const docRef = await fb.firestore
      .collection("customers")
      .doc(authUser.authUser.uid)
      .collection("checkout_sessions")
      .add({
        // price: "price_1Lz2LRLFZvJ24cvWoABLbbdD",
        allow_promotion_codes: true,

        price: "price_1LzQ4XLFZvJ24cvWreGaF8hP",
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
    // }
  };

  const checkoutProPlusYear = async () => {
    // if (authUser.uid) {
    const docRef = await fb.firestore
      .collection("customers")
      .doc(authUser.authUser.uid)
      .collection("checkout_sessions")
      .add({
        // price: "price_1Lz2MRLFZvJ24cvWzTvhot9s",
        allow_promotion_codes: true,

        price: "price_1LzQ4ILFZvJ24cvWYiF5vxRR",
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
    // }
  };

  return (
    <>
      <Center>
        <Stack>
          <h1
            style={{
              marginTop: "100px",
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontSize: 62,
              fontWeight: 900,
              lineHeight: 1.1,
              color: "white",
            }}
          >
            Choose a Plan
          </h1>
          <SegmentedControl
            value={value}
            onChange={setValue}
            data={[
              { label: "Monthly", value: "monthly" },
              { label: "Yearly (Save 60%)", value: "yearly" },
            ]}
          />
        </Stack>
      </Center>

      <Group position="center">
        <Container
          pt={80}
          size="xs"
          sx={{
            height: "600px",
            minWidth: "450px",

            marginLeft: 0,
            marginRight: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Card
            withBorder
            radius="md"
            p="md"
            className={classes.card}
            sx={{
              background: "rgb(0 0 0 / 40%)",
              backdropFilter: "blur(100px)",
              webkitBackdropFilter: "blur(100px)",

              boxShadow: "rgb(0 0 0 / 15%) 0px 12px 17px 12px",
            }}
          >
            <Stack
              sx={{ height: "100%" }}
              spacing={40}
              //   justify="space-between"
              align="center"
            >
              <Card.Section>
                <Center>
                  <h1
                    style={{
                      fontSize: 72,
                      fontWeight: 800,
                      color: "white",
                    }}
                  >
                    Free
                  </h1>
                </Center>

                {/* <Image src={image} alt={title} height={180} /> */}
              </Card.Section>
              <Group align="baseline" spacing={6}>
                <Text
                  data-aos="fade-zoom-in"
                  sx={{
                    fontSize: "4.75rem",
                    fontWeight: 800,
                    color: "white",
                    color: "white",
                  }}
                >
                  $0{" "}
                </Text>
                <Text sx={{ fontWeight: 600 }}>{time}</Text>
              </Group>

              {/* <Card.Section className={classes.section} mt="md">
          <Group position="apart">
            <Text size="lg" weight={500}>
              {title}
            </Text>
            <Badge size="sm">{country}</Badge>
          </Group>
          <Text size="sm" mt="xs">
            {description}
          </Text>
        </Card.Section> */}

              <Card.Section className={classes.section}>
                {/* <Text mt="md" className={classes.label} color="dimmed">
            Perfect for you, if you enjoy
          </Text> */}
                {/* <Group spacing={7} mt={5}>
            {features}
          </Group> */}
                <List
                  spacing="xl"
                  size="sm"
                  center
                  icon={<IoCheckmarkCircleSharp size={18} />}
                  // data-aos-duration="750"
                >
                  <List.Item
                    data-aos="fade-up"
                    data-aos-duration="750"
                    sx={{ fontWeight: 800, color: "white", lineHeight: "1.5" }}
                  >
                    1 public link page at a time
                  </List.Item>

                  <List.Item
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="100"
                    sx={{ fontWeight: 800, color: "white", lineHeight: "1.5" }}
                  >
                    10 gradients
                  </List.Item>
                  <List.Item
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="200"
                    sx={{ fontWeight: 800, color: "white", lineHeight: "1.5" }}
                  >
                    2 filters
                  </List.Item>
                  <List.Item
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="300"
                    sx={{ fontWeight: 800, color: "white", lineHeight: "1.5" }}
                  >
                    2 audio-reactive backgrounds
                  </List.Item>
                </List>
              </Card.Section>
            </Stack>
          </Card>
        </Container>
        <Container
          pt={80}
          size="xs"
          sx={{
            height: "630px",
            minWidth: "450px",

            marginLeft: 0,
            marginRight: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {/* <Indicator
            // dot
            // inline
            size={30}
            label="Most Popular"
            offset={7}
            //   // position="bottom-end"
            color="rgb(0 0 0 / 50%)"
            // withBorder
          > */}
          <Group position="right">
            <div
              style={{
                position: "absolute",
                // marginBottom: "px",
                marginTop: "15px",
                zIndex: "99",
              }}
            >
              <Badge color="blue" variant="light" size="sm">
                Most Popular
              </Badge>
            </div>
          </Group>
          <Card
            withBorder
            radius="md"
            p="md"
            className={classes.card}
            sx={{
              background: "rgb(0 0 0 / 10%)",
              backdropFilter: "blur(100px)",
              webkitBackdropFilter: "blur(100px)",

              //   boxShadow: "rgb(0 0 0 / 15%) 0px 12px 17px 12px",
              boxShadow:
                "rgba(0, 0, 0, 0.6) 0px 4px 8px, rgba(0, 0, 0, 0.5) 0px 7px 13px -3px, rgba(0, 0, 0, 0.4) 0px -3px 0px inset",
            }}
          >
            <Center>
              <div
                style={{ position: "absolute", zIndex: "2", bottom: 30 }}
                className={styles.box2}
              >
                <Button
                  loading={loading}
                  onClick={() => checkoutPro()}
                  variant="default"
                >
                  Go PRO
                </Button>
              </div>
            </Center>
            <Stack
              sx={{ height: "100%" }}
              spacing={40}
              //   justify="space-between"
              align="center"
            >
              <Card.Section>
                <Center>
                  <h1 style={{ fontSize: 72 }} className={styles.gradientText}>
                    PRO
                  </h1>
                </Center>

                {/* <Image src={image} alt={title} height={180} /> */}
              </Card.Section>
              <Stack spacing={0}>
                <Group align="baseline" spacing={6}>
                  {value === "yearly" && (
                    <Text
                      sx={{ fontSize: "1.75rem", fontWeight: 800 }}
                      className={styles.strikediag}
                      color="dimmed"
                      pr={5}
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      $60
                    </Text>
                  )}
                  <Text
                    sx={{
                      fontSize: "4.75rem",
                      fontWeight: 800,
                      color: "white",

                      color: "white",
                    }}
                    data-aos="fade-zoom-in"
                  >
                    {price1}
                  </Text>
                  <Text sx={{ fontWeight: 600 }}> {time}</Text>
                </Group>

                {value === "yearly" && (
                  <div style={{ width: "100%" }}>
                    <Center>
                      <Group position="right">
                        <Badge color="green">Save 60%</Badge>
                      </Group>
                    </Center>
                  </div>
                )}
              </Stack>

              {/* <Card.Section className={classes.section} mt="md">
          <Group position="apart">
            <Text size="lg" weight={500}>
              {title}
            </Text>
            <Badge size="sm">{country}</Badge>
          </Group>
          <Text size="sm" mt="xs">
            {description}
          </Text>
        </Card.Section> */}

              <Card.Section className={classes.section}>
                {/* <Text mt="md" className={classes.label} color="dimmed">
            Perfect for you, if you enjoy
          </Text> */}
                {/* <Group spacing={7} mt={5}>
            {features}
          </Group> */}
                <List
                  spacing="md"
                  size="sm"
                  center
                  icon={<IoCheckmarkCircleSharp size={18} />}
                >
                  <List.Item
                    data-aos="fade-up"
                    data-aos-duration="750"
                    sx={{ fontWeight: 800, color: "white", lineHeight: "1.4" }}
                  >
                    12 public link pages at a time
                  </List.Item>
                  <List.Item
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="100"
                    sx={{ fontWeight: 800, color: "white", lineHeight: "1.4" }}
                  >
                    Change public URL
                  </List.Item>
                  <List.Item
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="200"
                    sx={{ fontWeight: 800, color: "white", lineHeight: "1.4" }}
                  >
                    No watermark in Live Mode
                  </List.Item>

                  <List.Item
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="300"
                    sx={{ fontWeight: 800, color: "white", lineHeight: "1.4" }}
                  >
                    50 gradients
                  </List.Item>
                  <List.Item
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="400"
                    sx={{ fontWeight: 800, color: "white", lineHeight: "1.4" }}
                  >
                    Unlock all filters
                  </List.Item>
                  <List.Item
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="500"
                    sx={{ fontWeight: 800, color: "white", lineHeight: "1.4" }}
                  >
                    Unlock all audio-reactive backgrounds
                  </List.Item>
                </List>
              </Card.Section>
            </Stack>
            {/* <Group sx={{ position: "fixed", zIndex: "2" }} position="center"> */}

            {/* </Group> */}
          </Card>

          {/* </Indicator> */}
        </Container>

        <Container
          pt={80}
          size="xs"
          sx={{
            height: "600px",
            // width: "100%",
            minWidth: "450px",
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Card
            withBorder
            radius="md"
            p="md"
            className={classes.card}
            sx={{
              background: "rgb(0 0 0 / 40%)",
              backdropFilter: "blur(100px)",
              webkitBackdropFilter: "blur(100px)",

              boxShadow: "rgb(0 0 0 / 15%) 0px 12px 17px 12px",
            }}
          >
            <Center>
              <div
                style={{ position: "absolute", zIndex: "2", bottom: 30 }}
                className={styles.box2}
              >
                <Button
                  loading={loading2}
                  onClick={() => checkoutProPlus()}
                  variant="default"
                >
                  Go PRO +
                </Button>
              </div>
            </Center>
            <Stack
              sx={{ height: "100%" }}
              spacing={40}
              //   justify="space-between"
              align="center"
            >
              <Card.Section>
                <Center>
                  <h1 style={{ fontSize: 72 }} className={styles.gradientText}>
                    PRO +
                  </h1>
                </Center>

                {/* <Image src={image} alt={title} height={180} /> */}
              </Card.Section>
              <Stack spacing={0}>
                <Group align="baseline" spacing={6}>
                  {value === "yearly" && (
                    <Text
                      sx={{
                        fontSize: "1.75rem",
                        fontWeight: 800,
                        // color: "white",
                      }}
                      className={styles.strikediag}
                      color="dimmed"
                      pr={5}
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      $120
                    </Text>
                  )}

                  <Text
                    data-aos="fade-zoom-in"
                    sx={{
                      fontSize: "4.75rem",
                      fontWeight: 800,
                      color: "white",

                      color: "white",
                    }}
                  >
                    {price2}
                  </Text>
                  <Text sx={{ fontWeight: 600 }}> {time}</Text>
                </Group>
                {value === "yearly" && (
                  <div style={{ width: "100%" }}>
                    <Center>
                      <Group position="right">
                        <Badge color="green">Save 60%</Badge>
                      </Group>
                    </Center>
                  </div>
                )}
              </Stack>

              {/* <Card.Section className={classes.section} mt="md">
           <Group position="apart">
             <Text size="lg" weight={500}>
               {title}
             </Text>
             <Badge size="sm">{country}</Badge>
           </Group>
           <Text size="sm" mt="xs">
             {description}
           </Text>
         </Card.Section> */}

              <Card.Section className={classes.section}>
                {/* <Text mt="md" className={classes.label} color="dimmed">
             Perfect for you, if you enjoy
           </Text> */}
                {/* <Group spacing={7} mt={5}>
             {features}
           </Group> */}
                <List
                  spacing="xl"
                  size="sm"
                  center
                  icon={<IoCheckmarkCircleSharp size={18} />}
                >
                  <List.Item
                    data-aos="fade-up"
                    data-aos-duration="750"
                    sx={{ fontWeight: 800, color: "white", lineHeight: "1.5" }}
                  >
                    30 public link pages at a time
                  </List.Item>
                  {/* <List.Item
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="300"
                    sx={{ fontWeight: 800, color: "white", lineHeight: "1.5" }}
                  >
                    No logo/watermark on link pages
                  </List.Item> */}
                  <List.Item
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="200"
                    sx={{ fontWeight: 800, color: "white", lineHeight: "1.5" }}
                  >
                    100+ gradients
                  </List.Item>

                  <List.Item
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="400"
                    sx={{ fontWeight: 800, color: "white", lineHeight: "1.5" }}
                  >
                    Everything in PRO
                  </List.Item>
                </List>
              </Card.Section>
            </Stack>
          </Card>
        </Container>
      </Group>
      {/* <Image src={"/stripe.svg"} alt={"Powered By Stripe"} height={20} /> */}
      {/* <Center> */}
      {/* <div style={{ position: "absolute", zIndex: "2" }}> */}
      <div style={{ width: "100%" }}>
        <Center mt={60}>
          <img
            src="/stripeWhite.svg"
            alt="Stripe"
            height="100"
            width="100"
            // style="color:green;"
          />
        </Center>
      </div>
      {/* </div> */}
      {/* </Center> */}
    </>
    // </Container>
  );
}
