import React from "react";
import * as THREE from "three";
import { useRef, useState, useEffect, useMemo } from "react";
import {
  Canvas,
  extend,
  useFrame,
  useThree,
  useLoader,
} from "@react-three/fiber";
import { Stars, useTexture, shaderMaterial } from "@react-three/drei";
import LandingContainerMobile from "../LandingContainerMobile";
import useStore2 from "../store2";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import "bootstrap/dist/css/bootstrap.min.css";
import HyperbolicHelicoidZustand from "../HyperbolicHelicoidZustand";
import useRefs from "react-use-refs";
import "./stylesLanding.css";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import Lottie from "react-lottie";
import * as animationData from "./lottie/scroll.json";

extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  AfterimagePass,
});

const rsqw = (t, delta = 0.1, a = 1, f = 1 / (2 * Math.PI)) =>
  (a / Math.atan(1 / delta)) * Math.atan(Math.sin(2 * Math.PI * t * f) / delta);
export default function LandingPage2(props) {
  const color = "#000000";

  let animable = {
    r1: 0,
    r2: 0,
    r3: 0,
    r4: 0,
    r5: 0,
    r6: 0,
    r7: 1,
    camera: 0,
    camera2: 0,
    stars: 20,
    starsOut: 1,
    scale: 0,
    speed: 0.3,
    zAmount: 0.5,
    stage: 0,
    camera10: 0,
    // y: 0,
  };
  let animableSphere = {
    y: 0,
  };

  return (
    <>
      <Canvas
        linear
        flat
        shadows
        dpr={[1, 2]}
        camera={{ position: [0, -3.2, 500], fov: 20 }}
      >
        <color attach="background" args={[color]} />
        <ambientLight intensity={0.75} />

        <Contentt audioInput={props.audioInput} animable={animable} />
      </Canvas>
    </>
  );
}

const Contentt = (props) => {
  const hundredColors = require("./hundredColors.json");
  const [firstPalette, setFirstPalette] = useState(1);
  const landingBackground = useStore2((state) => state.landingBackground);
  useEffect(() => {
    let palette = hundredColors[firstPalette];
    palette = palette.map((color) => new THREE.Color(color));
    gradientRef.current.material.uniforms.uColor.value = palette;
  }, [firstPalette, landingBackground]);

  var x = 0;
  var sin = 0;
  var cos = 0;

  let y = 1;
  useFrame((state, delta) => {
    let avgPlease = 0;
    starRef.current.rotation.y -= delta / 50;
    starRef.current.rotation.x -= delta / 60;

    logoRef.current.position.y = THREE.MathUtils.damp(
      logoRef.current.position.y,
      rsqw(props.animable.r1) * 1.9 + rsqw(props.animable.camera10) * -1.9,
      4,
      delta
    );
    logoRef.current.position.x = THREE.MathUtils.damp(
      logoRef.current.position.x,
      rsqw(props.animable.r1) * -5 + rsqw(props.animable.camera10) * 1.9,
      4,
      delta
    );

    logoRef.current.scale.x =
      logoRef.current.scale.y =
      logoRef.current.scale.z =
      logoRef.current.rotation.y =
        THREE.MathUtils.damp(
          logoRef.current.scale.z,
          1 -
            rsqw(props.animable.r1) / 1.15 +
            rsqw(props.animable.camera10) / 1.15,
          2,
          delta
        );

    group.current.position.y = THREE.MathUtils.damp(
      group.current.position.y,
      props.animable.r4 * 1.1 - 4.5 + props.animable.r6 * -2,
      20,
      delta
    );

    group.current.scale.x =
      group.current.scale.y =
      group.current.scale.z =
        THREE.MathUtils.damp(
          group.current.scale.z,
          1.5 + 0.24 * (1 - rsqw(props.animable.r1)),
          4,
          delta
        );
    keyLight.current.position.set(
      0.25 + -15 * (1 - props.animable.r1),
      4 + 11 * (1 - props.animable.r1),
      3 + 2 * (1 - props.animable.r1)
    );

    pointsWorld.current.scale.x =
      pointsWorld.current.scale.y =
      pointsWorld.current.scale.z =
        1 -
        rsqw(props.animable.scale / 0.97) +
        rsqw(props.animable.camera10 / 0.97);
    pointsWorld.current.position.y = props.animable.camera * -10;
    pointsWorld.current.rotation.z =
      1 - props.animable.scale + props.animable.camera10 * 2;

    gradientRef.current.material.uniforms.time.value += delta / 90;
    gradientRef.current.material.uniforms.opacity.value = sin;

    group.current.position.z = props.animable.r6 * 25;

    pointsWorld.current.rotation.y = props.animable.r6 * Math.PI * 2;

    x += delta / 3;
    sin = Math.sin(x);
    cos = Math.cos(x);
  });
  const colorMap = useLoader(
    TextureLoader,
    "/images/sounddropWordmarkBlack.png"
    // '/images/'
  );
  const { width, height } = useThree((state) => state.viewport);
  const [
    group,
    mbp16,
    mbp14,
    keyLight,
    stripLight,
    fillLight,
    left,
    right,
    logoRef,
    wordMark,
    wordMarkMaterial,
    pointsWorld,
    gradientRef,
    iphoneRef,
    starRef,
    stageRef,
    clumpGroup,
    codropsRef,
    theRef,
  ] = useRefs();

  //   useEffect(() => {
  //     console.log(props);
  //   }, [props]);

  const [theImage, setTheImage] = useState("/images/sounddropLogo.png");

  return (
    <>
      <group ref={starRef}>
        <Stars
          radius={20}
          depth={20}
          count={6000}
          factor={4}
          saturation={100}
          fade
          speed={2}
        />
      </group>

      <mesh ref={gradientRef}>
        <sphereBufferGeometry args={[11, 128, 128]} />
        <gradientMaterial
          side={THREE.BackSide}
          transparent
          // wireframe={true}
          extensions={{
            derivatives: "#extension GL_OES_standard_derivatives : enable",
          }}
        />
      </mesh>
      <group
        ref={pointsWorld}
        rotation-x={0.05}
        position-z={-3}
        scale={0.4}
        rotation-z={Math.PI / 2}
        // visible={false}
      >
        {/* {props.theImage && ( */}
        <LandingContainerMobile
          //   r5={props.animable.r5}
          audioInput={props.audioInput}
          palette={1}
          bgProp={"DREAM"}
          //   useThisImage={"/images/sounddropLogo.png"}
          useThisImage={theImage}
          style={["PHANTOM"]}
          zAmount={props.animable.zAmount}
          speed={props.animable.speed}
        />
        {/* )} */}
      </group>
      <spotLight
        position={[0, -width * 0.7, 0]}
        intensity={0.5}
        // penumbra={0.1}
        // distance={width * 0.5}
      />
      <directionalLight ref={keyLight} castShadow intensity={1.5}>
        <orthographicCamera
          attachObject={["shadow", "camera"]}
          args={[-10, 10, 10, -10, 0.5, 30]}
        />
      </directionalLight>
      <group ref={group} position={[0, -12, 0]} {...props}>
        <spotLight
          ref={stripLight}
          position={[width * 2.5, 0, width]}
          angle={0.19}
          penumbra={5}
          intensity={0.25}
        />
        <spotLight
          ref={fillLight}
          position={[0, -width / 2.4, -width * 2.2]}
          angle={0.2}
          penumbra={1}
          intensity={2}
          distance={width * 3}
        />
      </group>
      <group scale={2} position={[0, 1, 0]}>
        <group ref={logoRef} visible={true}>
          <HyperbolicHelicoidZustand />
        </group>
      </group>
    </>
  );
};

const hundredColors = require("./hundredColors.json");

let firstPalette = hundredColors[15];

firstPalette = firstPalette.map((color) => new THREE.Color(color));

const GradientMaterial = shaderMaterial(
  {
    time: 0,
    uColor: firstPalette,
    resolution: new THREE.Vector4(),
    opacity: 1.0,
    // u: 1,
    // amplitude: 0.5,
  },
  /* glsl */ `
  uniform float time;
  // varying vec2 vUv;
  varying vec3 vNormal;
  varying vec3 vPosition;
  uniform vec3 uColor[5];
  varying vec3 vColor;
  uniform vec2 pixels;
  float PI = 3.141592653589793238;
  //	Simplex 3D Noise 
  //	by Ian McEwan, Ashima Arts
  //
  vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
  vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
  
  float snoise(vec3 v){ 
    const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
    const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);
  
  // First corner
    vec3 i  = floor(v + dot(v, C.yyy) );
    vec3 x0 =   v - i + dot(i, C.xxx) ;
  
  // Other corners
    vec3 g = step(x0.yzx, x0.xyz);
    vec3 l = 1.0 - g;
    vec3 i1 = min( g.xyz, l.zxy );
    vec3 i2 = max( g.xyz, l.zxy );
  
    //  x0 = x0 - 0. + 0.0 * C 
    vec3 x1 = x0 - i1 + 1.0 * C.xxx;
    vec3 x2 = x0 - i2 + 2.0 * C.xxx;
    vec3 x3 = x0 - 1. + 3.0 * C.xxx;
  
  // Permutations
    i = mod(i, 289.0 ); 
    vec4 p = permute( permute( permute( 
               i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
             + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
             + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));
  
  // Gradients
  // ( N*N points uniformly over a square, mapped onto an octahedron.)
    float n_ = 1.0/7.0; // N=7
    vec3  ns = n_ * D.wyz - D.xzx;
  
    vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)
  
    vec4 x_ = floor(j * ns.z);
    vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)
  
    vec4 x = x_ *ns.x + ns.yyyy;
    vec4 y = y_ *ns.x + ns.yyyy;
    vec4 h = 1.0 - abs(x) - abs(y);
  
    vec4 b0 = vec4( x.xy, y.xy );
    vec4 b1 = vec4( x.zw, y.zw );
  
    vec4 s0 = floor(b0)*2.0 + 1.0;
    vec4 s1 = floor(b1)*2.0 + 1.0;
    vec4 sh = -step(h, vec4(0.0));
  
    vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
    vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;
  
    vec3 p0 = vec3(a0.xy,h.x);
    vec3 p1 = vec3(a0.zw,h.y);
    vec3 p2 = vec3(a1.xy,h.z);
    vec3 p3 = vec3(a1.zw,h.w);
  
  //Normalise gradients
    vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;
  
  // Mix final noise value
    vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
    m = m * m;
    return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                  dot(p2,x2), dot(p3,x3) ) );
  }
  
  void main() {
  
  
    
  
    vec3 noiseCoord = normal;
  
    float tilt = -0.8*normal.y;
  
    float incline  = normal.x*0.1;
    // float incline  = uv.x*10.;

  
    float offset = incline*mix(-.25,0.25,normal.x);
    // float offset = incline*mix(-2.,2.,normal.y);

  
  
  
    float noise = snoise(vec3(noiseCoord.x + time*3.,noiseCoord.y, time * 10.));
  
    noise = max(0.,noise);
  
    vec3 pos = vec3(position.x,position.y,position.z + noise * 0.3 +tilt + incline + offset);
    // vec3 pos = vec3(position.x,position.y,position.z + noise * 200. +tilt + incline + offset);

  
  
  
  
    vColor = uColor[4];
  
    for(int i = 0; i < 4; i++) {
  
      float noiseFlow  = 5. + float(i)*0.3;
      float noiseSpeed  = 10. + float(i)*0.3;
  
      float noiseSeed = 1. + float(i)*10.;
      vec2 noiseFreq = vec2(1.,1.4)*.4;
  
      float noiseFloor = 0.1;
      float noiseCeil = 0.6 + float(i)*0.07;
  
  
  
      float noise = smoothstep(noiseFloor,noiseCeil,
        snoise(
          vec3(
            noiseCoord.x*noiseFreq.x + time*noiseFlow,
            noiseCoord.y*noiseFreq.y, 
            time / 2.0 * noiseSpeed + noiseSeed
          )
        )
      );
  
      vColor = mix(vColor,uColor[i],noise);
  
      
    }
  
    // vUv = uv;
    vNormal = normal;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( pos, 1.0 );
  }
  
    `,
  /* glsl */ `
  uniform float time;
  uniform float progress;
  uniform sampler2D texture1;
  uniform vec4 resolution;
  uniform float opacity;
  // varying vec2  vUv;
  varying vec3 vNormal;
  varying vec3 vPosition;
  varying vec3 vColor;
  float PI = 3.141592653589793238;
  void main()	{
      // vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
      // gl_FragColor = vec4(vNormal,0.);
      gl_FragColor = vec4(vColor,1.);
      gl_FragColor.a = opacity;

  }
    `
);

extend({ GradientMaterial });
