import React from "react";
import { useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import "bootstrap/dist/css/bootstrap.min.css";
import PointsWorldContainer3 from "./PointsWorldContainer3";
import styles from "./styles.module.css";
import { useParams } from "react-router-dom";
import { fb } from "./Firebase/Shared/Service";

export default function FinalCanvas() {
  const color = "#000000";
  // const storeUser = useStore2((state) => state.storeUser);
  // const storeTrack = useStore2((state) => state.storeTrack);
  const [style, setStyle] = useState();
  const [bg, setBg] = useState();
  const [palette, setPalette] = useState();

  const { id } = useParams();
  //   useEffect(() => {
  //     console.log(id);
  //   }, [id]);

  // useEffect(() => {
  //   console.log(style);
  // }, [style]);
  const [userName, setUserName] = useState();
  const [trackName, setTrackName] = useState();

  useEffect(() => {
    // const idTrack = id;
    // console.log(idTrack);
    // const idTrack = id.split("-")[1];
    if (id !== undefined) {
      console.log(id);
      const idTrack = id.split("-").splice(1).join("-");
      const idTrack2 = idTrack.replace(/-/g, " ");
      const idArtist = id.split("-")[0];
      // console.log(idTrack2);
      // setTheArtist(idArtist);
      // setTheTrack(idTrack);
      // setTheArtist(fb.firestore.collection(idArtist).doc(theTrack).get());
      // setTheTrack(fb.firestore.collection(idArtist).doc(idTrack).get());
      // console.log(fb.firestore.collection(idArtist).doc(theTrack).get());
      fb.firestore
        .collection(idArtist)
        .doc(idTrack2)
        .get()
        .then((res) => {
          const data = res.data();
          //   console.log(data);
          // console.log(data.links[0]);
          if (data) {
            // console.log(data);
            // setTrackName(data.name);
            // setPageLinks(data.links);
            // setArtistName(data.artist);
            setTrackName(data.name);
            setUserName(data.userName);
            setStyle(data.effects);
            setBg(data.background);
            setPalette(data.palette);
          } else {
            console.error(404);
          }
        });
    }
    // console.log(idTrack);
    // console.log(idArtist);
  }, [id]);
  console.log(userName, "userName");
  console.log(trackName, "trackName");

  return (
    <>
      {/* {styles && ( */}

      <Canvas
        className={styles.grabbable}
        style={{
          outline: "none !important",
          width: "100vw",
          height: "100vh",
          margin: "0",
          padding: "0",
          // zIndex:0,
          // cursor: "grab",
          // cursor: mouseDown ? "grabbing" : "grab",
          // cursor: cursorState,
        }}
        shadows
        dpr={[1, 2]}
        camera={{ position: [0, 0, 10], near: 0.001, far: 2000 }}
        // gl={{
        //   // preserveDrawingBuffer: true,
        //   toneMapping: NoToneMapping,
        // }}
        // onCreated={bind}
        linear
        flat
      >
        {/* <color attach="background" args={[color]} />    */}
        <color attach="background" args={[color]} />

        {/* {location.pathname.includes("page") && */}
        {/* pageTrigger === true && isAuthed && ( */}
        {/* {theUser && theTrack && ( */}
        <>
          {/* <TreeWorldM /> */}
          {/* <TreeWorld /> */}

          {/* {storeUser && storeTrack && ( */}
          <group visible={true}>
            {userName !== undefined && trackName !== undefined && (
              <PointsWorldContainer3
                // visible={false}
                // imageSource={theImage}
                // pageNameStore={pageNameStore}
                // authUserProp={authUser.uid}
                theUser={userName}
                theTrack={trackName}
                style={style}
                bg={bg}
                palette={palette}
              />
            )}
          </group>
          {/* )} */}

          <ambientLight intensity={2} />
          {style && style.includes("PRISMA") ? (
            <OrbitControls
              enablePan={false}
              // enableDamping={true}
              // dampingFactor={-0.01}
              minDistance={120}
              maxDistance={1000}
              rotateSpeed={0.4}
              zoomSpeed={0.4}
              autoRotate
              autoRotateSpeed={2}
            />
          ) : (
            <OrbitControls
              // enabled={false}
              enablePan={false}
              // enableDamping={true}
              // dampingFactor={-0.01}
              minDistance={120}
              maxDistance={1000}
              rotateSpeed={0.4}
              zoomSpeed={0.4}
              // autoRotate
              // autoRotateSpeed={1}
            />
          )}
        </>

        {/* )} */}
        {/* {musicWorldLeva === "Landing Page" && <></>}

{musicWorldLeva === "Cover Art" && (
  <>
    <PointsWorldContainer imageSource={theImage} />
    <OrbitControls
      maxDistance={2000}
      rotateSpeed={0.4}
      zoomSpeed={0.5}
    />
  </>
)}
{musicWorldLeva === "Lounge" && (
  <>
    <Physics gravity={[0, -50, 0]}>
      <PointsWorldContainer2 imageSource={theImage} />
    </Physics>
    <ambientLight intensity={2} />
    <group scale={5} position={[0, -10, 8]} rotation-y={Math.PI}>
      <Suspense fallback={null}>
        <Lounge />
      </Suspense>
    </group>
  </>
)} */}
        {/* {musicWorldLeva === "Parametric Shapes" && musicWorldStore !== 1 && (
  <>
    <Physics gravity={[0, -50, 0]}>
      <Player />
    </Physics>
  </>
)}
{(musicWorldLeva === "Tree" || musicWorldStore === 1) && (
  <>
    <group position={[0, 150, -250]}>
      <PointsWorldContainer imageSource={theImage} />
    </group>
    <Suspense fallback={null}></Suspense>

    <Physics gravity={[0, -50, 0]}>
      <TreeWorld musicWorldLeva={musicWorldLeva} />
      <Player />
    </Physics>
  </>
)} */}
      </Canvas>
      {/* )} */}
    </>
  );
}
