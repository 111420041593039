import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../Firebase/hooks";
import useStore2 from "../store2";
import AudioStart from "../Firebase/AudioStart";
import { BsSpotify } from "react-icons/bs";
import { SiApplemusic } from "react-icons/si";
import { AiFillYoutube } from "react-icons/ai";
import { SiTidal } from "react-icons/si";
import { RiSoundcloudFill } from "react-icons/ri";
import {
  Button,
  Group,
  Stack,
  Text,
  Kbd,
  Image,
  createStyles,
} from "@mantine/core";

import { fb } from "../Firebase/Shared/Service";
import { isMobile } from "react-device-detect";
import styles from "../styles.module.css";

const BREAKPOINT = "@media (max-width: 755px)";

export const PageM = (props) => {
  const trademark = "Made with SoundDrop";
  const changeStoreTrack = useStore2((state) => state.changeStoreTrack);
  const changeStoreUser = useStore2((state) => state.changeStoreUser);
  const [theArtist, setTheArtist] = useState();
  const [theTrack, setTheTrack] = useState();
  const [theLinks, setTheLinks] = useState([]);
  const [theUser, setTheUser] = useState();
  const { id } = useParams();
  useEffect(() => {
    console.log(theArtist);
    console.log(theTrack);
  }, [theArtist, theTrack]);

  useEffect(() => {
    const idTrack = id.split("-").splice(1).join("-");
    const idTrack2 = idTrack.replace(/-/g, " ");
    const idArtist = id.split("-")[0];
    setTheArtist(idArtist);

    fb.firestore
      .collection(idArtist)
      .doc(idTrack2)
      .get()
      .then((res) => {
        const data = res.data();

        if (data) {
          setTheLinks(data.links);
          setTheArtist(data.artist);
          setTheTrack(data.name);
          setTheUser(data.userName);
          changeStoreTrack(data.name);
          changeStoreUser(data.userName);
        } else {
          console.error(404);
        }
      });
  }, [id]);

  return (
    <>
      {theTrack && theUser && (
        <AudioStart theUser={theUser} theTrack={theTrack} />
      )}

      {!isMobile && (
        <div
          style={{
            position: "fixed",
            zIndex: "2",
            padding: "1rem",
            width: "210px",
            bottom: "0",
            left: "0",
          }}
        >
          <Kbd>Scroll</Kbd> + <Kbd>Click & Drag</Kbd>
        </div>
      )}
      {isMobile ? (
        <div
          style={{
            position: "fixed",
            zIndex: "2",
            padding: "1rem",
            width: "65px",
            bottom: "0",
            right: "0",
            cursor: "pointer",
          }}
        >
          <Image
            onClick={() => {
              window.open("https://sounddrop.io", "_self");
            }}
            src="/images/sounddropLogo.png"
          />
        </div>
      ) : (
        <div
          style={{
            position: "fixed",
            zIndex: "2",
            padding: "1rem",
            width: "70px",
            bottom: "0",
            right: "0",
            cursor: "pointer",
          }}
        >
          <Image
            onClick={() => {
              window.open("https://sounddrop.io", "_self");
            }}
            src="/images/sounddropLogo.png"
          />
        </div>
      )}
      <div
        style={{
          position: "fixed",
          zIndex: "1",
          marginTop: "10px",
          paddingRight: "10px",
          width: "100%",
        }}
      >
        <Stack position="center" spacing="xs">
          {theTrack && theArtist && (
            <>
              <Text align="center" size="lg" weight={500} mt="sm">
                {theTrack}
              </Text>
              <Text align="center" size="sm" color="dimmed">
                {theArtist}
              </Text>
            </>
          )}
        </Stack>
      </div>
      <div
        style={{
          position: "fixed",
          zIndex: "1",
          bottom: "10px",

          width: "100%",
        }}
      >
        <Group position="center" sx={{ padding: 15 }}>
          {theLinks?.map((l, index) => {
            let woahThere;

            if (l.icon === "Spotify") {
              woahThere = <BsSpotify width={18} height={18} />;
            }
            if (l.icon === "Apple Music") {
              woahThere = <SiApplemusic width={18} height={18} />;
            }
            if (l.icon === "Tidal") {
              woahThere = <SiTidal width={18} height={18} />;
            }
            if (l.icon === "YouTube") {
              woahThere = <AiFillYoutube width={18} height={18} />;
            }
            if (l.icon === "SoundCloud") {
              woahThere = <RiSoundcloudFill width={18} height={18} />;
            }
            let theUrl = l.url;
            if (!theUrl.includes("https://")) {
              theUrl = "https://" + theUrl;
            }
            return (
              <>
                <div onClick={() => window.open(theUrl)} key={index}>
                  {l.name !== "" && (
                    <Button
                      size="xs"
                      leftIcon={woahThere}
                      variant="default"
                      color="gray"
                      onClick={() => window.open(theUrl)}
                      styles={(theme) => ({
                        root: {
                          height: "27.5px",
                        },
                      })}
                    >
                      {l.name}
                    </Button>
                  )}
                </div>
              </>
            );
          })}
        </Group>

        <Group position="right">
          {isMobile ? (
            <div
              onClick={() => window.open("https://sounddrop.io", "_self")}
              style={{
                fontSize: "10px",
                paddingRight: "0.8rem",
                paddingBottom: "0.7rem",
                marginRight: "3rem",
                cursor: "pointer",
              }}
              className={styles.gradientText10}
            >
              {trademark}
            </div>
          ) : (
            <div
              onClick={() => window.open("https://sounddrop.io", "_self")}
              style={{
                paddingRight: "0.8rem",
                paddingBottom: "0.7rem",
                marginRight: "3rem",
                cursor: "pointer",
              }}
              className={styles.gradientText10}
            >
              {trademark}
            </div>
          )}
        </Group>
      </div>
    </>
  );
};
