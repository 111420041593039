import { useEffect, useState, useRef } from "react";
import * as THREE from "three";
import {
  Canvas,
  extend,
  useFrame,
  useThree,
  useLoader,
} from "@react-three/fiber";

import HyperbolicHelicoidZustand from "./HyperbolicHelicoidZustand";
import {
  Plane,
  MeshReflectorMaterial,
  MeshRefractionMaterial,
  Float,
} from "@react-three/drei";

export default function LoginCanvas(props) {
  const dirLight = useRef();
  var x = 0;
  useFrame((state, delta) => {
    x += delta;
    // dirLight.current.position.x = Math.sin(state.clock.getElapsedTime());
    dirLight.current.rotation.y = Math.cos(x / 2) * Math.PI;
    // dirLight.current.position.z = Math.cos(state.clock.getElapsedTime());
  });

  const [width, setWidth] = useState(window.innerWidth);
  const { camera } = useThree();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  useEffect(() => {
    if (!isMobile) {
      // x: 0, y: -3.2, z: 40
      //   console.log(camera.position);
      //   camera.position.set([0, -3.2, 40]);
      //   camera.position.set(new THREE.Vector3(0, -3.2, 40));
      camera.position.x = 0;
      camera.position.y = -2.2;
      camera.position.z = 40;
    }
    if (isMobile) {
      camera.position.x = 0.5;
      camera.position.y = -1.0;
      camera.position.z = 40;
    }
  }, [isMobile]);

  return (
    <>
      <group position={[2, -3, 0]}>
        {/* <directionalLight position={[0, 1, 0]} intensity={0.5} /> */}
        {/* <pointLight position={[0, 2, 0]} /> */}
        {/* <MainPrism2 /> */}
        <group
          scale={1}
          position={[0, 3, 0]}
          rotation-x={Math.PI / 4}
          ref={dirLight}
        >
          {/* <group ref={logoRef}> */}
          <Float
            speed={2}
            rotationIntensity={1}
            floatIntensity={1}
            floatingRange={[0, 0.75]}
          >
            <HyperbolicHelicoidZustand />
          </Float>

          <mesh rotation-x={-Math.PI / 2} position={[0, -1.3, 0]} receiveShadow>
            <planeGeometry args={[100, 100]} />
            {/* <meshStandardMaterial color="black" /> */}
            <MeshReflectorMaterial
              blur={[10, 10]} // Blur ground reflections (width, heigt), 0 skips blur
              mixBlur={0.8} // How much blur mixes with surface roughness (default = 1)
              mixStrength={0.5} // Strength of the reflections
              mixContrast={1} // Contrast of the reflections
              resolution={1024} // Off-buffer resolution, lower=faster, higher=better quality, slower
              mirror={1} // Mirror environment, 0 = texture colors, 1 = pick up env colors
              depthScale={0} // Scale the depth factor (0 = no depth, default = 0)
              minDepthThreshold={0.9} // Lower edge for the depthTexture interpolation (default = 0)
              maxDepthThreshold={1} // Upper edge for the depthTexture interpolation (default = 0)
              depthToBlurRatioBias={0.5} // Adds a bias factor to the depthTexture before calculating the blur amount [blurFactor = blurTexture * (depthTexture + bias)]. It accepts values between 0 and 1, default is 0.25. An amount > 0 of bias makes sure that the blurTexture is not too sharp because of the multiplication with the depthTexture
              distortion={1} // Amount of distortion based on the distortionMap texture
              // distortionMap={distortionTexture} // The red channel of this texture is used as the distortion map. Default is null
              reflectorOffset={0.2} // Offsets the virtual camera that projects the reflection. Useful when the reflective surface is some distance from the object's origin (default = 0)
            />
          </mesh>

          {/* <Plane
          args={[5, 5]}
          rotation-x={-Math.PI / 2}
          position={[0, -1, 0]}
          receiveShadow
        /> */}
        </group>
      </group>
    </>
  );
}
