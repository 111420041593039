import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

// try {
//   const app = firebase.initializeApp({
//     apiKey: "AIzaSyB5G9Gg2_73JXoxW-lEzIBvC61z-WOiktw",
//     authDomain: "stemm-f4683.firebaseapp.com",
//     projectId: "stemm-f4683",
//     storageBucket: "stemm-f4683.appspot.com",
//     messagingSenderId: "421784872474",
//     appId: "1:421784872474:web:69a7bc74a338ffe5b313a3",
//     measurementId: "G-V05WYRKYVF",
//     storageBucket: "gs://stemm-f4683.appspot.com",
//   });
// } catch (err) {
//   if (!/already exists/u.test(err.message)) {
//     console.error("Firebase admin initialization error", err.stack);
//   }
// }

const firebaseConfig = {
  apiKey: "AIzaSyB5G9Gg2_73JXoxW-lEzIBvC61z-WOiktw",
  authDomain: "sounddrop.io",
  projectId: "stemm-f4683",
  storageBucket: "stemm-f4683.appspot.com",
  messagingSenderId: "421784872474",
  appId: "1:421784872474:web:69a7bc74a338ffe5b313a3",
  measurementId: "G-V05WYRKYVF",
  storageBucket: "gs://stemm-f4683.appspot.com",
};

const app = firebase.initializeApp(firebaseConfig);
const storage = getStorage(app);
const functions = getFunctions(app);
functions.region = "us-west2";

export const fb = {
  firestore: firebase.firestore(),
  auth: firebase.auth(),
  storage: storage,
  functions: functions,
  // app: firebase.app(),
  // app2: app2,
  // app2: app,
};
