import { useState, useRef, useEffect, useMemo } from "react";
import {
  Stepper,
  Button,
  Group,
  TextInput,
  Container,
  Text,
  createStyles,
  Grid,
  Center,
  Loader,
  Stack,
  Alert,
  MediaQuery,
  Menu,
  Kbd,
  Image,
  Select,
  ScrollArea,
  Table,
  Checkbox,
  Switch,
} from "@mantine/core";

import styles from "../styles.module.css";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { IconX, IconDownload } from "@tabler/icons";
import { MdOutlineMusicNote } from "react-icons/md";
import { BsImage } from "react-icons/bs";
import { IoLinkSharp } from "react-icons/io5";
import { Draggable } from "react-beautiful-dnd";
import { TiDelete } from "react-icons/ti";
import Resizer from "react-image-file-resizer";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useAuth } from "../Firebase/hooks";
import { fb } from "../Firebase/Shared/Service";
import { useNavigate } from "react-router-dom";
import { ref, uploadBytesResumable } from "firebase/storage";
import { IconCircleCheck } from "@tabler/icons";
import { BiArrowBack } from "react-icons/bi";
import { BsSpotify } from "react-icons/bs";
import { SiApplemusic } from "react-icons/si";
import { AiFillYoutube } from "react-icons/ai";
import { SiTidal } from "react-icons/si";
import { RiSoundcloudFill } from "react-icons/ri";
import { forwardRef } from "react";
import { TiCancel } from "react-icons/ti";
import { AiOutlinePlus } from "react-icons/ai";
import { useUsernames } from "../Firebase/hooks/useUsernames";
import { doc, getDoc } from "firebase/firestore";
import { Canvas, extend, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls, useCursor, Text3D } from "@react-three/drei";
import PointsWorldContainer4 from "../PointsWorldContainer4";
import { RiImageEditFill } from "react-icons/ri";
import { ImUpload } from "react-icons/im";
import { AsciiEffect } from "three-stdlib";

import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { Badge } from "react-bootstrap";

const PRIMARY_COL_HEIGHT = 300;

const useStyles = createStyles((theme, { opened }) => ({
  wrapper: {
    position: "relative",
    marginBottom: 30,
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: "absolute",
    width: 250,
    left: "calc(50% - 125px)",
    bottom: -20,
  },
  control3: {
    position: "absolute",
    zIndex: 100,
    width: 250,
    left: "calc(50% - 125px)",
    // bottom: -20,
    top: 170,
  },

  control2: {
    // width: 200,
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 15px",
    borderRadius: theme.radius.md,
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2]
    }`,
    transition: "background-color 150ms ease",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[opened ? 5 : 6]
        : opened
        ? theme.colors.gray[0]
        : theme.white,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  label2: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  icon2: {
    transition: "transform 150ms ease",
    transform: opened ? "rotate(180deg)" : "rotate(0deg)",
  },
  rowSelected: {
    // backgroundColor:
    // theme.colorScheme === "dark"
    //   ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
    //   : theme.colors[theme.primaryColor][0],
  },
}));

export default function NewCreatePageM(props) {
  const [checkSubscriptionProp, setcheckSubscriptionProp] = useState(false);
  const [proRole, setProRole] = useState(false);
  const [proPlusRole, setProPlusRole] = useState(false);

  async function getCustomClaimRole() {
    await fb.auth.currentUser.getIdToken(true);
    const decodedToken = await fb.auth.currentUser.getIdTokenResult();
    return decodedToken.claims.stripeRole;
  }

  useEffect(() => {
    getCustomClaimRole().then((res) => {
      // console.log(res);
      if (res === "premium") {
        setcheckSubscriptionProp(true);
        setProRole(true);
      }
      if (res === "premiumPlus") {
        setcheckSubscriptionProp(true);
        setProPlusRole(true);
      }
    });
  }, []);

  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);

  const { classes, theme } = useStyles({ opened });

  const SECONDARY_COL_HEIGHT = PRIMARY_COL_HEIGHT / 2 - theme.spacing.md / 2;
  const [active, setActive] = useState(0);
  const openRef = useRef();
  const [imageUpload, setImageUpload] = useState(null);
  const [trackUpload, setTrackUpload] = useState(null);

  const [imageUploadComplete, setImageUploadComplete] = useState(false);
  const [trackUploadComplete, setTrackUploadComplete] = useState(false);

  const form = useForm({
    initialValues: {
      artistName: "",
      trackName: "",
      links: [{ icon: "", name: "", url: "" }],
    },

    validate: (values) => {
      if (active === 1) {
        return {
          artistName: values.artistName === "" ? "Required Field" : null,
          trackName: values.trackName === "" ? "Required Field" : null,
        };
      }

      return {};
    },
  });

  useEffect(() => {
    setErrorYo("");
  }, [form.values.trackName]);

  const SelectItem = forwardRef(({ label, iconName, ...others }, ref) => (
    <div ref={ref} {...others}>
      {/* <BsSpotify /> */}
      <Group noWrap>
        {/* <Avatar src={image} /> */}
        {label === "None" && <TiCancel width={18} height={18} />}
        {label === "Spotify" && <BsSpotify width={18} height={18} />}
        {label === "Apple Music" && <SiApplemusic width={18} height={18} />}
        {label === "Tidal" && <SiTidal width={18} height={18} />}
        {label === "YouTube" && <AiFillYoutube width={18} height={18} />}
        {label === "SoundCloud" && <RiSoundcloudFill width={18} height={18} />}

        <div>
          <Text size="sm">{label}</Text>
        </div>
      </Group>
    </div>
  ));

  const rows = form.values.links.map((item, index) => {
    let woahThere;
    if (item.icon === "None") {
      woahThere = <TiCancel width={18} height={18} />;
    }
    if (item.icon === "Spotify") {
      woahThere = <BsSpotify width={18} height={18} />;
    }
    if (item.icon === "Apple Music") {
      woahThere = <SiApplemusic width={18} height={18} />;
    }
    if (item.icon === "Tidal") {
      woahThere = <SiTidal width={18} height={18} />;
    }
    if (item.icon === "YouTube") {
      woahThere = <AiFillYoutube width={18} height={18} />;
    }
    if (item.icon === "SoundCloud") {
      woahThere = <RiSoundcloudFill width={18} height={18} />;
    }
    return (
      <tr key={index} className={classes.rowSelected}>
        <td>
          <Select
            itemComponent={SelectItem}
            size="md"
            icon={woahThere}
            style={{ maxWidth: 200 }}
            dropdownPosition="right"
            placeholder="Icon"
            {...form.getInputProps(`links.${index}.icon`)}
            data={[
              { value: "None", label: "None" },
              { value: "Spotify", label: "Spotify" },
              { value: "Apple Music", label: "Apple Music" },
              { value: "Tidal", label: "Tidal" },
              { value: "YouTube", label: "YouTube" },
              { value: "SoundCloud", label: "SoundCloud" },
            ]}
          />
        </td>
        {index % 2 === 0 ? (
          <>
            <td>
              <TextInput
                placeholder="e.g. Spotify"
                {...form.getInputProps(`links.${index}.name`)}
                size="md"
              />
            </td>
            <td>
              <TextInput
                placeholder="https://open.spotify.com/track/..."
                {...form.getInputProps(`links.${index}.url`)}
                size="md"
              />
            </td>
            <td>
              <TiDelete
                style={{ cursor: "pointer" }}
                onClick={() => form.removeListItem("links", index)}
                size={20}
              />
            </td>
          </>
        ) : (
          <>
            <td>
              <TextInput
                placeholder="e.g. Apple Music"
                {...form.getInputProps(`links.${index}.name`)}
              />
            </td>
            <td>
              <TextInput
                placeholder="https://music.apple.com/..."
                {...form.getInputProps(`links.${index}.url`)}
              />
            </td>
            <td>
              <TiDelete
                style={{ cursor: "pointer" }}
                onClick={() => form.removeListItem("links", index)}
                size={20}
              />
            </td>
          </>
        )}
      </tr>
    );
  });
  const [bgValue, setBgValue] = useState("DEFAULT");
  const hundredColors = require("./hundredColors.json");
  const tenColors = require("./tenColors.json");
  const fiftyColors = require("./fiftyColors.json");

  const [pa, setPa] = useState(tenColors);

  const data5 = pa.map((item, index) => (
    <Group spacing={0} position="center" m={25}>
      <Button
        color="gray"
        onClick={() => {
          setFinalPalette(index);
        }}
      >
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[0],
          }}
        ></div>
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[1],
          }}
        ></div>
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[2],
          }}
        ></div>
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[3],
          }}
        ></div>
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[4],
          }}
        ></div>
      </Button>
    </Group>
  ));
  data5.shift();
  useEffect(() => {
    if (checkSubscriptionProp === false) {
      setPa(tenColors);
    }
    if (proRole === true) {
      setPa(fiftyColors);
    }
    if (proPlusRole === true) {
      setPa(hundredColors);
    }
  }, [checkSubscriptionProp, proRole, proPlusRole]);

  let ind = Math.floor(Math.random() * 100);

  const [finalPalette, setFinalPalette] = useState(1);

  const nextStep = () => {
    window.scrollTo(0, 0);

    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 4 ? current + 1 : current;
    });
  };

  const [effectsArray, setEffectsArray] = useState([]);

  const prevStep = () => {
    window.scrollTo(0, 0);

    setActive((current) => (current > 0 ? current - 1 : current));
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        // "base64"
        "file"
      );
    });
  const [paletteHider, setPaletteHider] = useState(false);

  const onChangeImage = async (fileYo) => {
    const file = fileYo;
    const image = await resizeFile(file);
    setImageUpload(image);
  };
  const { authUser } = useAuth();
  const hookUser = useUsernames(authUser?.uid);
  let finalHookUser2;
  const [finalHookUser, setFinalHookUser] = useState();
  const [lowerUserString, setLowerUserString] = useState("");
  useEffect(() => {
    if (hookUser) {
      finalHookUser2 = hookUser[0].userName;
      setFinalHookUser(finalHookUser2);

      let lowest = finalHookUser2.toLowerCase();
      setLowerUserString("SoundDrop.io/" + lowest + "-");
    }
  }, [hookUser]);
  const [errorYo, setErrorYo] = useState();

  const createPage = async (values) => {
    let lowerTrackName2 = values.trackName.toLowerCase();
    const docRef = doc(fb.firestore, finalHookUser, lowerTrackName2);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      prevStep();

      console.log("page already exists");
      setErrorYo(
        "You already have a page with this track name. Please choose a different track name."
      );
    } else {
      nextStep();

      if (authUser) {
        let lowerTrackName = values.trackName.toLowerCase();
        fb.firestore
          .collection(finalHookUser)
          .doc(lowerTrackName)
          .set({
            userId: authUser.uid,
            links: values.links,
            name: lowerTrackName,
            artist: values.artistName,
            userName: finalHookUser,
            effects: effectsArray,
            background: bgValue,
            palette: finalPalette,
          })
          .then(uploadCoverArt(values))
          .then(uploadTrack(values))
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const uploadCoverArt = (values) => {
    let lowerTrackName = values.trackName.toLowerCase();
    // if (imageUpload == null) return;
    const imageRef = ref(
      fb.storage,
      // `users/${authUser.uid}/${pageName}/images/${imageUpload.name + uuid()}`
      // `users/${authUser.uid}/${values.trackName}/images/0`
      `users/${finalHookUser}/${lowerTrackName}/images/0`
    );
    // const imageRef = ref(fb.storage, `users/${auth.user.uid}/images/${imageUpload.name + uuid()} images/${imageUpload.name + uuid()}`);
    // const [loaderProgress, setLoaderProgress] = useState(0);
    const uploadTask = uploadBytesResumable(imageRef, imageUpload);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // loaderProgress =
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");

        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        alert("Error uploading image, please try again");
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        //   console.log("File available at", downloadURL);
        // });
        // console.log("Image Uploaded!");
        // alert("Image Uploaded!");
        setImageUploadComplete(true);
      }
    );

    // uploadBytes(imageRef, imageUpload)
    //   .then((snapshot) => {
    //     console.log("Image Uploaded!");
    //     alert("Image Uploaded!");
    //   })
    //   .then(() => {
    //     setImageUploadComplete(true);
    //   });
  };
  // const { name, aNumber } = useControls({ name: "World", aNumber: 0 });

  useEffect(() => {
    // console.log(effectsArray);
  }, [effectsArray]);
  const uploadTrack = (values) => {
    let lowerTrackName = values.trackName.toLowerCase();

    const trackRef = ref(
      fb.storage,
      // `users/${authUser.uid}/${pageName}/tracks/${trackUpload.name + uuid()}`
      // `users/${authUser.uid}/${values.trackName}/tracks/0`
      `users/${finalHookUser}/${lowerTrackName}/tracks/0`
    );

    const uploadTask = uploadBytesResumable(trackRef, trackUpload);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        alert("Error uploading track, please try again");
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        //   console.log("File available at", downloadURL);
        // });
        // console.log("Track Uploaded!");
        // alert("Track Uploaded!");
        setTrackUploadComplete(true);
      }
    );

    // uploadBytes(trackRef, trackUpload)
    //   .then((snapshot) => {
    //     console.log("Track Uploaded!");
    //     alert("Track Uploaded!");
    //   })
    //   .then(() => {
    //     setTrackUploadComplete(true);
    //   });
  };
  // const [hovered, hover] = useState(true);
  // useCursor("grab", hovered);

  useEffect(() => {
    if (trackUploadComplete && imageUploadComplete) {
      setAlertTrigger(1);
      const timer = setTimeout(() => {
        navigate("/");
      }, 2000);
      return () => clearTimeout(timer);
      //   navigate("/");
    }
  }, [trackUploadComplete, imageUploadComplete]);
  const [alertTrigger, setAlertTrigger] = useState(0);

  useEffect(() => {
    console.log(imageUpload);
  }, [imageUpload]);
  const [preview, setPreview] = useState(null);
  useEffect(() => {
    // create the preview
    // var x = imageUpload;
    // if (x.files.length) {
    //   var test = URL.createObjectURL(x.files[0]);
    // }
    let objectUrl;
    if (imageUpload) {
      objectUrl = URL.createObjectURL(imageUpload);
    }
    setPreview(objectUrl);
    // setPreview(test);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [imageUpload]);

  // useEffect(() => {
  //   if (preview) {
  //     prominent(preview, { amount: 5, format: "hex" }).then((color) => {
  //       // console.log(color);
  //     });
  //   }
  // }, [preview]);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  const [labelArray, setLabelArray] = useState([]);
  const [descriptionArray, setDescriptionArray] = useState([]);
  const [paddingTop, setPaddingTop] = useState(0);
  const [canvasDecider, setCanvasDecider] = useState(800);
  const [navDecider, setNavDecider] = useState("lg");
  const [previewWidth, setPreviewWidth] = useState("53%");
  const [orientationDecider, setOrientationDecider] = useState("vertical");
  const [spacingDecider, setSpacingDecider] = useState("lg");
  const [effectsSize, setEffectsSize] = useState("md");
  const [positionDecider, setPositionDecider] = useState("absolute");
  const [marginY, setMarginY] = useState("md");
  const [backgroundSize, setBackgroundSize] = useState("md");
  const [effectsMarginDecider, setEffectsMarginDecider] = useState("87px");
  const [bgPositionDecider, setBgPositionDecider] = useState("absolute");
  const [bgMarginLeftDecider, setBgMarginLeftDecider] = useState("975px");
  const [bgMarginTopDecider, setBgMarginTopDecider] = useState("87px");
  const [paletteHeightDecider, setPaletteHeightDecider] = useState(500);
  const [bottomNavPosition, setBottomNavPosition] = useState("");
  // const [cameraPositionDecider, setCameraPositionDecider] = useState([
  //   0, 0, 10,
  // ]);
  useEffect(() => {
    if (active === 2 && isMobile) {
      setBottomNavPosition("fixed");
    } else {
      setBottomNavPosition("");
    }
  }, [active, isMobile]);

  useEffect(() => {
    if (!isMobile) {
      // setCameraPositionDecider([0, 0, 10]);
      setBottomNavPosition("");
      setPaletteHeightDecider(500);
      setEffectsMarginDecider("87px");
      setBackgroundSize("md");
      setMarginY("md");
      setPreviewWidth("15%");
      setNavDecider("lg");
      setCanvasDecider(650);
      setPaddingTop(90);
      setLabelArray(["Upload Assets", "Add Links", "Customize"]);
      setOrientationDecider("vertical");
      setSpacingDecider("lg");
      setEffectsSize("md");
      setPositionDecider("absolute");
      setBgPositionDecider("absolute");
      setBgMarginLeftDecider("920px");
      setBgMarginTopDecider("87px");
      // setDescriptionArray(["", "", ""]);
    }
    if (isMobile) {
      // setCameraPositionDecider([0, 0, 1000]);

      // if (active === 0) {
      //   setBottomNavPosition("fixed");
      // }
      // if (active === 2) {
      //   setBottomNavPosition("fixed");
      // }
      setPaletteHeightDecider(250);
      setBgMarginLeftDecider("0px");
      setBgMarginTopDecider("16px");
      setBgPositionDecider("absolute");
      setEffectsMarginDecider("0px");
      setBackgroundSize("md");
      setMarginY(0);
      setPreviewWidth("100%");
      setNavDecider("xs");
      setCanvasDecider(400);
      setLabelArray(["", "", ""]);
      setOrientationDecider("horizontal");
      setSpacingDecider("xs");
      setEffectsSize("sm");
      setPositionDecider("");

      // setLabelArray(["Assets", "Links", "Customize"]);

      setPaddingTop(0);
    }
  }, [isMobile]);

  const [checked, setChecked] = useState(false);

  return (
    <>
      <Container
        pt={80}
        // my={marginY}

        style={{ minHeight: "685px", maxWidth: "1125px" }}
        sx={{
          zIndex: 6,
          position: "relative",
          background: "rgb(0 0 0 / 25%)",
          backdropFilter: "blur(10px)",
          webkitBackdropFilter: "blur(10px)",
          boxShadow: "0px 10px 15px 10px rgb(0 0 0 / 15%)",
          WebkitBoxShadow: "0px 10px 15px 10px rgb(0 0 0 / 15%)",
        }}
      >
        <Stepper
          // hidden={block === false}
          // hidden={!block}
          active={active}
          breakpoint="xs"
          size={navDecider}
        >
          <Stepper.Step
            label={labelArray[0]}
            // icon={<BsImage />}
            icon={<ImUpload />}
            description={descriptionArray[0]}
          >
            <Grid mb={25} gutter="xl">
              {/* <TextInput
              label="Username"
              placeholder="Username"
              {...form.getInputProps("username")}
            />
            <PasswordInput
              mt="md"
              label="Password"
              placeholder="Password"
              {...form.getInputProps("password")}
            /> */}
              {/* <div className={classes.wrapper}> */}
              <Grid.Col sm="auto" md="auto" lg="auto" xl="auto">
                <Stack spacing={0}>
                  <Dropzone
                    mt={15}
                    openRef={openRef}
                    onDrop={(file) => {
                      //   resizeFunction(file)
                      //   console.log(file);
                      //   setImageUpload(file[0]);
                      onChangeImage(file[0]);

                      //   console.log(file);
                      //   const formData = new FormData();
                      //   formData.append("file", file[0]);
                    }}
                    className={classes.dropzone}
                    radius="md"
                    // accept={[MIME_TYPES.image]}
                    accept={{
                      "image/*": [],
                    }}
                    // maxSize={15 * 1024 ** 2}
                  >
                    <div style={{ pointerEvents: "none" }}>
                      <Group position="center">
                        <Dropzone.Accept>
                          <IconDownload
                            size={50}
                            color={theme.colors[theme.primaryColor][6]}
                            stroke={1.5}
                          />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                          <IconX
                            size={50}
                            color={theme.colors.red[6]}
                            stroke={1.5}
                          />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                          {!imageUpload ? (
                            <BsImage
                              size={50}
                              color={
                                theme.colorScheme === "dark"
                                  ? theme.colors.dark[0]
                                  : theme.black
                              }
                              stroke={1.5}
                            />
                          ) : (
                            // <BsFillCheckCircleFill color="green" size={50} />
                            <div style={{ width: 50 }}>
                              <Image
                                radius="md"
                                alt="uploaded image"
                                src={preview}
                              />
                            </div>
                          )}
                        </Dropzone.Idle>
                      </Group>

                      <Text align="center" weight={700} size="lg" mt="xl">
                        <Dropzone.Accept>Drop Image here</Dropzone.Accept>
                        <Dropzone.Reject>Must be image file</Dropzone.Reject>
                        <Dropzone.Idle>Upload Cover Art</Dropzone.Idle>
                      </Text>
                      <Text align="center" size="sm" mt="xs" color="dimmed">
                        Drag & Drop image here to upload.
                      </Text>
                    </div>
                  </Dropzone>
                  <Button
                    // className={classes.control3}
                    size="md"
                    // radius="xl"
                    // onClick={() => openRef.current?.()}
                    onClick={() => {
                      document.getElementById("imageYo").click();
                    }}
                  >
                    <input
                      style={{
                        display: "none",
                      }}
                      type="file"
                      id="imageYo"
                      name="imageYo"
                      accept="image/*"
                      //   onChange={(event) => {
                      //     setImageUpload(event.target.files[0]);
                      //   }}
                      onChange={(event) => {
                        onChangeImage(event.target.files[0]);
                      }}
                    />
                    Select Cover Art
                  </Button>
                </Stack>
              </Grid.Col>
              <Grid.Col sm="auto" md="auto" lg="auto" xl="auto">
                <Stack spacing={0}>
                  <Dropzone
                    mt={15}
                    openRef={openRef}
                    onDrop={(file) => {
                      //   console.log(file);
                      setTrackUpload(file[0]);
                    }}
                    className={classes.dropzone}
                    radius="md"
                    accept={{
                      "audio/*": ["mp3"],
                    }}
                    maxSize={15 * 1024 ** 2}
                  >
                    <div style={{ pointerEvents: "none" }}>
                      <Group position="center">
                        <Dropzone.Accept>
                          <IconDownload
                            size={50}
                            color={theme.colors[theme.primaryColor][6]}
                            stroke={1.5}
                          />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                          <IconX
                            size={50}
                            color={theme.colors.red[6]}
                            stroke={1.5}
                          />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                          {!trackUpload ? (
                            <MdOutlineMusicNote
                              size={50}
                              color={
                                theme.colorScheme === "dark"
                                  ? theme.colors.dark[0]
                                  : theme.black
                              }
                              stroke={1.5}
                            />
                          ) : (
                            <BsFillCheckCircleFill color="green" size={50} />
                          )}
                        </Dropzone.Idle>
                      </Group>

                      <Text align="center" weight={700} size="lg" mt="xl">
                        <Dropzone.Accept>Drop mp3 here</Dropzone.Accept>
                        <Dropzone.Reject>Must be audio file</Dropzone.Reject>
                        <Dropzone.Idle>Upload Track</Dropzone.Idle>
                      </Text>
                      <Text align="center" size="sm" mt="xs" color="dimmed">
                        Drag & Drop <i>.mp3</i> here to upload. Max size 15MB.
                      </Text>
                    </div>
                  </Dropzone>
                  <Button
                    // className={classes.control}
                    size="md"
                    // radius="xl"
                    onClick={() => {
                      document.getElementById("track").click();
                    }}
                  >
                    <input
                      style={{
                        display: "none",
                      }}
                      type="file"
                      id="track"
                      name="track"
                      accept="audio/mp3"
                      onChange={(event) => {
                        setTrackUpload(event.target.files[0]);
                      }}
                    />
                    Select Track
                  </Button>
                  <Group spacing={5} position="center" mt={15}>
                    <Checkbox
                      checked={checked}
                      onChange={(event) =>
                        setChecked(event.currentTarget.checked)
                      }
                    />
                    <Text
                      sx={{ marginBottom: 7.5, fontWeight: 600, fontSize: 13 }}
                    >
                      I have full rights to this track and allow SoundDrop to
                      stream it on their platform
                    </Text>
                  </Group>
                </Stack>
              </Grid.Col>
            </Grid>
          </Stepper.Step>

          <Stepper.Step
            description={descriptionArray[1]}
            label={labelArray[1]}
            icon={<IoLinkSharp />}
            // description="Social media"
          >
            <Group position="center" grow>
              <TextInput
                style={{ maxWidth: "50%" }}
                mt={20}
                label="Artist Name"
                required
                //   placeholder=""
                {...form.getInputProps("artistName")}
                size="md"
              />
              <TextInput
                style={{ maxWidth: "50%" }}
                mt={20}
                // placeholder={lowerUserString}
                // iconWidth={190}
                // icon={
                //   <Text ml={0} mt={1} size="sm">
                //     {/* {"website.com/" + { lowerUser } + "-"} */}
                //     {lowerUserString}
                //   </Text>
                // }
                label="Track Name"
                size="md"
                // onChange={(e) => {
                //   // console.log("aa");
                //   setErrorYo("");
                // }}
                required
                //   placeholder="GitHub"
                {...form.getInputProps("trackName")}
              />

              {/* <Button
                // disabled={claimDisabled}
                style={{ height: "40px" }}
                onClick={setLink}
              >
                Claim your link
              </Button> */}
            </Group>
            <Group position="center" mt={20} grow>
              <TextInput
                disabled
                label="Public URL"
                style={{ maxWidth: "600%" }}
                size="sm"
                radius="xl"
                value={
                  lowerUserString +
                  form.values.trackName.toLowerCase().replace(/ /g, "-")
                }
                placeholder={lowerUserString + "track-name"}
              />
            </Group>
            <div
              style={{
                marginTop: "10px",
                marginLeft: "10px",
                // fontWeight: "bold",
                color: "red",
                fontSize: "13px",
              }}
            >
              {errorYo}
            </div>
            {/* </Stack> */}
            {/* </Center> */}
            {/* <Container sx={{ backgroundColor: "red" }}> */}
            <ScrollArea
              mb={10}
              pt={30}
              scrollbarSize={6}

              // sx={{ backgroundColor: theme.colors.dark }}
            >
              <Table sx={{ minWidth: 374 }} verticalSpacing="md">
                <thead>
                  <tr>
                    <th>Icon</th>
                    <th>
                      <Group spacing={4}>
                        Link Label<Text color="red">*</Text>
                      </Group>
                    </th>
                    <th>Link URL</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
              <Group position="center" mt="xl" pb={30} mb={40}>
                <Button
                  onClick={() =>
                    form.insertListItem("links", {
                      icon: "",
                      name: "",
                      url: "",
                    })
                  }
                >
                  Add Link
                  <AiOutlinePlus style={{ marginLeft: "5" }} size={11} />
                </Button>
              </Group>
            </ScrollArea>

            {/* <Image alt="Image Preview" src={preview} /> */}

            {/* <Code block mt="xl">
              {JSON.stringify(form.values, null, 2)}
            </Code>{" "}
            */}
            {/* </Container> */}
          </Stepper.Step>
          <Stepper.Step
            description={descriptionArray[2]}
            label={labelArray[2]}
            icon={<RiImageEditFill />}
          >
            {/* <Group position="apart"> */}
            <div
              style={{
                position: positionDecider,
                zIndex: "1",
                //   padding: "1rem",
                // paddingRight: "200px",
                marginTop: effectsMarginDecider,
                width: previewWidth,
              }}
            >
              <Switch.Group
                // defaultValue={["PHANTOM"]}

                orientation={orientationDecider}
                label="Filters"
                spacing={spacingDecider}
                offset={spacingDecider}
                size={effectsSize}
                value={effectsArray}
                onChange={setEffectsArray}
              >
                <Switch value="NONE" label="NONE" />

                <Switch
                  value="PHANTOM"
                  label="PHANTOM"
                  disabled={effectsArray.includes("NONE")}
                  // onLabel={
                  //   <FaGhost
                  //     size={13}
                  //     stroke={2.5}
                  //     color={theme.colors.white}
                  //   />
                  // }
                  // offLabel={
                  //   <FaGhost
                  //     size={13}
                  //     stroke={2.5}
                  //     color={theme.colors.gray[6]}
                  //   />
                  // }
                />
                <Switch
                  value="PRISMA"
                  label="PRISMA"
                  disabled={effectsArray.includes("NONE")}
                />

                <Switch
                  disabled={checkSubscriptionProp === false}
                  value="NOIR"
                  label="NOIR"
                  onLabel={"PRO"}
                  offLabel={"PRO"}
                />
                {/* <div className={styles.gradientText}>NOIR</div> */}
                {/* </div> */}
                <Switch
                  disabled={checkSubscriptionProp === false}
                  value="RGB"
                  label="RGB"
                  onLabel={"PRO"}
                  offLabel={"PRO"}
                />
              </Switch.Group>
            </div>
            <div
              style={{
                // position: "absolute",
                // position: positionDecider,
                position: bgPositionDecider,
                zIndex: "1",
                marginTop: bgMarginTopDecider,
                marginLeft: bgMarginLeftDecider,
                width: previewWidth,
                maxWidth: "200px",
                // marginTop: "10px",
              }}
            >
              <Select
                label="Background"
                placeholder="DEFAULT"
                // width="md"
                size={backgroundSize}
                // maxLength={5}
                value={bgValue}
                onChange={setBgValue}
                data={[
                  { value: "default", label: "DEFAULT" },
                  { value: "dream", label: "DREAM" },
                  { value: "astra", label: "ASTRA" },
                  {
                    value: "amethyst",
                    label: "AMETHYST (PRO)",
                    disabled: !checkSubscriptionProp,
                  },
                ]}
              />
              {bgValue === "dream" && checkSubscriptionProp === false && (
                <>
                  <div style={{ cursor: "pointer", marginTop: 10 }}>
                    <Badge
                      onClick={() =>
                        window.open("https://www.sounddrop.io/pricing")
                      }
                      mt={10}
                      color="gray"
                      variant="outline"
                    >
                      Go PRO for more palettes
                    </Badge>
                  </div>
                  <Menu mt={15} mb={15}>
                    <Menu.Target>
                      <Button
                        size={backgroundSize}
                        rightIcon={
                          paletteHider ? (
                            <RiArrowDropDownLine size={20} />
                          ) : (
                            <RiArrowDropUpLine size={20} />
                          )
                        }
                        color="gray"
                        onClick={() => {
                          setPaletteHider(!paletteHider);
                        }}
                      >
                        Select Palette
                      </Button>
                    </Menu.Target>
                    <ScrollArea
                      hidden={paletteHider}
                      // mt={20}
                      style={{ height: paletteHeightDecider, width: 200 }}
                      // style={{ height: 50, width: 200 }}
                      type="always"
                    >
                      {data5}
                      {/* <div style={{ width: 5000 }}>
                      <Group> {data5}</Group>
                    </div> */}
                    </ScrollArea>
                  </Menu>
                </>
              )}
              {bgValue === "dream" && checkSubscriptionProp === true && (
                <>
                  <Menu mt={15} mb={15}>
                    <Menu.Target>
                      <Button
                        size={backgroundSize}
                        rightIcon={
                          paletteHider ? (
                            <RiArrowDropDownLine size={20} />
                          ) : (
                            <RiArrowDropUpLine size={20} />
                          )
                        }
                        color="gray"
                        onClick={() => {
                          setPaletteHider(!paletteHider);
                        }}
                      >
                        Select Palette
                      </Button>
                    </Menu.Target>
                    <ScrollArea
                      hidden={paletteHider}
                      // mt={20}
                      style={{ height: paletteHeightDecider, width: 200 }}
                      // style={{ height: 50, width: 200 }}
                      type="always"
                    >
                      {data5}
                      {/* <div style={{ width: 5000 }}>
                      <Group> {data5}</Group>
                    </div> */}
                    </ScrollArea>
                  </Menu>
                </>
              )}
            </div>

            <Stack
              position="center"
              spacing="xs"
              hidden={isMobile}
              //   sx={{ padding: 15 }}
            >
              {/* {theTrack && theArtist && ( */}
              <>
                <Text align="center" size="lg" weight={500} mt="sm">
                  {/* {theTrack} */}
                  {form.values.trackName}
                </Text>
                <Text align="center" size="sm" color="dimmed">
                  {/* {theArtist} */}
                  {form.values.artistName}
                </Text>
              </>
              {/* )} */}
            </Stack>

            {/* </div> */}

            {/* go here */}
            <Center>
              {/* <Button onClick={() => setBlur(!blur)}>Motion Blur</Button> */}
              <div
                style={{
                  paddingTop: "20px",
                  // visibility: "hidden",
                  // display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  width: canvasDecider,
                  height: canvasDecider,
                }}
              >
                {!isMobile && (
                  <Center>
                    <div
                      style={{
                        position: "fixed",
                        zIndex: "5",
                        padding: "1rem",
                        width: "210px",
                        bottom: "0",
                        // left: "0",
                      }}
                    >
                      <Kbd>Scroll</Kbd> + <Kbd>Click & Drag</Kbd>
                    </div>
                  </Center>
                )}

                {/* <SegmentedControl orientation="vertical" /> */}

                <Canvas
                  className={styles.grabbable}
                  style={{
                    outline: "none !important",
                  }}
                  shadows
                  dpr={[1, 2]}
                  camera={{
                    position: [0, 0, 10],
                    // position: cameraPositionDecider,
                    near: 0.001,
                    far: 2000,
                  }}
                  linear
                  flat
                >
                  {/* <color attach="background" args={[color]} />    */}
                  <color attach="background" args={["#000000"]} />
                  {/* <Leva fill /> */}

                  <>
                    <ambientLight intensity={2} />

                    <OrbitControls
                      enablePan={false}
                      // enableDamping={true}
                      // dampingFactor={-0.01}
                      minDistance={150}
                      maxDistance={1000}
                      rotateSpeed={0.4}
                      zoomSpeed={0.4}
                      // autoRotate
                      // autoRotateSpeed={4}
                    />
                  </>
                  {/* {style === "ng" ? (
                    <>
                      <Torusknot />
                      <AsciiRenderer
                        invert
                        characters={" " + form.values.artistName.toLowerCase()}
                      />
                    </>
                  ) : ( */}
                  <PointsWorldContainer4
                    palette={finalPalette}
                    bgProp={bgValue}
                    useThisImage={preview}
                    // blur={blur}
                    style={effectsArray}

                    // theUser={form.values.artistName}
                    // theTrack={form.values.trackName}
                  />
                  {/* )} */}
                </Canvas>
              </div>
            </Center>

            {/* <div
              style={{
                position: "absolute",
                zIndex: "1000000",
                bottom: "10px",
                paddingLeft: "190px",
                // bottom: "10px",
                // alignItems: "center",
                // justifyContent
                width: previewWidth,
                // height: "65px",
              }}
            > */}
            {/* <Center> */}

            <Group
              position="center"
              //   spacing="xs"
              sx={{ padding: 15 }}
              hidden={isMobile}
            >
              {/* {page?.links?.map((l, index) => { */}
              {form.values.links?.map((l, index) => {
                let woahThere;
                //  if (item.icon === "None") {
                //    // setIconPlaceholder(<TiCancel width={18} height={18} />);
                //    woahThere = <TiCancel width={18} height={18} />;
                //  }
                if (l.icon === "Spotify") {
                  // setIconPlaceholder(<BsSpotify width={18} height={18} />);
                  woahThere = <BsSpotify width={18} height={18} />;
                }
                if (l.icon === "Apple Music") {
                  // setIconPlaceholder(<SiApplemusic width={18} height={18} />);
                  woahThere = <SiApplemusic width={18} height={18} />;
                }
                if (l.icon === "Tidal") {
                  // setIconPlaceholder(<SiTidal width={18} height={18} />)
                  woahThere = <SiTidal width={18} height={18} />;
                }
                if (l.icon === "YouTube") {
                  // setIconPlaceholder(<AiFillYoutube width={18} height={18} />);
                  woahThere = <AiFillYoutube width={18} height={18} />;
                }
                if (l.icon === "SoundCloud") {
                  // setIconPlaceholder(<RiSoundcloudFill width={18} height={18} />);
                  woahThere = <RiSoundcloudFill width={18} height={18} />;
                }
                let theUrl = l.url;
                if (!theUrl.includes("https://")) {
                  theUrl = "https://" + theUrl;
                }
                // console.log(theUrl);
                return (
                  <>
                    <div
                      onClick={() => window.open(theUrl)}
                      key={index}
                      //   className={styles.page}
                    >
                      {l.name !== "" && (
                        <Button
                          size="xs"
                          //   compact
                          leftIcon={woahThere}
                          variant="default"
                          color="gray"
                          // mb={10}
                          // mr={10}
                          // ml={10}
                          onClick={() => window.open(theUrl)}
                          styles={(theme) => ({
                            root: {
                              height: "27.5px",
                              //   padding: "0 7px",
                              //   backgroundColor: "#00acee",
                              //   border: 0,
                              //   height: 42,
                              //   paddingLeft: 20,
                              //   paddingRight: 20,
                            },
                            //   fontSize: theme.fontSizes.xs,
                            //   "@media (max-width: 380px)": {
                            //     root: {
                            //       height: "100px",
                            //     },
                            //   },
                          })}
                        >
                          {l.name}
                        </Button>
                      )}
                    </div>
                  </>
                );
              })}
            </Group>
            {/* </Center> */}
            {/* </div> */}
          </Stepper.Step>
          <Stepper.Completed>
            {alertTrigger === 1 ? (
              <Alert
                mt="xl"
                icon={<IconCircleCheck size={16} />}
                title="Success!"
                color="green"
                withCloseButton
                onClick={() => {
                  //   setAlertTrigger(2);
                  navigate("/");
                }}
              >
                Your files have been uploaded
              </Alert>
            ) : (
              <Center mt="xl">
                <Stack>
                  Uploading files
                  <Center>
                    <Loader variant="bars" mt="md" />
                  </Center>
                </Stack>
              </Center>
            )}
            {/* <Progress
              value={loaderProgress}
              label="image upload progress"
              size="xl"
              radius="xl"
            /> */}
            {/* {console.log(form.values.artistName)} */}
            {/* Completed! Form values:
            <Code block mt="xl">
              {JSON.stringify(form.values, null, 2)}
            </Code> */}
            {/* {createPage(form.values)} */}
          </Stepper.Completed>
        </Stepper>
        {/* <Group position="left" mt="xl">
          {active !== 3 && (
            <Button
              variant="default"
              onClick={() => {
                navigate("/");
              }}
            >
              <BiArrowBack style={{ marginRight: "8px", marginTop: "1px" }} />
              Cancel
            </Button>
          )}
        </Group> */}

        {/* <Group position="right" mt="xl"> */}
        <MediaQuery largerThan={500} styles={{ display: "none" }}>
          <div
            style={{
              //   position: "absolute",
              // position: "fixed",
              position: bottomNavPosition,
              bottom: "10px",
              width: "98%",
              height: "65px",
            }}
          >
            <Grid grow gutter="xs" mt="lg">
              <MediaQuery smallerThan={400} styles={{ display: "none" }}>
                <Grid.Col span={8}>
                  {active !== 3 && (
                    <Button
                      //   variant="default"
                      variant="light"
                      color="red"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      <BiArrowBack
                        style={{ marginRight: "8px", marginTop: "1px" }}
                      />
                      Cancel
                    </Button>
                  )}
                </Grid.Col>
              </MediaQuery>

              <MediaQuery largerThan={400} styles={{ display: "none" }}>
                <Grid.Col span={1}>
                  {active !== 3 && (
                    <Button
                      variant="light"
                      color="red"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      <BiArrowBack
                        style={{ marginRight: "8px", marginTop: "1px" }}
                      />
                      Cancel
                    </Button>
                  )}
                </Grid.Col>
              </MediaQuery>

              <Grid.Col span={1}>
                {active !== 0 && active !== 4 && (
                  <Button variant="default" onClick={prevStep}>
                    Back
                  </Button>
                )}
              </Grid.Col>
              <Grid.Col span="auto">
                {active == 0 && (
                  <Button
                    disabled={!imageUpload || !checked || !trackUpload}
                    onClick={nextStep}
                    variant="light"
                  >
                    Next
                  </Button>
                )}
                {active == 1 && (
                  <Button
                    disabled={!trackUpload}
                    onClick={nextStep}
                    variant="light"
                  >
                    Next
                  </Button>
                )}

                {active == 2 && (
                  <Button
                    onClick={() => {
                      createPage(form.values);
                    }}
                    variant="light"
                    color="green"
                  >
                    Submit
                  </Button>
                )}
              </Grid.Col>
            </Grid>
          </div>
        </MediaQuery>
        <MediaQuery smallerThan={500} styles={{ display: "none" }}>
          <Grid grow gutter="xs" mt="lg">
            <MediaQuery smallerThan={400} styles={{ display: "none" }}>
              <Grid.Col span={8}>
                {active !== 3 && (
                  <Button
                    //   variant="default"
                    variant="light"
                    color="red"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <BiArrowBack
                      style={{ marginRight: "8px", marginTop: "1px" }}
                    />
                    Cancel
                  </Button>
                )}
              </Grid.Col>
            </MediaQuery>

            <MediaQuery largerThan={400} styles={{ display: "none" }}>
              <Grid.Col span={1}>
                {active !== 3 && (
                  <Button
                    variant="light"
                    color="red"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <BiArrowBack
                      style={{ marginRight: "8px", marginTop: "1px" }}
                    />
                    Cancel
                  </Button>
                )}
              </Grid.Col>
            </MediaQuery>

            <Grid.Col span={1}>
              {active !== 0 && active !== 3 && (
                <Button variant="default" onClick={prevStep}>
                  Back
                </Button>
              )}
            </Grid.Col>
            <Grid.Col span="auto">
              {active == 0 && (
                <Button
                  disabled={!imageUpload || !checked || !trackUpload}
                  onClick={nextStep}
                  variant="light"
                >
                  Next
                </Button>
              )}
              {active == 1 && (
                <Button
                  disabled={!trackUpload}
                  onClick={nextStep}
                  variant="light"
                >
                  Next
                </Button>
              )}

              {active == 2 && (
                <Button
                  onClick={() => {
                    createPage(form.values);
                  }}
                  variant="light"
                  color="green"
                >
                  Submit
                </Button>
              )}
            </Grid.Col>
          </Grid>
        </MediaQuery>

        {/* {active !== 3 && <Button onClick={nextStep}>Next</Button>} */}
        {/* </Group> */}
      </Container>
    </>
  );
}
function AsciiRenderer({
  renderIndex = 1,
  characters = " Rufus Du Sol",
  // characters={form.valu/es.}
  // invert,
  ...options
}) {
  // Reactive state
  const { size, gl, scene, camera } = useThree();

  // Create effect
  const effect = useMemo(() => {
    const effect = new AsciiEffect(gl, characters, options);
    effect.domElement.style.position = "absolute";
    effect.domElement.style.top = "0px";
    effect.domElement.style.left = "0px";
    effect.domElement.style.color = "white";
    effect.domElement.style.backgroundColor = "black";
    effect.domElement.style.pointerEvents = "none";
    return effect;
  }, [characters, options.invert]);

  // Append on mount, remove on unmount
  useEffect(() => {
    gl.domElement.parentNode.appendChild(effect.domElement);
    return () => gl.domElement.parentNode.removeChild(effect.domElement);
  }, [effect]);

  // Set size
  useEffect(() => {
    effect.setSize(size.width, size.height);
  }, [effect, size]);

  // Take over render-loop (that is what the index is for)
  useFrame((state) => {
    effect.render(scene, camera);
  }, renderIndex);

  // This component returns nothing, it has no view, it is a purely logical
}
function Torusknot(props) {
  const ref = useRef();
  const [clicked, click] = useState(false);
  const [hovered, hover] = useState(false);
  useCursor(hovered);
  useFrame((state, delta) => (ref.current.rotation.y += delta / 2));
  return (
    <>
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <pointLight position={[-10, -10, -10]} />
      {/* <mesh
        {...props}
        ref={ref}
        scale={1}
        onClick={() => click(!clicked)}
        onPointerOver={() => hover(true)}
        onPointerOut={() => hover(false)}
      >
        <torusKnotGeometry args={[200, 20, 128, 320]} />
        <meshStandardMaterial color="orange" />
      </mesh> */}
      <group ref={ref}>
        {/* <Center> */}
        <Text3D
          position={[-600, -80, 0]}
          bevelEnabled
          bevelSize={0.05}
          bevelThickness={10}
          font={"/fonts/Inter_Bold.json"}
          size={200}
        >
          physical
          <meshStandardMaterial color="orange" />
        </Text3D>
        {/* </Center> */}
      </group>
    </>
  );
}
