import React, { useState } from "react";
import { SegmentedControl } from "@mantine/core";
import useStore2 from "../../store2";

export default function Segmented() {
  const [value, setValue] = useState("DREAM");
  const changeLandingBackground = useStore2(
    (state) => state.changeLandingBackground
  );

  return (
    <SegmentedControl
      mt={30}
      size="md"
      value={value}
      onChange={(e) => {
        setValue(e);
        changeLandingBackground(e);
      }}
      data={[
        { label: "DREAM", value: "DREAM" },
        { label: "ASTRA", value: "ASTRA" },
        { label: "PRISMA", value: "PRISMA" },

        { label: "and more", value: "NONE", disabled: true },
      ]}
    />
  );
}
