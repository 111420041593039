import { useFrame, extend, useThree } from "@react-three/fiber";
import React, { useEffect, useRef, useState, useMemo } from "react";
import PointsWorld5 from "./PointsWorld5";
import { fb } from "./Firebase/Shared/Service";
import { useAuth } from "./Firebase/hooks";
import {
  getStorage,
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
} from "firebase/storage";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDownloadURL } from "react-firebase-hooks/storage";
import * as THREE from "three";
import { uuid } from "./Firebase/Shared";
// import { Text3D, Center } from "@react-three/drei";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import { SobelOperatorShader } from "three/examples/jsm/shaders/SobelOperatorShader";
import { DotScreenShader } from "three/examples/jsm/shaders/DotScreenShader";
import { RGBShiftShader } from "three/examples/jsm/shaders/RGBShiftShader";
import { FilmShader } from "three/examples/jsm/shaders/FilmShader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { BokehPass } from "three/examples/jsm/postprocessing/BokehPass.js";
import { BloomPass } from "three/examples/jsm/postprocessing/BloomPass.js";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass.js";
import PointsWorld6 from "./PointsWorld6";
import { HalftonePass } from "three/examples/jsm/postprocessing/HalftonePass.js";

import { SubsurfaceScatteringShader } from "three/examples/jsm/shaders/SubsurfaceScatteringShader.js";
import { TechnicolorShader } from "three/examples/jsm/shaders/TechnicolorShader.js";
import { ToonShader1 } from "three/examples/jsm/shaders/ToonShader.js";
import { TriangleBlurShader } from "three/examples/jsm/shaders/TriangleBlurShader.js";
import { VerticalBlurShader } from "three/examples/jsm/shaders/VerticalBlurShader.js";
import { WaterRefractionShader } from "three/examples/jsm/shaders/WaterRefractionShader.js";
import { PixelShader } from "three/examples/jsm/shaders/PixelShader.js";
// import { ParallaxShader } from "three/examples/jsm/shaders/ParallaxShader.js";
import { KaleidoShader } from "three/examples/jsm/shaders/KaleidoShader.js";
import { ConvolutionShader } from "three/examples/jsm/shaders/ConvolutionShader.js";
import { CustomPass } from "./Scenes/CustomPass";
import { isMobile } from "react-device-detect";

// import { WaterPass } from "./Waterpass";

extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  // UnrealBloomPass,
  // FilmPass,
  // WaterPass,
  AfterimagePass,
  // FXAAShader,
});
extend({ OrbitControls });

// const DownloadURL = (pageNameStore, authUser) => {
//   // const [value, loading, error] = useDownloadURL(
//   //   ref(fb.storage, `users/${authUser.authUser.uid}/${pageNameStore}/images/`)
//   // );
//   // console.log(value, "value");
//   // console.log(loading, "loading");
//   // console.log(error, "error");
//   // console.log(pageNameStore);
//   // console.log(authUser.authUser.uid);
// };

const getResizedImage = async (path) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const img = new Image();
  img.crossOrigin = "anonymous";
  img.src = path;

  await img.decode();

  canvas.width = 1000;
  canvas.height = 1000;

  // step 1 - resize to 50%
  const oc = document.createElement("canvas");
  const octx = oc.getContext("2d");

  oc.width = 1000;
  oc.height = 1000;
  octx.drawImage(img, 0, 0, oc.width, oc.height);

  // step 2
  octx.drawImage(oc, 0, 0, oc.width, oc.height);

  // step 3, resize to final size
  ctx.drawImage(
    oc,
    0,
    0,
    oc.width,
    oc.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  // console.log({ w: canvas.width, imagewidth: img.width });

  return canvas.toDataURL();
};
export default function PointsWorldContainer3(props) {
  // console.log("reached");
  const pointsRef = useRef();
  // useFrame((state, delta) => {
  //   // pointsRef.current.rotation.y = THREE.MathUtils.lerp(
  //   //   pointsRef.current.rotation.y,
  //   //   state.mouse.x * Math.PI * 0.18,
  //   //   // 0.005
  //   //   0.01
  //   //   // 0.015
  //   // );
  //   // pointsRef.current.rotation.x = THREE.MathUtils.lerp(
  //   //   pointsRef.current.rotation.x,
  //   //   state.mouse.y * -Math.PI * 0.18,
  //   //   // 0.005
  //   //   0.01
  //   //   // 0.015
  //   // );
  // });
  const authUser = useAuth();
  const [imageList, setImageList] = useState([]);
  const [woahImage, setWoahImage] = useState("");
  // const pageId = useStore2((state) => state.pageId);
  const [pageNameState, setPageNameState] = useState(null);
  const [finalImageUrl, setFinalImageUrl] = useState(null);
  // const pageNameStore = useStore2((state) => state.pageNameStore);
  // const [pageTrigger, setPageTrigger] = useState(false);

  // console.log(pageId);

  // if (pageId !== null) {
  //   // console.log(
  //   fb.firestore
  //     .doc(pageId)
  //     .get()
  //     .then((res) => {
  //       const data = res.data();
  //       // console.log(data);
  //       // console.log(data.links[0]);
  //       if (data) {
  //         // setPageName(data.name);
  //         // setPageLinks(data.links);
  //         // console.log(data.name);
  //         setPageNameState(data.name);
  //       } else {
  //         console.error(404);
  //       }
  //     });
  //   // );
  // }
  // Original cloud storage image retrieval
  // useEffect(() => {
  //   if (pageNameState !== null) {
  //     const imageListRef = ref(
  //       fb.storage,
  //       `users/${authUser.authUser.uid}/${pageNameState}/images/`
  //     );
  //     listAll(imageListRef)
  //       .then((res) => {
  //         res.items.forEach((itemRef) => {
  //           getDownloadURL(itemRef).then((url) => {
  //             setImageList((prev) => [...prev, url]);
  //           });
  //         });
  //       })
  //       // .then(() => {
  //       //   // while (finalImageUrl === null) {
  //       //   console.log(imageList[0], "image list console log");
  //       //   setFinalImageUrl(imageList[0]);
  //       //   console.log(finalImageUrl, "final image url");
  //       //   // }
  //       // })
  //       .catch((error) => {
  //         console.log(error, "error while fetching image");
  //       });
  //   }
  // }, [pageNameState]);

  // useEffect(() => {
  //   if (pageNameStore !== null) {
  //     setPageTrigger(true);
  //     // console.log("ready");
  //     DownloadURL(pageNameStore, authUser);
  //   }
  // }, [pageNameStore]);

  // console.log(props.pageNameStore);

  // useEffect(() => {
  //   if (pageTrigger === true) {
  //     const imageListRef = ref(
  //       fb.storage,
  //       `users/${authUser.authUser.uid}/${pageNameState}/images/`
  //     );
  //     listAll(imageListRef)
  //       .then((res) => {
  //         res.items.forEach((itemRef) => {
  //           getDownloadURL(itemRef).then((url) => {
  //             setImageList((prev) => [...prev, url]);
  //           });
  //         });
  //       })
  //       // .then(() => {
  //       //   // while (finalImageUrl === null) {
  //       //   console.log(imageList[0], "image list console log");
  //       //   setFinalImageUrl(imageList[0]);
  //       //   console.log(finalImageUrl, "final image url");
  //       //   // }
  //       // })
  //       .catch((error) => {
  //         console.log(error, "error while fetching image");
  //       });
  //   }
  //   // console.log(pageNameStore);
  // }, []);

  //   const [value, loading, error] = useDownloadURL(
  //     ref(
  //       fb.storage,
  //       // `users/${authUser.authUser.uid}/${props.pageNameStore}/images/`
  //       // `users/${props.authUserProp}/${props.pageNameStore}/images/0`
  //       `users/${props.theUser}/${props.theTrack}/images/0`
  //     )
  //   );
  //   // console.log("value: ", value, uuid());
  //   // console.log("loading: ", loading);
  //   if (error) {
  //     console.log(error, "error");
  //   }
  useEffect(() => {
    if (props.useThisImage !== null) {
      setWoahImage(props.useThisImage);
    }
  });

  // console.log(props.authUserProp);

  // useEffect(() => {
  //   // console.log(imageList, "image list");
  //   if (imageList.length >= 1) {
  //     // while (finalImageUrl === null) {
  //     setFinalImageUrl(imageList[0]);
  //     // console.log(finalImageUrl, "final image url");
  //     setWoahImage(imageList[0]);
  //     // }
  //   }
  // }, [imageList]);

  const [imageData, setImageData] = useState(null);
  const img = useRef(null);

  const createImage = async (imagePath) => {
    const resizedImage = await getResizedImage(imagePath);

    img.current = document.createElement("img");
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    img.current.crossOrigin = "Anonymous";
    img.current.src = resizedImage;

    await img.current.decode();

    canvas.width = img.current.width;
    canvas.height = img.current.height;

    context.fillStyle = context.createPattern(img.current, "no-repeat");
    //   context.fillRect(0, 0, imageWidth, imageHeight);
    context.fillRect(0, 0, img.current.width, img.current.height);

    setImageData(
      context.getImageData(0, 0, img.current.width, img.current.height).data
    );
  };
  useEffect(() => {
    if (woahImage !== null) {
      createImage(woahImage);
      // console.log(woahImage);
      // createImage("/images/testIcon.jpeg");
      // createImage(
      //   "https://i.scdn.co/image/ab67616d0000b2734b1d5e3fc565e1d221dd78da"
      // );
    }
  }, [woahImage]);
  //   const [audioTrigger, setAudioTrigger] = useState(false);
  //   const woahAudio2 = useStore2((state) => state.woahAudio2);
  //   useEffect(() => {
  //     if (woahAudio2 !== null) {
  //       setAudioTrigger(true);
  //     }
  //   }, [woahAudio2]);
  //   useEffect(() => {
  //     console.log(props.style.includes("PHANTOM" && "NOIR"));
  //   }, [props.style]);

  const { gl, scene, camera, size } = useThree();

  useEffect(() => {
    console.log(
      props.style.includes("PHANTOM") &&
        !props.style.includes("NOIR") &&
        !props.style.includes("RGB") &&
        !props.style.includes("PRISMA")
    );
  }, [props.style]);

  //   img.current.src = "/rufus.jpeg";
  if (imageData === null) return null;
  if (!img.current) return null;
  //   console.log(props.blur);

  return (
    <>
      {/* {props.style === "react" ? <EffectBlur /> : <Effect />} */}
      {/* <Effect effect={props.blur} /> */}

      {props.style &&
        !isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NOIR") &&
        !props.style.includes("RGB") &&
        !props.style.includes("NONE") &&
        !props.style.includes("PRISMA") && <Phantom />}
      {props.style &&
        !isMobile &&
        props.style.includes("NOIR") &&
        !props.style.includes("PHANTOM") &&
        !props.style.includes("RGB") &&
        !props.style.includes("NONE") &&
        !props.style.includes("PRISMA") && <Noir />}
      {props.style &&
        !isMobile &&
        props.style.includes("RGB") &&
        !props.style.includes("NOIR") &&
        !props.style.includes("NONE") &&
        !props.style.includes("PHANTOM") &&
        !props.style.includes("PRISMA") && <Rgb />}
      {props.style &&
        !isMobile &&
        props.style.includes("PRISMA") &&
        !props.style.includes("NONE") &&
        !props.style.includes("NOIR") &&
        !props.style.includes("RGB") &&
        !props.style.includes("PHANTOM") && <Prisma />}
      {props.style && !isMobile && props.style.includes("NONE") && <None />}
      {props.style &&
        !isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        props.style.includes("NOIR") &&
        !props.style.includes("RGB") &&
        !props.style.includes("PRISMA") && <PhantomNoir />}
      {props.style &&
        !isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        props.style.includes("NOIR") &&
        props.style.includes("RGB") &&
        !props.style.includes("PRISMA") && <PhantomNoirRGB />}
      {props.style &&
        !isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        props.style.includes("NOIR") &&
        props.style.includes("RGB") &&
        props.style.includes("PRISMA") && <PhantomNoirRgbPrisma />}
      {props.style &&
        !isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        !props.style.includes("NOIR") &&
        props.style.includes("RGB") &&
        !props.style.includes("PRISMA") && <PhantomRgb />}
      {props.style &&
        !isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        !props.style.includes("NOIR") &&
        !props.style.includes("RGB") &&
        !props.style.includes("NONE") &&
        props.style.includes("PRISMA") && <PhantomPrisma />}
      {props.style &&
        !isMobile &&
        !props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        props.style.includes("NOIR") &&
        props.style.includes("RGB") &&
        !props.style.includes("PRISMA") && <NoirRgb />}
      {props.style &&
        !isMobile &&
        !props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        props.style.includes("NOIR") &&
        !props.style.includes("RGB") &&
        props.style.includes("PRISMA") && <NoirPrisma />}
      {props.style &&
        !isMobile &&
        !props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        !props.style.includes("NOIR") &&
        props.style.includes("RGB") &&
        props.style.includes("PRISMA") && <RgbPrisma />}
      {props.style &&
        !isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        props.style.includes("NOIR") &&
        !props.style.includes("RGB") &&
        props.style.includes("PRISMA") && <PhantomNoirPrisma />}
      {props.style &&
        !isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        !props.style.includes("NOIR") &&
        props.style.includes("RGB") &&
        props.style.includes("PRISMA") && <PhantomRgbPrisma />}
      {props.style &&
        !isMobile &&
        !props.style.includes("PHANTOM") &&
        props.style.includes("NOIR") &&
        !props.style.includes("NONE") &&
        props.style.includes("RGB") &&
        props.style.includes("PRISMA") && <NoirRgbPrisma />}

      {props.style &&
        isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NOIR") &&
        !props.style.includes("RGB") &&
        !props.style.includes("NONE") &&
        !props.style.includes("PRISMA") && <PhantomMobile />}
      {props.style &&
        isMobile &&
        props.style.includes("NOIR") &&
        !props.style.includes("PHANTOM") &&
        !props.style.includes("RGB") &&
        !props.style.includes("NONE") &&
        !props.style.includes("PRISMA") && <NoirMobile />}
      {props.style &&
        isMobile &&
        props.style.includes("RGB") &&
        !props.style.includes("NOIR") &&
        !props.style.includes("NONE") &&
        !props.style.includes("PHANTOM") &&
        !props.style.includes("PRISMA") && <RgbMobile />}
      {props.style &&
        isMobile &&
        props.style.includes("PRISMA") &&
        !props.style.includes("NONE") &&
        !props.style.includes("NOIR") &&
        !props.style.includes("RGB") &&
        !props.style.includes("PHANTOM") && <PrismaMobile />}
      {props.style && isMobile && props.style.includes("NONE") && (
        <NoneMobile />
      )}
      {props.style &&
        isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        props.style.includes("NOIR") &&
        !props.style.includes("RGB") &&
        !props.style.includes("PRISMA") && <PhantomNoirMobile />}
      {props.style &&
        isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        props.style.includes("NOIR") &&
        props.style.includes("RGB") &&
        !props.style.includes("PRISMA") && <PhantomNoirRGBMobile />}
      {props.style &&
        isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        props.style.includes("NOIR") &&
        props.style.includes("RGB") &&
        props.style.includes("PRISMA") && <PhantomNoirRgbPrismaMobile />}
      {props.style &&
        isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        !props.style.includes("NOIR") &&
        props.style.includes("RGB") &&
        !props.style.includes("PRISMA") && <PhantomRgbMobile />}
      {props.style &&
        isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        !props.style.includes("NOIR") &&
        !props.style.includes("RGB") &&
        !props.style.includes("NONE") &&
        props.style.includes("PRISMA") && <PhantomPrismaMobile />}
      {props.style &&
        isMobile &&
        !props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        props.style.includes("NOIR") &&
        props.style.includes("RGB") &&
        !props.style.includes("PRISMA") && <NoirRgbMobile />}
      {props.style &&
        isMobile &&
        !props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        props.style.includes("NOIR") &&
        !props.style.includes("RGB") &&
        props.style.includes("PRISMA") && <NoirPrismaMobile />}
      {props.style &&
        isMobile &&
        !props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        !props.style.includes("NOIR") &&
        props.style.includes("RGB") &&
        props.style.includes("PRISMA") && <RgbPrismaMobile />}
      {props.style &&
        isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        props.style.includes("NOIR") &&
        !props.style.includes("RGB") &&
        props.style.includes("PRISMA") && <PhantomNoirPrismaMobile />}
      {props.style &&
        isMobile &&
        props.style.includes("PHANTOM") &&
        !props.style.includes("NONE") &&
        !props.style.includes("NOIR") &&
        props.style.includes("RGB") &&
        props.style.includes("PRISMA") && <PhantomRgbPrismaMobile />}
      {props.style &&
        isMobile &&
        !props.style.includes("PHANTOM") &&
        props.style.includes("NOIR") &&
        !props.style.includes("NONE") &&
        props.style.includes("RGB") &&
        props.style.includes("PRISMA") && <NoirRgbPrismaMobile />}

      <group ref={pointsRef}>
        <PointsWorld6
          palette={props.palette}
          //   woahAudioProp={woahAudio2}
          bgProp={props.bgProp}
          imageData={imageData}
          imageWidth={img.current.width}
          imageHeight={img.current.height}
        />
      </group>
    </>
  );
}

const Effect = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    // comp.addPass(new UnrealBloomPass(new THREE.Vector2(512, 512), 2, 1, 0));
    const finalComposer = new EffectComposer(gl);
    // const finalPass = new ShaderPass(
    //   new THREE.ShaderMaterial({
    //     uniforms: {
    //       baseTexture: { value: null },
    //       bloomTexture: { value: comp.renderTarget2.texture },
    //     },
    //     vertexShader:
    //       "varying vec2 vUv; void main() { vUv = uv; gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 ); }",
    //     fragmentShader:
    //       "uniform sampler2D baseTexture; uniform sampler2D bloomTexture; varying vec2 vUv; vec4 getTexture( sampler2D texelToLinearTexture ) { return mapTexelToLinear( texture2D( texelToLinearTexture , vUv ) ); } void main() { gl_FragColor = ( getTexture( baseTexture ) + vec4( 1.0 ) * getTexture( bloomTexture ) ); }",
    //   }),
    //   "baseTexture"
    // );
    // finalPass.needsSwap = true;
    // finalComposer.addPass(finalPass);
    // finalComposer.addPass();

    finalComposer.addPass(renderScene);
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);

    // const fxaa = new ShaderPass(FXAAShader);
    // fxaa.material.uniforms["resolution"].value.x = 1 / size.width;
    // fxaa.material.uniforms["resolution"].value.y = 1 / size.height;
    // finalComposer.addPass(new FilmPass(0.35, 0.025, 648, false));
    // finalComposer.addPass(new BokehPass(scene, camera, { focus: 1 }));

    // finalComposer.addPass(new AfterimagePass(0.98));
    // {
    //   props.blur && finalComposer.addPass(new AfterimagePass());
    // }
    // if (props.effect) {
    //   console.log("blur");
    // if (props.effect === false) {
    // finalComposer.addPass(new AfterimagePass());
    // }
    // }

    // finalComposer.addPass(
    //   new OutlinePass(new THREE.Vector2(512, 512), scene, camera)
    // );
    // finalComposer.addPass(
    //   new UnrealBloomPass(new THREE.Vector2(1024, 1024), 1, 1, 0)
    // );

    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    // https://github.com/mrdoob/three.js/blob/master/examples/webgl_postprocessing_unreal_bloom_selective.html
    // this seems kinda dirty, it mutates the scene and overwrites materials ...
    // scene.traverse(darkenNonBloomed);
    bloom.render();
    // scene.traverse(restoreMaterial);
    // then writes the normal scene on top
    final.render();
  }, 1);
  return null;
};
const None = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    // finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};
const Phantom = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    // finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    // const creativePass = new ShaderPass(CreativePass);
    // creativePass.uniforms["time"].value = 0.0;
    // finalComposer.addPass(creativePass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};
const PhantomNoir = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};
const PhantomNoirRGB = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};
const PhantomNoirRgbPrisma = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};
const Noir = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const Rgb = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    //   finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const Prisma = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    //   finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const PhantomRgb = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    //   finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const PhantomPrisma = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    //   finalComposer.addPass(dotScreen);

    //   const rgbShift = new ShaderPass(RGBShiftShader);
    //   rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const NoirRgb = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const NoirPrisma = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const RgbPrisma = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    //   finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const PhantomNoirPrisma = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const PhantomRgbPrisma = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    //   finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const NoirRgbPrisma = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    const customPass = new ShaderPass(CustomPass);
    finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const EffectMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    // comp.addPass(new UnrealBloomPass(new THREE.Vector2(512, 512), 2, 1, 0));
    const finalComposer = new EffectComposer(gl);
    // const finalPass = new ShaderPass(
    //   new THREE.ShaderMaterial({
    //     uniforms: {
    //       baseTexture: { value: null },
    //       bloomTexture: { value: comp.renderTarget2.texture },
    //     },
    //     vertexShader:
    //       "varying vec2 vUv; void main() { vUv = uv; gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 ); }",
    //     fragmentShader:
    //       "uniform sampler2D baseTexture; uniform sampler2D bloomTexture; varying vec2 vUv; vec4 getTexture( sampler2D texelToLinearTexture ) { return mapTexelToLinear( texture2D( texelToLinearTexture , vUv ) ); } void main() { gl_FragColor = ( getTexture( baseTexture ) + vec4( 1.0 ) * getTexture( bloomTexture ) ); }",
    //   }),
    //   "baseTexture"
    // );
    // finalPass.needsSwap = true;
    // finalComposer.addPass(finalPass);
    // finalComposer.addPass();

    finalComposer.addPass(renderScene);
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);

    // const fxaa = new ShaderPass(FXAAShader);
    // fxaa.material.uniforms["resolution"].value.x = 1 / size.width;
    // fxaa.material.uniforms["resolution"].value.y = 1 / size.height;
    // finalComposer.addPass(new FilmPass(0.35, 0.025, 648, false));
    // finalComposer.addPass(new BokehPass(scene, camera, { focus: 1 }));

    // finalComposer.addPass(new AfterimagePass(0.98));
    // {
    //   props.blur && finalComposer.addPass(new AfterimagePass());
    // }
    // if (props.effect) {
    //   console.log("blur");
    // if (props.effect === false) {
    // finalComposer.addPass(new AfterimagePass());
    // }
    // }

    // finalComposer.addPass(
    //   new OutlinePass(new THREE.Vector2(512, 512), scene, camera)
    // );
    // finalComposer.addPass(
    //   new UnrealBloomPass(new THREE.Vector2(1024, 1024), 1, 1, 0)
    // );

    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    // https://github.com/mrdoob/three.js/blob/master/examples/webgl_postprocessing_unreal_bloom_selective.html
    // this seems kinda dirty, it mutates the scene and overwrites materials ...
    // scene.traverse(darkenNonBloomed);
    bloom.render();
    // scene.traverse(restoreMaterial);
    // then writes the normal scene on top
    final.render();
  }, 1);
  return null;
};
const NoneMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    // finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};
const PhantomMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    // finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    // const creativePass = new ShaderPass(CreativePass);
    // creativePass.uniforms["time"].value = 0.0;
    // finalComposer.addPass(creativePass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};
const PhantomNoirMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};
const PhantomNoirRGBMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};
const PhantomNoirRgbPrismaMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};
const NoirMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const RgbMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    //   finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const PrismaMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    //   finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const PhantomRgbMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    //   finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const PhantomPrismaMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    //   finalComposer.addPass(dotScreen);

    //   const rgbShift = new ShaderPass(RGBShiftShader);
    //   rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const NoirRgbMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    // const kaleidoShader = new ShaderPass(KaleidoShader);
    // // OG
    // kaleidoShader.uniforms["sides"].value = 3;
    // kaleidoShader.uniforms["angle"].value = 0.5;

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const NoirPrismaMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const RgbPrismaMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    //   finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const PhantomNoirPrismaMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    // finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const PhantomRgbPrismaMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    //   finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};

const NoirRgbPrismaMobile = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);

    // finalComposer.addPass(new AfterimagePass());

    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);
    finalComposer.addPass(dotScreen);

    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;
    finalComposer.addPass(rgbShift);

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;
    // const customPass = new ShaderPass(CustomPass);
    // finalComposer.addPass(customPass);
    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};
