import React from "react";
import { useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Html } from "@react-three/drei";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./styles.module.css";
import { useParams } from "react-router-dom";
import { fb } from "./Firebase/Shared/Service";
import { useFullscreen } from "@mantine/hooks";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import { showNotification } from "@mantine/notifications";
import {
  Badge,
  Checkbox,
  Kbd,
  Text,
  Button,
  Modal,
  useMantineTheme,
  Group,
  Center,
  Drawer,
  Switch,
  Select,
  Menu,
  ScrollArea,
  Stack,
  Divider,
  List,
  FileButton,
} from "@mantine/core";
import LivePointsWorldContainer from "./LivePointsWorldContainer";
import { useNavigate } from "react-router-dom";
import { TbDropletOff } from "react-icons/tb";
const BREAKPOINT = "@media (maxWidth: 755px)";

export default function FinalCanvas(props) {
  const [opened2, setOpened2] = useState(true);

  const color = "#000000";
  //   const storeUser = useStore2((state) => state.storeUser);
  //   const storeTrack = useStore2((state) => state.storeTrack);
  const [style, setStyle] = useState();
  const [bg, setBg] = useState();
  const [palette, setPalette] = useState();
  const [userName, setUserName] = useState();
  const [trackName, setTrackName] = useState();
  const [artistName, setArtistName] = useState();
  const navigate = useNavigate();

  const { id } = useParams();

  const [checkSubscriptionProp, setcheckSubscriptionProp] = useState(true);
  const [proRole, setProRole] = useState(false);
  const [proPlusRole, setProPlusRole] = useState(false);

  async function getCustomClaimRole() {
    await fb.auth.currentUser.getIdToken(true);
    const decodedToken = await fb.auth.currentUser.getIdTokenResult();
    return decodedToken.claims.stripeRole;
  }

  useEffect(() => {
    getCustomClaimRole().then((res) => {
      // console.log(res);
      if (res === "premium") {
        setcheckSubscriptionProp(true);
        setProRole(true);
      }
      if (res === "premiumPlus") {
        setcheckSubscriptionProp(true);
        setProPlusRole(true);
      } else {
        setcheckSubscriptionProp(false);
      }
    });
  }, []);
  //   useEffect(() => {
  //     console.log(id);
  //   }, [id]);

  // useEffect(() => {
  //   console.log(style);
  // }, [style]);
  // const [amethystPro, setAmethystPro] = useState(true);
  useEffect(() => {
    // console.log(checkSubscriptionProp);
    if (checkSubscriptionProp === false && opened2 === false) {
      // setAmethystPro(true);
      showNotification({
        icon: <TbDropletOff size={18} />,
        style: { cursor: "pointer" },
        disallowClose: true,
        // autoClose: 5
        // icon: <IconCheck size={18} />,
        onClick: () =>
          // open new window tab to pricing page
          window.open("https://sounddrop.io/pricing", "_blank"),

        title: "Remove Watermark",
        message: "Click here to go PRO",
        // color: "teal",
        radius: "lg",
      });
    }
    // if (checkSubscriptionProp === true) {
    //   setAmethystPro(false);
    // }
  }, [checkSubscriptionProp, opened2]);

  useEffect(() => {
    // const idTrack = id;
    // console.log(idTrack);
    // const idTrack = id.split("-")[1];
    if (id !== undefined) {
      //   console.log(id);
      const idTrack = id.split("-").splice(1).join("-");
      const idTrack2 = idTrack.replace(/-/g, " ");
      const idArtist = id.split("-")[0];
      // console.log(idTrack2);
      // setTheArtist(idArtist);
      // setTheTrack(idTrack);
      // setTheArtist(fb.firestore.collection(idArtist).doc(theTrack).get());
      // setTheTrack(fb.firestore.collection(idArtist).doc(idTrack).get());
      // console.log(fb.firestore.collection(idArtist).doc(theTrack).get());
      fb.firestore
        .collection(idArtist)
        .doc(idTrack2)
        .get()
        .then((res) => {
          const data = res.data();
          //   console.log(data);
          // console.log(data.links[0]);
          if (data) {
            // console.log(data);
            // setTrackName(data.name);
            // setPageLinks(data.links);
            setArtistName(data.artist);
            setTrackName(data.name);
            setUserName(data.userName);
            setStyle(data.effects);
            setBg(data.background);
            setPalette(data.palette);
          } else {
            console.error(404);
          }
        });
    }
    // console.log(idTrack);
    // console.log(idArtist);
  }, [id]);
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const { toggle, fullscreen } = useFullscreen();
  const [paletteHider, setPaletteHider] = useState(false);

  const hundredColors = require("./Mantine/hundredColors.json");
  const tenColors = require("./Mantine/tenColors.json");
  const fiftyColors = require("./Mantine/fiftyColors.json");
  const [pa, setPa] = useState(tenColors);
  useEffect(() => {
    if (checkSubscriptionProp === false) {
      // setAmethystPro(true);
      // data2 = tenColors;
      setPa(tenColors);
    }
    if (proRole === true) {
      // setAmethystPro(false);
      // data2 = fiftyColors;
      setPa(fiftyColors);
    }
    if (proPlusRole === true) {
      // setAmethystPro(false);
      // data2 = hundredColors;
      setPa(hundredColors);
    }
  }, [checkSubscriptionProp, proRole, proPlusRole]);

  const [checkedArtist, setCheckedArtist] = useState(false);
  const [checkedTrack, setCheckedTrack] = useState(false);
  //   const [finalPalette, setFinalPalette] = useState();
  // const data2 = hundredColors;
  const data5 = pa.map((item, index) => (
    <Group spacing={0} position="center" m={25}>
      <Button
        color="gray"
        onClick={() => {
          setPalette(index);
          // console.log(index);
        }}
      >
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[0],
          }}
        ></div>
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[1],
          }}
        ></div>
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[2],
          }}
        ></div>
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[3],
          }}
        ></div>
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[4],
          }}
        ></div>
      </Button>
    </Group>
  ));
  // const [checked, setChecked] = useState(false);
  data5.shift();
  useEffect(() => {
    // console.log(checkedArtist, checkedTrack);
  }, [checkedArtist, checkedTrack]);

  const [file, setFile] = useState();
  const [newImage, setNewImage] = useState();

  useEffect(() => {
    let objectUrl;
    if (file) {
      // console.log(file)
      objectUrl = URL.createObjectURL(file);
      setNewImage(objectUrl);
    }
  }, [file]);

  return (
    <>
      <Modal
        // sx={{ background: "transparent" }}
        size={500}
        centered
        opened={opened2}
        onClose={() => setOpened2(false)}
      >
        <Center>
          <Stack>
            <h1
              style={{
                fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                fontSize: 52,
                fontWeight: 900,
                lineHeight: 1.1,
                margin: 0,
                padding: 0,
                textAlign: "center",

                color: theme.white,

                [BREAKPOINT]: {
                  fontSize: 42,
                  lineHeight: 1.2,
                },
              }}
            >
              Live Mode
            </h1>
            <List mt={40} spacing={"xl"}>
              <List.Item>
                Connect your microphone, DAW, DJ output, and more by adjusting
                your computer's audio input settings
              </List.Item>
              <List.Item>
                Pair with a projector while DJing or performing
              </List.Item>
              <List.Item>
                <Group spacing={6}>
                  Perfect for reactive visuals during
                  <span style={{ color: "#6440A6" }}>Twitch </span>or
                  <span style={{ color: "#357EFC" }}>Zoom </span>streams
                </Group>
              </List.Item>
              <List.Item>
                Change filters, backgrounds, and images on the fly
              </List.Item>
              <List.Item>Remove watermark by going pro</List.Item>
            </List>
            {/* <Text
              style={{
                textAlign: "center",
                marginTop: theme.spacing.xl,
                fontSize: 20,
                fontWeight: 500,

                [BREAKPOINT]: {
                  fontSize: 20,
                },
              }}
              color="dimmed"
            >
              Send us an email at{" "}
              <span
                onClick={() => {
                  window.open("mailto:contact@sounddrop.io");
                }}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#046bba",
                }}
              >
                contact@sounddrop.io
              </span>{" "}
              to change your URL
            </Text>
            <Text
              style={{
                textAlign: "center",
                marginTop: theme.spacing.xs,
                fontSize: 16,

                [BREAKPOINT]: {
                  fontSize: 16,
                },
              }}
              color="dimmed"
            >
              We will get back to you within 24-48 hours. Thank you!
            </Text> */}
            <Group
              style={{
                marginTop: theme.spacing.xl * 2,
                paddingBottom: theme.spacing.xl,

                [BREAKPOINT]: {
                  marginTop: theme.spacing.xl,
                },
              }}
              position="center"
            >
              <div className={styles.box3}>
                <Button
                  onClick={() => {
                    setOpened2(false);
                  }}
                  style={{
                    [BREAKPOINT]: {},
                  }}
                  size="lg"
                  color="gray"
                  variant="default"
                >
                  Make Some Noise
                </Button>
              </div>
            </Group>
          </Stack>
        </Center>
      </Modal>
      <div
        style={{
          position: "fixed",
          zIndex: "2",
          padding: "1rem",
          width: "210px",
          top: "0",
          right: "0",
        }}
      >
        <Group hidden={fullscreen} spacing={5}>
          <Kbd>Scroll</Kbd> + <Kbd>Click & Drag</Kbd>
        </Group>
      </div>
      {/* {checkSubscriptionProp ? (
        <></>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              height: "100%",
              bottom: "0",
              zIndex: 2,
              position: "absolute",
              opacity: "0.25",
            }}
          >
            <Center>
              <Image src={"/images/sounddropWordmarkBlack.png"} />
            </Center>
          </div>
        </>
      )} */}
      <div
        style={{
          position: "fixed",
          zIndex: "1",
          //   padding: "1rem",
          marginTop: "10px",
          paddingRight: "10px",
          //   bottom: "10px",
          width: "100%",
          // height: "65px",
        }}
      >
        <Stack
          position="center"
          spacing="xs"
          //   sx={{ padding: 15 }}
        >
          {trackName && artistName && (
            <>
              <Text
                align="center"
                size="lg"
                weight={500}
                mt="sm"
                hidden={!checkedTrack}
              >
                {trackName}
              </Text>
              <Text
                align="center"
                size="sm"
                color="dimmed"
                hidden={!checkedArtist}
              >
                {artistName}
              </Text>
            </>
          )}
        </Stack>
      </div>
      <Drawer
        overlayColor={
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2]
        }
        overlayOpacity={0.4}
        // overlayBlur={2.5}
        // style
        opened={opened}
        onClose={() => setOpened(false)}
        title="OPTIONS"
        padding="xl"
        size="lg"
      >
        <Stack mt={50}>
          <FileButton onChange={setFile} accept="image/*">
            {(props) => <Button {...props}>Change Image</Button>}
          </FileButton>
          {props?.checkSubscriptionProp === false && (
            <Button
              onClick={() => navigate("/pricing")}
              variant="subtle"
              radius="lg"
            >
              Remove Watermark
            </Button>
          )}

          <Divider label="Header" labelPosition="center" size="xs" />
          <Checkbox
            label="Artist Name"
            checked={checkedArtist}
            onChange={(event) => setCheckedArtist(!checkedArtist)}
          />
          <Checkbox
            label="Track Name"
            checked={checkedTrack}
            onChange={(event) => setCheckedTrack(!checkedTrack)}
          />

          <Divider label="Filters" labelPosition="center" size="xs" />
          {style && (
            <div
              style={{
                // position: positionDecider,
                zIndex: "1",

                // marginTop: effectsMarginDecider,
                // width: previewWidth,
                //   backgroundColor: "black",
                // padding: "20px",
                //   radius: "50px",
              }}
            >
              <Switch.Group
                orientation="vertical"
                // label="Filters"
                // spacing={spacingDecider}
                // offset={spacingDecider}
                // size={effectsSize}
                value={style}
                onChange={setStyle}
                spacing="xs"
              >
                <Switch
                  value="PHANTOM"
                  label="PHANTOM"
                  disabled={style.includes("NONE")}
                />
                <Switch
                  value="PRISMA"
                  label="PRISMA"
                  disabled={style.includes("NONE")}
                />

                <Switch
                  value="NOIR"
                  label="NOIR"
                  onLabel={"PRO"}
                  offLabel={"PRO"}
                  disabled={checkSubscriptionProp === false}
                />
                <Switch
                  value="RGB"
                  label="RGB"
                  onLabel={"PRO"}
                  offLabel={"PRO"}
                  disabled={checkSubscriptionProp === false}
                />
                <Switch value="NONE" label="NONE" />
              </Switch.Group>
            </div>
          )}
          <Divider
            label="Background"
            labelPosition="center"
            size="xs"
            mb={20}
            mt={20}
          />
          <div
            style={{
              // position: bgPositionDecider,
              // zIndex: "1",
              // marginTop: bgMarginTopDecider,
              // marginLeft: bgMarginLeftDecider,
              // width: previewWidth,
              // maxWidth: "200px",
              // position: "absolute",
              // zIndex: "1",
              // marginTop: "16px",
              // marginLeft: "650px",
              width: "100%",
              maxWidth: "200px",
            }}
          >
            <Select
              //   label="Background"
              placeholder="DEFAULT"
              // size={backgroundSize}
              value={bg}
              onChange={setBg}
              data={[
                { value: "default", label: "DEFAULT" },
                { value: "dream", label: "DREAM" },
                { value: "astra", label: "ASTRA" },
                {
                  value: "amethyst",
                  label: "AMETHYST (PRO)",
                  disabled: !checkSubscriptionProp,
                },
              ]}
            />

            {bg && bg === "dream" && checkSubscriptionProp === false && (
              <>
                <div style={{ cursor: "pointer", marginTop: 10 }}>
                  <Badge
                    onClick={() =>
                      window.open("https://www.sounddrop.io/pricing")
                    }
                    mt={10}
                    color="gray"
                    variant="outline"
                  >
                    Go PRO for more palettes
                  </Badge>
                </div>
                <Menu mt={15} mb={15}>
                  <Menu.Target>
                    <Button
                      // size={backgroundSize}
                      size="md"
                      rightIcon={
                        paletteHider ? (
                          <RiArrowDropDownLine size={20} />
                        ) : (
                          <RiArrowDropUpLine size={20} />
                        )
                      }
                      color="gray"
                      onClick={() => {
                        setPaletteHider(!paletteHider);
                      }}
                    >
                      Select Palette
                    </Button>
                  </Menu.Target>
                  <ScrollArea
                    hidden={paletteHider}
                    // mt={20}
                    style={{ height: 290, width: 200 }}
                    // style={{ height: 50, width: 200 }}
                    type="always"
                  >
                    {data5}
                    {/* <div style={{ width: 5000 }}>
                      <Group> {data5}</Group>
                    </div> */}
                  </ScrollArea>
                </Menu>
              </>
            )}
            {bg && bg === "dream" && checkSubscriptionProp === true && (
              <>
                <Menu mt={15} mb={15}>
                  <Menu.Target>
                    <Button
                      // size={backgroundSize}
                      size="md"
                      rightIcon={
                        paletteHider ? (
                          <RiArrowDropDownLine size={20} />
                        ) : (
                          <RiArrowDropUpLine size={20} />
                        )
                      }
                      color="gray"
                      onClick={() => {
                        setPaletteHider(!paletteHider);
                      }}
                    >
                      Select Palette
                    </Button>
                  </Menu.Target>
                  <ScrollArea
                    hidden={paletteHider}
                    // mt={20}
                    style={{ height: 290, width: 200 }}
                    // style={{ height: 50, width: 200 }}
                    type="always"
                  >
                    {data5}
                    {/* <div style={{ width: 5000 }}>
                      <Group> {data5}</Group>
                    </div> */}
                  </ScrollArea>
                </Menu>
              </>
            )}
          </div>
        </Stack>
      </Drawer>
      <Group
        style={{ position: "absolute", bottom: 0, left: 0, zIndex: 1000 }}
        position="center"
        hidden={opened}
      >
        <Button
          hidden={fullscreen}
          radius="xs"
          variant="light"
          color="gray"
          onClick={() => setOpened(true)}
        >
          OPTIONS
        </Button>
      </Group>
      <Group
        style={{ position: "absolute", bottom: 0, right: 0, zIndex: 1000 }}
        position="center"
        // hidden={opened}
      >
        <Button
          radius="xs"
          variant="light"
          color="gray"
          onClick={toggle}
          hidden={fullscreen}
        >
          FULLSCREEN
        </Button>
      </Group>
      {/* {styles && ( */}
      <Canvas
        className={styles.grabbable}
        style={{
          //   outline: "none !important",
          width: "100vw",
          height: "100vh",
          margin: "0",
          padding: "0",
          //   position: "fixed",
          //   zIndex: "99999999",
          /* width: 100%;
  height: 100%; */
          /* z-index: 0; */
          /* position: absolute; */
          // zIndex:0,
          // cursor: "grab",
          // cursor: mouseDown ? "grabbing" : "grab",
          // cursor: cursorState,
        }}
        shadows
        dpr={[1, 2]}
        camera={{ position: [0, 0, 10], near: 0.001, far: 2000 }}
        // gl={{
        //   // preserveDrawingBuffer: true,
        //   toneMapping: NoToneMapping,
        // }}
        // onCreated={bind}
        linear
        flat
      >
        {/* <color attach="background" args={[color]} />    */}
        <color attach="background" args={[color]} />
        <TheMesh checkSubscriptionProp={checkSubscriptionProp} />

        {/* {location.pathname.includes("page") && */}
        {/* pageTrigger === true && isAuthed && ( */}
        {/* {theUser && theTrack && ( */}
        <>
          {/* <TreeWorldM /> */}
          {/* <TreeWorld /> */}

          {/* {storeUser && storeTrack && ( */}
          {userName && trackName && (
            <LivePointsWorldContainer
              // imageSource={theImage}
              // pageNameStore={pageNameStore}
              // authUserProp={authUser.uid}
              newImage={newImage}
              theUser={userName}
              theTrack={trackName}
              style={style}
              bg={bg}
              palette={palette}
            />
          )}

          {/* )} */}

          <ambientLight intensity={2} />
          {style && style.includes("PRISMA") ? (
            <OrbitControls
              enablePan={false}
              // enableDamping={true}
              // dampingFactor={-0.01}
              minDistance={120}
              maxDistance={1000}
              rotateSpeed={0.4}
              zoomSpeed={0.4}
              autoRotate
              autoRotateSpeed={2}
            />
          ) : (
            <OrbitControls
              enablePan={false}
              // enableDamping={true}
              // dampingFactor={-0.01}
              minDistance={120}
              maxDistance={1000}
              rotateSpeed={0.4}
              zoomSpeed={0.4}
              // autoRotate
              // autoRotateSpeed={1}
            />
          )}
        </>
      </Canvas>
      {/* )} */}
    </>
  );
}

const TheMesh = (props) => {
  // const textureYo = useTexture("/images/sounddropWordmarkBlack.png");
  console.log(props);
  return (
    <Html
      // scale={405}
      as="div" // Wrapping element (default: 'div')
      wrapperClass // The className of the wrapping element (default: undefined)
      prepend // Project content behind the canvas (default: false)
      // center // Adds a -50%/-50% css transform (default: false) [ignored in transform mode]
      fullscreen // Aligns to the upper-left corner, fills the screen (default:false) [ignored in transform mode]
      distanceFactor={10} // If set (default: undefined), children will be scaled by this factor, and also by distance to a PerspectiveCamera / zoom by a OrthographicCamera.
      zIndexRange={[100, 0]} // Z-order range (default=[16777271, 0])
      // portal={domnodeRef} // Reference to target container (default=undefined)
      // transform // If true, applies matrix3d transformations (default=false)
      // sprite // Renders as sprite, but only in transform mode (default=false)
      // calculatePosition={(el: Object3D, camera: Camera, size: { width: number; height: number }) => number[]} // Override default positioning function. (default=undefined) [ignored in transform mode]
      // occlude={[ref]} // Can be true or a Ref<Object3D>[], true occludes the entire scene (default: undefined)
      // onOcclude={(visible) => null} // Callback when the visibility changes (default: undefined)
      // {...groupProps} // All THREE.Group props are valid
      // {...divProps} // All HTMLDivElement props are valid
    >
      {props.checkSubscriptionProp && props.checkSubscriptionProp === true ? (
        <></>
      ) : (
        <>
          {/* <div
            style={{
              width: "100%",
              height: "100%",
              bottom: "0",
              zIndex: 2,
              position: "absolute",
              opacity: "0.25",
            }}
          > */}
          {/* <Center> */}
          {/* <Image src={"/images/sounddropWordmarkBlack.png"} /> */}
          {/* <Center> */}
          <div
            style={{
              opacity: 0.45,
              fontSize: 2500,
              // marginBottom: "50px",
              // marginLeft: "20px",
            }}
            className={styles.gradientText10}
          >
            <Center>SoundDrop</Center>

            {/* SoundDrop */}
          </div>
          {/* </Center> */}
          {/* </Center> */}
          {/* </div> */}
        </>
      )}
      {/* <Image /> */}
    </Html>
    // <mesh position={[50, 0, 0]}>
    //   <planeBufferGeometry attach="geometry" args={[100, 100]} />
    //   <meshBasicMaterial
    //     side={THREE.DoubleSide}
    //     color="red"
    //     // map={textureYo}
    //     // material-map={textureYo}
    //     attach="material"
    //     // color="black"
    //   />
    // </mesh>
  );
};
