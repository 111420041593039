import React from "react";
import * as THREE from "three";
import { useRef, useState, useEffect, forwardRef, useMemo } from "react";
import {
  Canvas,
  extend,
  useFrame,
  useThree,
  useLoader,
} from "@react-three/fiber";
import {
  Stars,
  useTexture,
  Html,
  useGLTF,
  shaderMaterial,
} from "@react-three/drei";
import { suspend } from "suspend-react";
import useStore2 from "../store2";
import { KaleidoShader } from "three/examples/jsm/shaders/KaleidoShader.js";
import { DotScreenShader } from "three/examples/jsm/shaders/DotScreenShader";
import { RGBShiftShader } from "three/examples/jsm/shaders/RGBShiftShader";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LandingContainer from "../LandingContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import useRefs from "react-use-refs";
import "./stylesLanding.css";
import { CustomPass } from "../Scenes/CustomPass";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import Lottie from "react-lottie";
import * as animationData from "./lottie/scroll.json";
import StageNEW2 from "../Models/StageNEW2";

import { IcosahedronBufferGeometry } from "three";

import { ParametricGeometry } from "three/examples/jsm/geometries/ParametricGeometry";

extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  AfterimagePass,
});

const rsqw = (t, delta = 0.1, a = 1, f = 1 / (2 * Math.PI)) =>
  (a / Math.atan(1 / delta)) * Math.atan(Math.sin(2 * Math.PI * t * f) / delta);
export default function LandingPage2(props) {
  const color = "#000000";
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.config({ ignoreMobileResize: true });
  ScrollTrigger.normalizeScroll(true);

  let animable = {
    r1: 0,
    r2: 0,
    r3: 0,
    r4: 0,
    r5: 0,
    r6: 0,
    r7: 1,
    camera: 0,
    camera2: 0,
    stars: 20,
    starsOut: 1,
    scale: 0,
    speed: 0.3,
    zAmount: 0.5,
    stage: 0,
    camera10: 0,
  };
  let animableSphere = {
    y: 0,
  };

  useEffect(() => {
    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-one",
        start: "top top",
        endTrigger: ".section-two",
        end: "section-two",
        scrub: 1,

        snap: {
          snapTo: "labels", // snap to the closest label in the timeline
          duration: { min: 3, max: 4 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
          delay: 0.4, // wait 0.2 seconds from the last scroll event before doing the snapping
          ease: "power2.inOut", // the ease of the snap animation ("power3" by default)
        },
      },
    });
    tl1.to(animable, {
      r1: 1,
    });
    const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-two",
        start: ".section-two",
        endTrigger: ".section-three",
        end: ".section-three",
        markers: false,
        scrub: 1,
      },
    });
    tl3.to(animable, {});
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-four",
        start: ".section-four",
        endTrigger: ".section-five",
        end: ".section-five",
        markers: false,
        scrub: 1,
        markers: false,
      },
    });
    tl2.to(animable, {
      //   r4: 1,
      speed: 1,
      zAmount: 1,
      r3: 1,
      r2: 1,
    });
    const tl4 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-one",
        start: ".section-one",
        endTrigger: ".section-three",
        end: ".section-three",
        markers: false,
        scrub: 1,
        markers: false,
      },
    });
    tl4.to(animable, {
      scale: 1,
    });
    const tl5 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-six",
        start: ".section-six",
        endTrigger: ".section-seven",
        end: ".section-seven",
        markers: false,

        scrub: 1,
        markers: false,
      },
    });
    tl5.to(animable, {
      stars: 1,

      r5: 1,
      r6: 1,
    });
    const tl6 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-three",
        start: ".section-three",
        endTrigger: ".section-four",
        end: ".section-four",

        scrub: 1,
        markers: false,
      },
    });
    tl6.to(animable, {
      r4: 1,
    });
    const tl7 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-three",
        start: "top top",
        endTrigger: ".section-six",
        end: "bottom bottom",
        markers: false,

        scrub: 1,
        markers: false,
      },
    });
    tl7.to(animable, {});
    const tl8 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-six",
        start: "section-six",
        endTrigger: ".section-seven",
        end: "bottom bottom",
        markers: false,

        scrub: 1,
        markers: false,
      },
    });
    tl8.to(animable, {});
    const tl9 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-seven",
        start: "top top",
        endTrigger: ".section-eight",
        end: "bottom bottom",
        markers: false,

        scrub: 1,
        markers: false,
      },
    });
    tl9.to(animable, {
      stage: 1,
    });
    const tl10 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-nine",
        start: "top top",
        endTrigger: ".section-ten",
        end: "bottom bottom",
        markers: false,

        scrub: 1,
        markers: false,
      },
    });
    tl10.to(animable, {
      camera10: 1,
      r7: 0,
    });
    const tl11 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-one",
        start: "top top",
        endTrigger: ".section-three",
        end: "bottom bottom",
        markers: false,

        scrub: 1,
        snap: 1,
        markers: false,
      },
    });
    const tl12 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-three",
        start: "top top",
        endTrigger: ".section-five",
        end: "bottom bottom",
        markers: false,

        scrub: 1,
        snap: 1,
        markers: false,
      },
    });
    const tl13 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-five",
        start: "top top",
        endTrigger: ".section-seven",
        end: "bottom bottom",
        markers: false,

        scrub: 1,
        snap: 1,
        markers: false,
      },
    });
    const tl14 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-seven",
        start: "top top",
        endTrigger: ".section-eight",
        end: "bottom bottom",
        markers: false,

        scrub: 1,
        snap: 1,
        markers: false,
      },
    });
    const tl15 = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-eight",
        start: "top top",
        endTrigger: ".section-ten",
        end: "bottom bottom",
        markers: false,

        scrub: 1,
        snap: 1,
        markers: false,
      },
    });
  }, []);

  return (
    <>
      <LottieYo animable={animable} />
      <Canvas
        linear
        flat
        shadows
        dpr={[1, 2]}
        camera={{ position: [0, -3.2, 40], fov: 12 }}
      >
        <color attach="background" args={[color]} />
        <ambientLight intensity={0.75} />

        <Contentt audioInput={props.audioInput} animable={animable} />

        <Phantom />
      </Canvas>
    </>
  );
}

const Contentt = (props) => {
  const hundredColors = require("./hundredColors.json");
  const [firstPalette, setFirstPalette] = useState(1);
  const landingBackground = useStore2((state) => state.landingBackground);
  useEffect(() => {
    let palette = hundredColors[firstPalette];
    palette = palette.map((color) => new THREE.Color(color));
    gradientRef.current.material.uniforms.uColor.value = palette;
  }, [firstPalette, landingBackground]);

  var x = 0;
  var sin = 0;
  var cos = 0;
  const { gainNode, context, update, data, source, avg } = suspend(
    () => createAudio(),
    []
  );

  let y = 1;
  const enabledAudio = useStore2((state) => state.enabledAudio);

  let kick = 1;
  let bass = 1;
  let synth1 = 1;
  let synth2 = 1;
  let otherMusic = 1;
  let vocal = 1;

  const [click, setClick] = useState(1);

  var sinFunc = 1;
  const refYo = useRef();
  const ballRef = useRef();
  const ballRef2 = useRef();

  function Teardrop(u, v, target) {
    let alpha = Math.PI * 2 * (u - 0.5);
    let theta = Math.PI * 2 * (v - 0.5);
    let t = 1 * click;

    let x = 0.5 * (1 - Math.cos(alpha)) * Math.sin(alpha) * Math.cos(theta) * 1;
    let z = Math.cos(alpha);
    let y = 0.5 * (1 - Math.cos(alpha)) * Math.sin(alpha) * Math.sin(theta) * 1;

    target.set(x, y, z);
  }

  function getMaterial() {
    let material = new THREE.MeshPhysicalMaterial({
      color: 0xffffff,
      roughness: 0,
      metalness: 0.5,
      clearcoat: 1,
      clearcoatRoughness: 0.4,
      transparent: true,
    });

    material.onBeforeCompile = function (shader) {
      shader.uniforms.playhead = { value: 0 };

      shader.fragmentShader =
        `uniform float playhead;\n` + shader.fragmentShader;

      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <logdepthbuf_fragment>",
        `
          float diff = dot(vec3(1.),vNormal);
  
          // First
          vec3 a = vec3(0.5,0.5 ,0.5 );
          vec3 b = vec3(0.5,0.5,0.5);
          vec3 c = vec3(1.0,1.0,1.0 );
          vec3 d = vec3(0.00,0.10,0.20);
  
  
          // Second
          // vec3 a = vec3(0.5,0.5,0.5);
          // vec3 b = vec3(0.5,0.5,0.5);
          // vec3 c = vec3(2.0,1.0,0.0);
          // vec3 d = vec3(0.5,0.2,0.25);
  
          vec3 cc= a + b * cos(2.*3.141592*(c*diff+d + playhead ));
  
  
          // diffuseColor.rgb = vec3(diff,0.,0.);
          diffuseColor.rgb = cc ;
  
          ` + "#include <logdepthbuf_fragment>"
      );

      material.userData.shader = shader;
    };
    return material;
  }
  let materialYo = getMaterial();
  let theta3 = 0;
  let theta4 = 0 + Math.PI;

  useFrame((state, delta, clock, playhead) => {
    refYo.current.rotation.z += delta / 2.5;

    if (materialYo.userData.shader) {
      materialYo.userData.shader.uniforms.playhead.value += delta / 2.5;
    }

    theta3 += delta / 2.5;
    theta4 += delta / 2.5;
  });

  const geometryYo = new ParametricGeometry(Teardrop, 300, 300);

  useFrame((state, delta) => {
    let avgPlease = update();
    if (enabledAudio === true) {
      y = avgPlease / 30000;
    }
    if (enabledAudio === false) {
      y = 0;
    }

    mbp16.current.rotation.x =
      Math.PI - (Math.PI / 2) * props.animable.r2 + props.animable.r2 * 0.33;
    mbp14.current.rotation.x =
      Math.PI -
      (Math.PI / 2) * rsqw(props.animable.r3) -
      props.animable.r3 * 0.39;

    logoRef.current.position.y = THREE.MathUtils.damp(
      logoRef.current.position.y,
      rsqw(props.animable.r3) * 1.9 + rsqw(props.animable.camera10) * -1.9,
      4,
      delta
    );
    logoRef.current.position.x = THREE.MathUtils.damp(
      logoRef.current.position.x,
      rsqw(props.animable.r3) * -5 + rsqw(props.animable.camera10) * 1.9,
      4,
      delta
    );
    refYo.current.material.opacity = 1 - props.animable.r1 + props.animable.r6;

    wordMarkMaterial.current.opacity = THREE.MathUtils.damp(
      wordMarkMaterial.current.opacity,
      1 - rsqw(props.animable.r1) * 1.2 + rsqw(props.animable.camera10) * 1.2,
      2,
      delta
    );
    wordMark.current.position.x = props.animable.r5 * -14.5;

    logoRef.current.scale.x =
      logoRef.current.scale.y =
      logoRef.current.scale.z =
      logoRef.current.rotation.y =
        THREE.MathUtils.damp(
          logoRef.current.scale.z,
          1 -
            rsqw(props.animable.r3) / 1.15 +
            rsqw(props.animable.camera10) / 1.15,
          2,
          delta
        );

    group.current.position.y = THREE.MathUtils.damp(
      group.current.position.y,
      props.animable.r4 * 1.1 - 4.5 + props.animable.r6 * -2,
      20,
      delta
    );

    group.current.scale.x =
      group.current.scale.y =
      group.current.scale.z =
        THREE.MathUtils.damp(
          group.current.scale.z,
          1.5 + 0.24 * (1 - rsqw(props.animable.r1)),
          4,
          delta
        );
    keyLight.current.position.set(
      0.25 + -15 * (1 - props.animable.r1),
      4 + 11 * (1 - props.animable.r1),
      3 + 2 * (1 - props.animable.r1)
    );

    pointsWorld.current.scale.x =
      pointsWorld.current.scale.y =
      pointsWorld.current.scale.z =
        1 -
        rsqw(props.animable.scale / 0.97) +
        rsqw(props.animable.camera10 / 0.97);
    pointsWorld.current.position.y = props.animable.camera * -10;
    pointsWorld.current.rotation.z =
      1 - props.animable.scale + props.animable.camera10 * 2;
    if (landingBackground === "DREAM") {
      gradientRef.current.material.uniforms.opacity.value =
        props.animable.r5 * props.animable.r7;
    }
    if (landingBackground !== "DREAM") {
      gradientRef.current.material.uniforms.opacity.value = 0;
    }
    if (landingBackground === "ASTRA") {
      starRef.current.rotation.y += -delta / 50 - y;
      starRef.current.rotation.x += -delta / 150 - y;
      starRef.current.scale.x =
        starRef.current.scale.y =
        starRef.current.scale.z =
          1 * props.animable.stars * props.animable.starsOut;
    }
    if (landingBackground !== "ASTRA") {
      starRef.current.scale.x =
        starRef.current.scale.y =
        starRef.current.scale.z =
          1000 * props.animable.stars;
    }

    gradientRef.current.material.uniforms.time.value += y / 2 + delta / 90;

    gradientRef.current.rotation.y = Math.PI * props.animable.r5;

    group.current.position.z = props.animable.r6 * 25;

    iphoneRef.current.rotation.y =
      props.animable.r2 * Math.PI * 3.5 + props.animable.r6 * -Math.PI * 1;
    iphoneRef.current.position.x =
      -10 + props.animable.r2 * 5 - props.animable.r6 * 6;
    pointsWorld.current.rotation.y = props.animable.r6 * Math.PI * 2;

    codropsRef.current.rotation.y += delta / 50;
    codropsRef.current.rotation.x += delta / 60;
    codropsRef.current.rotation.z += delta / 70;
    x += delta / 3;
    sin = Math.sin(x);
    cos = Math.cos(x);

    theRef.current.uFrequency = avgPlease / 40;
    theRef.current.uAmplitude = avgPlease / 40;
    theRef.current.uDensity = 0.8 + avgPlease / 40;
    theRef.current.uStrength = avgPlease / 40;
    theRef.current.uDeepPurple = (avgPlease / 16) * sin * 5 - 8;

    stageRef.current.position.y =
      props.animable.stage * 16 - 20 + props.animable.camera10 * -1;
    stageRef.current.rotation.x = (props.animable.camera10 * Math.PI) / 8;
    stageRef.current.position.z = props.animable.camera10 * 20 + 10;
  });
  const colorMap = useLoader(
    TextureLoader,
    "/images/sounddropWordmarkBlack.png"
    // '/images/'
  );
  const { width, height } = useThree((state) => state.viewport);
  const [
    group,
    mbp16,
    mbp14,
    keyLight,
    stripLight,
    fillLight,
    left,
    right,
    logoRef,
    wordMark,
    wordMarkMaterial,
    pointsWorld,
    gradientRef,
    iphoneRef,
    starRef,
    stageRef,
    clumpGroup,
    codropsRef,
    theRef,
  ] = useRefs();
  const [textureRed, textureBlue] = useTexture([
    // "/test2.png",
    // "/Chroma Blue.jpg",
    "/images/iphoneLogo.jpeg",
    "/images/macLogo3.png",
  ]);

  const [theImage, setTheImage] = useState("/images/sounddropLogo.png");

  return (
    <>
      <group
        ref={codropsRef}
        visible={false}
        scale={0.25}
        position={[0, 0, -10]}
      >
        <mesh position={[10, 50, -20]}>
          <icosahedronBufferGeometry args={[150, 64]} />
          <waveShaderMaterial
            side={THREE.FrontSide}
            wireframe={true}
            transparent={true}
            blending={THREE.AdditiveBlending}
            ref={theRef}
            uFrequency={1}
            uAmplitude={1}
            uDensity={1}
            uStrength={1}
            uDeepPurple={1}
            uOpacity={0.2}
          />
        </mesh>
      </group>

      <group visible={true} ref={stageRef} position={[-0.1, -4, 10]}>
        <StageNEW2 />
      </group>

      <group ref={starRef}>
        <Stars
          radius={20}
          depth={20}
          count={6000}
          factor={4}
          saturation={100}
          fade
          speed={2}
        />
      </group>
      <group
        scale={0.9}
        position={[-4.25, -1.25, 0]}
        rotation-y={-Math.PI / 2}
        ref={iphoneRef}
      >
        <Iphone texture={textureRed} />
      </group>

      <mesh ref={gradientRef}>
        <sphereBufferGeometry args={[10, 128, 128]} />
        <gradientMaterial
          side={THREE.BackSide}
          transparent
          extensions={{
            derivatives: "#extension GL_OES_standard_derivatives : enable",
          }}
        />
      </mesh>
      <group
        ref={pointsWorld}
        rotation-x={0.05}
        position-z={-3}
        scale={0.4}
        rotation-z={Math.PI / 2}
      >
        <LandingContainer
          audioInput={props.audioInput}
          palette={1}
          bgProp={"DREAM"}
          useThisImage={theImage}
          style={["PHANTOM"]}
          zAmount={props.animable.zAmount}
          speed={props.animable.speed}
        />
      </group>
      <spotLight position={[0, -width * 0.7, 0]} intensity={0.5} />
      <directionalLight ref={keyLight} castShadow intensity={1.5}>
        <orthographicCamera
          attachObject={["shadow", "camera"]}
          args={[-10, 10, 10, -10, 0.5, 30]}
        />
      </directionalLight>
      <group ref={group} position={[0, -12, 0]} {...props}>
        <spotLight
          ref={stripLight}
          position={[width * 2.5, 0, width]}
          angle={0.19}
          penumbra={5}
          intensity={0.25}
        />
        <spotLight
          ref={fillLight}
          position={[0, -width / 2.4, -width * 2.2]}
          angle={0.2}
          penumbra={1}
          intensity={2}
          distance={width * 3}
        />
        <M1 ref={mbp16} texture={textureBlue} scale={width / 67}>
          <Tag
            ref={left}
            position={[16, 5, 0]}
            head="up to"
            stat="13x"
            expl={`faster\ngraphics\nperformance²`}
          />
        </M1>
        <M1
          ref={mbp14}
          visible={false}
          texture={textureRed}
          scale={width / 77}
          rotation={[0, Math.PI, 0]}
          position={[0, 0, -width / 2.625]}
        >
          <Tag
            ref={right}
            position={[10, 14, 0]}
            head="up to"
            stat="3.7x"
            expl={`faster CPU\nperformance¹`}
          />
        </M1>
      </group>
      <group scale={2} position={[3, 0, 0]}>
        <group ref={logoRef}>
          <mesh
            ref={refYo}
            geometry={geometryYo}
            material={materialYo}
            rotation-x={-Math.PI / 2}
            castShadow
            scale={[1, 1, 1]}
          ></mesh>
        </group>
        <group ref={wordMark}>
          <mesh position={[5.5, 0, -75]}>
            <meshBasicMaterial
              ref={wordMarkMaterial}
              map={colorMap}
              alphaMap={colorMap}
              transparent
            />
            <planeBufferGeometry attach="geometry" args={[6.5, 1.3]} />
          </mesh>
        </group>
      </group>
    </>
  );
};

const M1 = forwardRef(({ texture, children, ...props }, ref) => {
  const { nodes, materials } = useGLTF("/mbp-v1-pipe.glb");
  return (
    <group {...props} dispose={null}>
      <group
        ref={ref}
        position={[0, -0.43, -11.35]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <mesh
          geometry={nodes.back_1.geometry}
          material={materials.blackmatte}
        />
        <mesh
          receiveShadow
          castShadow
          geometry={nodes.back_2.geometry}
          material={materials.aluminium}
        />
        <mesh geometry={nodes.matte.geometry}>
          <meshLambertMaterial
            map={texture}
            toneMapped={false}
            transparent
            alphaMap={texture}
          />
        </mesh>
      </group>
      {children}
      <mesh
        geometry={nodes.body_1.geometry}
        material={materials.aluminium}
        material-color="#aaaaaf"
        material-envMapIntensity={0.2}
      />
      <mesh geometry={nodes.body_2.geometry} material={materials.blackmatte} />
    </group>
  );
});

const Tag = forwardRef(({ head, stat, expl, ...props }, ref) => {
  return (
    <Html ref={ref} className="data" center {...props}>
      <div>{head}</div>
      <h1>{stat}</h1>
      <h2>{expl}</h2>
    </Html>
  );
});

const Phantom = (props) => {
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);
    finalComposer.addPass(renderScene);

    finalComposer.addPass(new AfterimagePass(0.75));
    const dotScreen = new ShaderPass(DotScreenShader);
    dotScreen.uniforms["scale"].value = 0.5;
    dotScreen.uniforms["angle"].value = 2;
    dotScreen.uniforms["tSize"].value = new THREE.Vector2(1024, 1024);

    const customPass = new ShaderPass(CustomPass);
    const rgbShift = new ShaderPass(RGBShiftShader);
    rgbShift.uniforms["amount"].value = 0.32;

    const kaleidoShader = new ShaderPass(KaleidoShader);
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;

    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};
const hundredColors = require("./hundredColors.json");

let firstPalette = hundredColors[15];

firstPalette = firstPalette.map((color) => new THREE.Color(color));

const GradientMaterial = shaderMaterial(
  {
    time: 0,
    uColor: firstPalette,
    resolution: new THREE.Vector4(),
    opacity: 1.0,
    // u: 1,
    // amplitude: 0.5,
  },
  /* glsl */ `
  uniform float time;
  // varying vec2 vUv;
  varying vec3 vNormal;
  varying vec3 vPosition;
  uniform vec3 uColor[5];
  varying vec3 vColor;
  uniform vec2 pixels;
  float PI = 3.141592653589793238;
  //	Simplex 3D Noise 
  //	by Ian McEwan, Ashima Arts
  //
  vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
  vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
  
  float snoise(vec3 v){ 
    const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
    const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);
  
  // First corner
    vec3 i  = floor(v + dot(v, C.yyy) );
    vec3 x0 =   v - i + dot(i, C.xxx) ;
  
  // Other corners
    vec3 g = step(x0.yzx, x0.xyz);
    vec3 l = 1.0 - g;
    vec3 i1 = min( g.xyz, l.zxy );
    vec3 i2 = max( g.xyz, l.zxy );
  
    //  x0 = x0 - 0. + 0.0 * C 
    vec3 x1 = x0 - i1 + 1.0 * C.xxx;
    vec3 x2 = x0 - i2 + 2.0 * C.xxx;
    vec3 x3 = x0 - 1. + 3.0 * C.xxx;
  
  // Permutations
    i = mod(i, 289.0 ); 
    vec4 p = permute( permute( permute( 
               i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
             + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
             + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));
  
  // Gradients
  // ( N*N points uniformly over a square, mapped onto an octahedron.)
    float n_ = 1.0/7.0; // N=7
    vec3  ns = n_ * D.wyz - D.xzx;
  
    vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)
  
    vec4 x_ = floor(j * ns.z);
    vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)
  
    vec4 x = x_ *ns.x + ns.yyyy;
    vec4 y = y_ *ns.x + ns.yyyy;
    vec4 h = 1.0 - abs(x) - abs(y);
  
    vec4 b0 = vec4( x.xy, y.xy );
    vec4 b1 = vec4( x.zw, y.zw );
  
    vec4 s0 = floor(b0)*2.0 + 1.0;
    vec4 s1 = floor(b1)*2.0 + 1.0;
    vec4 sh = -step(h, vec4(0.0));
  
    vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
    vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;
  
    vec3 p0 = vec3(a0.xy,h.x);
    vec3 p1 = vec3(a0.zw,h.y);
    vec3 p2 = vec3(a1.xy,h.z);
    vec3 p3 = vec3(a1.zw,h.w);
  
  //Normalise gradients
    vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;
  
  // Mix final noise value
    vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
    m = m * m;
    return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                  dot(p2,x2), dot(p3,x3) ) );
  }
  
  void main() {
  
  
    
  
    vec3 noiseCoord = normal;
  
    float tilt = -0.8*normal.y;
  
    float incline  = normal.x*0.1;
    // float incline  = uv.x*10.;

  
    float offset = incline*mix(-.25,0.25,normal.x);
    // float offset = incline*mix(-2.,2.,normal.y);

  
  
  
    float noise = snoise(vec3(noiseCoord.x + time*3.,noiseCoord.y, time * 10.));
  
    noise = max(0.,noise);
  
    vec3 pos = vec3(position.x,position.y,position.z + noise * 0.3 +tilt + incline + offset);
    // vec3 pos = vec3(position.x,position.y,position.z + noise * 200. +tilt + incline + offset);

  
  
  
  
    vColor = uColor[4];
  
    for(int i = 0; i < 4; i++) {
  
      float noiseFlow  = 5. + float(i)*0.3;
      float noiseSpeed  = 10. + float(i)*0.3;
  
      float noiseSeed = 1. + float(i)*10.;
      vec2 noiseFreq = vec2(1.,1.4)*.4;
  
      float noiseFloor = 0.1;
      float noiseCeil = 0.6 + float(i)*0.07;
  
  
  
      float noise = smoothstep(noiseFloor,noiseCeil,
        snoise(
          vec3(
            noiseCoord.x*noiseFreq.x + time*noiseFlow,
            noiseCoord.y*noiseFreq.y, 
            time / 2.0 * noiseSpeed + noiseSeed
          )
        )
      );
  
      vColor = mix(vColor,uColor[i],noise);
  
      
    }
  
    // vUv = uv;
    vNormal = normal;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( pos, 1.0 );
  }
  
    `,
  /* glsl */ `
  uniform float time;
  uniform float progress;
  uniform sampler2D texture1;
  uniform vec4 resolution;
  uniform float opacity;
  // varying vec2  vUv;
  varying vec3 vNormal;
  varying vec3 vPosition;
  varying vec3 vColor;
  float PI = 3.141592653589793238;
  void main()	{
      // vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
      // gl_FragColor = vec4(vNormal,0.);
      gl_FragColor = vec4(vColor,1.);
      gl_FragColor.a = opacity;

  }
    `
);

extend({ GradientMaterial });

const Iphone = forwardRef(({ texture, children, ...props }, ref) => {
  const { nodes, materials } = useGLTF("/iphone.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[-0.1, 1.45, -0.26]} scale={0.04}>
        <mesh
          // visible={false}
          // castShadow
          // receiveShadow
          geometry={nodes.立方体034.geometry}
          material={materials["Material.001"]}
          // material={materials.GOLD}
        />
        <mesh
          // castShadow
          // receiveShadow
          geometry={nodes.立方体034_1.geometry}
          material={materials.Black}
        />
        <mesh
          // visible={false}
          // castShadow
          // receiveShadow
          geometry={nodes.立方体034_2.geometry}
          // material={materials.GLASS}
          // material={materials.BARRES}
          material={materials.GOLD}
          // material={materials.aluminium}
          // material-color="#aaaaaf"
          // material-envMapIntensity={0.2}
        />
        <mesh
          // castShadow
          // receiveShadow
          geometry={nodes.立方体034_3.geometry}
          material={materials.GOLD}
        />
        <mesh
          // castShadow
          // receiveShadow
          geometry={nodes.立方体034_4.geometry}
          // material={materials.BARRES}
          material={materials.GOLD}
        />
        <mesh
          // castShadow
          // receiveShadow
          geometry={nodes.立方体034_5.geometry}
          // material={materials.Labber}
          material={materials.GOLD}
        />
        <mesh
          // castShadow
          // receiveShadow
          geometry={nodes.立方体034_6.geometry}
          // material={materials.GLASS}
          material={materials.GOLD}
        />
        <mesh
          // castShadow
          // receiveShadow
          geometry={nodes.立方体034_7.geometry}
          // material={materials.Lenscover}
          material={materials.GOLD}
        />
        <mesh
          // castShadow
          // receiveShadow
          geometry={nodes.立方体034_8.geometry}
          // material={materials.CA}
          material={materials.GOLD}
        />
        {/* {children} */}

        <mesh
          // DISPLAY
          // visible={false}
          // castShadow
          // receiveShadow
          scale-x={1.15}
          position-y={1.1}
          // position-z={0.5}
          geometry={nodes.立方体034_9.geometry}
          // material={materials.Display}
          // material={materials.GOLD}
        >
          <meshBasicMaterial
            map={texture}
            toneMapped={false}
            // lightMapIntensity={0.1}
            // emissiveMap={texture}
            // emissiveIntensity={0.5}
          />
        </mesh>
        <mesh
          // visible={false}
          // castShadow
          // receiveShadow
          geometry={nodes.立方体034_10.geometry}
          // material={materials["Lens.2"]}
          material={materials.GOLD}

          // material={materials.CA}
        />
        <mesh
          // visible={false}
          // castShadow
          // receiveShadow
          geometry={nodes.立方体034_11.geometry}
          // material={materials.GLS}
          material={materials.GOLD}
        />
      </group>
    </group>
  );
});

function LottieYo(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const lottieRef = useRef();

  //   console.log(lottieRef.current);

  function fadeOutOnScroll(element) {
    if (!element) {
      return;
    }
    // console.log(element);
    // console.log(element.style.opacity);

    // var distanceToTop =
    //   window.pageYOffset + element.getBoundingClientRect().top;
    var elementHeight = element.offsetHeight;
    var scrollTop = document.documentElement.scrollTop;
    // console.log(document.documentElement.scrollTop);

    var opacity = 1;

    // if (scrollTop > distanceToTop) {
    //   opacity = 1 - (scrollTop - distanceToTop) / elementHeight;
    // }
    // console.log(scrollTop);
    if (scrollTop > 7000) {
      opacity = 1 - (scrollTop - 7000) / 1000;
    }

    if (opacity >= 0) {
      element.style.opacity = opacity;
    }
  }

  function scrollHandler() {
    fadeOutOnScroll(lottieRef.current);
  }

  window.addEventListener("scroll", scrollHandler);

  // useEffect(() => {
  //   console.log(props);
  // }, [props]);
  return (
    <div
      ref={lottieRef}
      style={{
        zIndex: "1",
        position: "fixed",
        left: "50%",
        bottom: "-30px",
        transform: "translate(-50%, 0%)",
        margin: "0 auto",
        fill: "white",
        stroke: "white",
        // opacity: 1 - animable.r1,
      }}
    >
      <Lottie
        speed={0.5}
        options={defaultOptions}
        height={150}
        width={200}
        // ref={lottieRef}
      />
    </div>
  );
}
async function createAudio(userInput, audioTrig, { threshold, expire } = {}) {
  //   console.log(userInput);

  // if (audioTrig === true) {
  //   console.log("YEOHEOHE");
  // }
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false,
  });
  //   const res = await fetch(url);
  //   const buffer = await res.arrayBuffer();
  const context = new (window.AudioContext || window.webkitAudioContext)();
  const analyser = context.createAnalyser();
  analyser.smoothingTimeConstant = 0.9;
  analyser.fftSize = 2048;
  const data = new Uint8Array(analyser.frequencyBinCount);
  const source = context.createMediaStreamSource(stream);

  // metronome start
  let notesInQueue = []; // notes that have been put into the web audio and may or may not have been played yet {note, time}
  let currentBeatInBar = 0;
  let beatsPerBar = 4;
  let tempo = 121;
  let lookahead = 25; // How frequently to call scheduling function (in milliseconds)
  let scheduleAheadTime = 0.1; // How far ahead to schedule audio (sec)
  let nextNoteTime = 0.0; // when the next note is due
  let isRunning = false;
  let intervalID = null;
  // let test = 0;
  let test = 0;
  let starter = 0;

  //   source.buffer = await new Promise((res) =>
  //     context.decodeAudioData(buffer, res)
  //   );

  source.loop = true;
  const gainNode = context.createGain();
  gainNode.gain.value = 0;
  gainNode.connect(context.destination);
  source.connect(analyser);
  analyser.connect(gainNode);

  isRunning = true;

  currentBeatInBar = 0;
  nextNoteTime = context.currentTime + 0.05;

  // intervalID = setInterval(() => scheduler(), lookahead);

  let time = Date.now();
  let state = {
    context,
    source,
    data,
    gainNode,
    gain: 1,
    signal: false,
    avg: 0,
    avgFreq: 0,
    avgGainNormalized: 0,
    avgFreqNormalized: 0,
    avgRounded: 0,
    tempoTrigger: 0,
    low: 0,
    medium: 0,
    high: 0,
    finalAvg: 0,

    update: () => {
      // test = 0;
      // console.log(test);
      // console.log(isRunning);
      state.tempoTrigger = test;
      // console.log(tempoTrigger);
      let now = Date.now();
      let value = 0;
      let value2 = 0;
      let value3 = 0;
      let lowBand = 0;
      let mediumBand = 0;
      let highBand = 0;
      // let start = context.currentTime;
      // let startRounded = start.toFixed(1);
      // let tempoRaw = 130 / 60;
      // let tempoRounded = tempoRaw.toFixed(1);
      // if (startRounded === tempoRounded) {
      //   console.log("beat");
      //   startRounded = startRounded - startRounded;
      // }
      // if (startRounded % tempoRounded === 0) {
      //   console.log("beat");
      // }
      // console.log(startRounded % tempoRounded);
      // console.log(startRounded);

      analyser.getByteFrequencyData(data);
      // console.log(data);
      // console.log(context.currentTime);
      // console.log(context.startedAt);
      // if (context.currentTime)
      for (let i = 0; i < data.length; i++) {
        value += data[i];
        value2 += (i + 1) * data[i];
        value3 += data[i];
      }
      for (let i = 0; i < 30; i++) {
        lowBand += data[i];
      }
      for (let i = 30; i < 650; i++) {
        mediumBand += data[i];
      }
      for (let i = 650; i < 1024; i++) {
        highBand += data[i];
      }

      const low = (state.low = lowBand / data.length);
      const medium = (state.medium = mediumBand / data.length);
      const high = (state.high = highBand / data.length);

      const avg = (state.avg = value / data.length);
      // const avgPlease = (state.avg = value / data.length);
      // return avgPlease;

      const avgRounded = (state.avgRounded = Math.round(avg * 10) / 10);
      const avgFreq = (state.avgFreq = value2 / value3);
      const avgGainNormalized = (state.avgGainNormalized =
        (10 * Math.pow(avg, 0.5)) / 160);
      // (7 * Math.pow(avg, 0.6)) / 194);
      const avgFreqNormalized = (state.avgFreqNormalized =
        (10.24 * Math.pow(avgFreq, 0.75)) / 1024);
      if (threshold && avg > threshold && now - time > expire) {
        time = Date.now();
        state.signal = true;
      } else state.signal = false;
      return value / data.length;
    },
    // setGain(level) {
    //   gainNode.gain.setValueAtTime((state.gain = level), context.currentTime);
    // },
  };

  return state;
}
