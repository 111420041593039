import {
  createStyles,
  Container,
  Group,
  ActionIcon,
  Image,
  Anchor,
  Text,
} from "@mantine/core";

import { IconBrandTwitter, IconBrandInstagram } from "@tabler/icons";

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: 0,
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xs,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  inner2: {
    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export function TheFooter() {
  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <Container
        style={{ marginLeft: 0, marginRight: 0, maxWidth: "100%" }}
        className={classes.inner}
      >
        <Group className={classes.inner2}>
          <Image src="/images/sounddropLogo.png" width={30} />
          <Text color="dimmed" size="xs">
            © 2022 SoundDrop.io. All rights reserved.
          </Text>
        </Group>
        <Group className={classes.links}>
          <Anchor
            variant="light"
            color="blue.2"
            onClick={(event) => event.preventDefault()}
            size="xs"
            pr="sm"
          >
            Legal
          </Anchor>
          <ActionIcon
            onClick={() => {
              window.open("https://twitter.com/SoundDrop_io", "_blank");
            }}
            color={"dimmed"}
            size="sm"
          >
            <IconBrandTwitter size={18} stroke={1.5} />
          </ActionIcon>

          <ActionIcon
            onClick={() => {
              window.open("https://www.instagram.com/sounddrop.io", "_blank");
            }}
            color={"dimmed"}
            size="sm"
          >
            <IconBrandInstagram size={18} stroke={1.5} />
          </ActionIcon>
        </Group>
      </Container>
    </div>
  );
}
