import React, { useEffect, useRef, useState } from "react";
import { useToggle } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";

import { gsap } from "gsap";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Text,
  Button,
  Group,
  createStyles,
  Container,
  Center,
} from "@mantine/core";
import "./stylesLanding.css";
import styles from "../styles.module.css";

import { ScrollToPlugin } from "gsap/all";
import LandingPageMobile from "./LandingPageMobile";

const BREAKPOINT = "@media (max-width: 755px)";

const useStyles = createStyles((theme) => ({
  wrapper: {
    marginTop: "100px",
    width: "100vw",

    position: "relative",
    boxSizing: "border-box",
  },
  wrapper2: {
    width: "100vw",

    position: "relative",
    boxSizing: "border-box",
  },

  inner: {
    position: "relative",
    paddingBottom: 80,
    paddingTop: 80,
    marginRight: 0,

    [BREAKPOINT]: {
      paddingBottom: 80,
      paddingTop: 80,
    },
  },
  inner11: {
    position: "relative",
    paddingBottom: 80,
    paddingTop: 80,

    [BREAKPOINT]: {
      paddingBottom: 80,
      paddingTop: 80,
    },
  },
  inner2: {
    position: "relative",
    paddingBottom: 80,
    paddingTop: 80,

    [BREAKPOINT]: {
      paddingBottom: 80,
      paddingTop: 80,
    },
  },
  inner9: {
    position: "relative",
    paddingTop: 80,

    [BREAKPOINT]: {
      paddingBottom: 80,
      paddingTop: 80,
    },
  },
  inner10: {
    position: "relative",

    [BREAKPOINT]: {
      paddingBottom: 80,
      paddingTop: 80,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 10,
    lineHeight: 1.2,
    fontWeight: 900,
    margin: 0,
    padding: 0,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,

    [BREAKPOINT]: {
      fontSize: 32,
      lineHeight: 1.2,
    },
  },
  title2: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 10,
    lineHeight: 1.2,
    fontWeight: 750,
    margin: 0,
    padding: 0,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,

    [BREAKPOINT]: {
      fontSize: 22,
      lineHeight: 1.2,
    },
  },
  title3: {
    textAlign: "right",
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 10,
    lineHeight: 1.2,
    fontWeight: 800,
    margin: 0,
    marginTop: "200px",
    padding: 0,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,

    [BREAKPOINT]: {
      fontSize: 22,
      lineHeight: 1.2,
    },
  },
  title4: {
    textAlign: "left",
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 18,
    lineHeight: 1.2,
    fontWeight: 800,
    margin: 0,
    marginTop: "200px",
    padding: 0,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,

    [BREAKPOINT]: {
      fontSize: 22,
      lineHeight: 1.2,
    },
  },
  title10: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 18,
    lineHeight: 1.2,
    fontWeight: 900,
    margin: 0,
    padding: 0,
    color: theme.colorScheme === "dark" ? theme.gray : theme.black,

    [BREAKPOINT]: {
      fontSize: 22,
      lineHeight: 1.2,
    },
  },

  description: {
    marginTop: theme.spacing.xl,
    fontSize: 18,

    [BREAKPOINT]: {
      fontSize: 18,
    },
  },
  description2: {
    textAlign: "center",
    marginTop: theme.spacing.xl,
    fontSize: 18,

    [BREAKPOINT]: {
      fontSize: 18,
    },
  },

  controls: {
    marginTop: theme.spacing.xl,

    [BREAKPOINT]: {
      marginTop: theme.spacing.xl,
    },
  },
  controls2: {
    marginTop: theme.spacing.xl,

    [BREAKPOINT]: {
      marginTop: theme.spacing.xl,
    },
  },

  control: {
    height: 54,
    paddingLeft: 18,
    paddingRight: 18,
    flex: 1,

    [BREAKPOINT]: {
      height: 44,
      paddingLeft: 18,
      paddingRight: 18,
      flex: 1,
    },
  },
}));

function ContentMobile() {
  const navigate = useNavigate();
  gsap.registerPlugin(ScrollToPlugin);

  const { classes } = useStyles();

  const [enableText, toggle] = useToggle([
    "Enable Audio Input",
    "Disable Audio Input",
  ]);

  const ref = useRef();
  useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
    });
  }, []);

  const [audioInput, setAudioInput] = useState(false);

  return (
    <>
      <Center>
        <div
          style={{
            fontSize: "21px",

            left: 0,
            top: 0,
            paddingLeft: "1rem",
            paddingTop: "1rem",

            position: "absolute",
            zIndex: 100,
          }}
          className={styles.gradientText10}
        >
          SoundDrop
        </div>
      </Center>
      <div
        style={{
          position: "fixed",
          zIndex: "999999999",
          top: "15px",
          right: "15px",
        }}
      >
        <Button
          onClick={() => navigate("/login")}
          variant="light"
          color="orange"
          radius="lg"
        >
          Login / Sign Up
        </Button>
      </div>
      <div ref={ref} className="content">
        <div style={{ zIndex: "3", position: "fixed" }}>
          <section className="section-one">
            <div className={classes.wrapper}>
              <Container
                size={350}
                className={classes.inner}
                style={{
                  marginLeft: "15px",
                }}
              >
                <h1
                  className={classes.title}
                  data-aos="fade-zoom-in"
                  data-aoos-duration="2000"
                  style={{
                    textAlign: "center",
                    fontSize: 36,
                    marginTop: "100px",
                  }}
                >
                  The most
                  <div
                    className={styles.slidingVertical}
                    style={{
                      fontSize: 42,
                      fontWeight: 900,
                      lineHeight: 1.1,
                      margin: 10,
                      padding: 10,
                      display: "flex",
                      textIndent: 0,
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <span>interactive</span>
                    <span>stunning</span>
                    <span>audio-reactive</span>
                    <span>cutting-edge</span>
                    <span>3D</span>
                  </div>
                  <Text style={{ lineHeight: 1.2 }}>
                    way to promote your music
                  </Text>
                </h1>

                <Text
                  className={classes.description}
                  color="dimmed"
                  data-aos="fade-zoom-in"
                  data-aos-delay="500"
                  style={{
                    textAlign: "center",
                    fontSize: 16,
                  }}
                >
                  Upload your{" "}
                  <span style={{ color: "#c4c4c4", fontWeight: 800 }}>
                    cover art
                  </span>
                  , add your
                  <span style={{ color: "#c4c4c4", fontWeight: 800 }}>
                    {" "}
                    track
                  </span>
                  , and SoundDrop will create an entirely new way to share your
                  music with listeners.
                </Text>

                <Group position="center" className={classes.controls}>
                  <div className={styles.box2}>
                    <Button
                      className={classes.control}
                      size="md"
                      color="gray"
                      variant="default"
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Get Started
                    </Button>
                  </div>
                </Group>
              </Container>
            </div>
          </section>
        </div>
      </div>

      <div className={styles.canvas3D}>
        <LandingPageMobile audioInput={audioInput} />
      </div>
    </>
  );
}

export default ContentMobile;
