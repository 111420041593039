import { useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { Leva } from "leva";
import "bootstrap/dist/css/bootstrap.min.css";
import useStore2 from "./store2";
import Pricing2Mobile from "./Mantine/Pricing2Mobile";
import useCapture from "use-capture";
import styles from "./styles.module.css";
import { useAuth } from "./Firebase/hooks/useAuth";
import { Route, Routes } from "react-router-dom";
import { fb } from "./Firebase/Shared/Service";
import LiveCanvas from "./LiveCanvas";
import { httpsCallable } from "firebase/functions";
import Content from "./Mantine/Content";
import { NotificationsProvider } from "@mantine/notifications";
import LoginCanvas from "./LoginCanvas";
import Lottie from "react-lottie";
import * as animationData from "./Mantine/lottie/email.json";
import ContentMobile from "./Mantine/ContentMobile";
import LoginCanvas2 from "./LoginCanvas2";
import {
  MantineProvider,
  List,
  Text,
  Button,
  Modal,
  useMantineTheme,
  Group,
  TextInput,
  Center,
  Container,
  Title,
  ScrollArea,
} from "@mantine/core";
import LoginM from "./Mantine/LoginM";
import CardGrid from "./Mantine/CardGrid";
import NavBar from "./Mantine/NavBar";
import PasswordRecovery from "./Mantine/PasswordRecovery";
import { PageM } from "./Mantine/PageM";
import { doc, setDoc, getDoc } from "firebase/firestore";
import NewCreatePageM from "./Mantine/NewCreatePageM";
import { useUsernames } from "./Firebase/hooks/useUsernames";
import { isMobile } from "react-device-detect";
import FinalCanvas from "./FinalCanvas";
import Pricing2 from "./Mantine/Pricing2";
const BREAKPOINT = "@media (maxWidth: 755px)";

function App(props) {
  const { isAuthed, authUser } = useAuth();
  const [navTrigger, setNavTrigger] = useState(true);
  const hookUser = useUsernames(authUser?.uid);
  const [finalHookUser, setFinalHookUser] = useState(null);
  const [cardGridTrigger, setCardGridTrigger] = useState(false);
  useEffect(() => {
    if (hookUser) {
      if (hookUser.length === 0) {
        setOpened(true);
      } else if (hookUser.length > 0) {
        setOpened(false);
        setFinalHookUser(hookUser[0].userName);
        setCardGridTrigger(true);
      }
    }
  }, [hookUser]);

  const storeUser = useStore2((state) => state.storeUser);
  const storeTrack = useStore2((state) => state.storeTrack);
  const [storeTrackTrigger, setStoreTrackTrigger] = useState(false);
  useEffect(() => {
    if (storeTrack !== null) {
      setStoreTrackTrigger(true);
    }
  }, [storeUser, storeTrack]);
  useEffect(() => {
    if (storeTrackTrigger) {
      setNavTrigger(false);
    }
  }, [storeTrackTrigger]);

  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const theme = useMantineTheme();

  const setLink = async () => {
    if (/\s/g.test(value) || /-/g.test(value)) {
      setError("Can't contain spaces or dashes");
    } else {
      let link = value;
      let linkLower = link.toLowerCase();
      const docRef = doc(fb.firestore, "allUsers", linkLower);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        console.log("user exists");
        setError("This link is already taken");
      } else {
        // doc.data() will be undefined in this case
        console.log("User does not exist");
        submitLink();
      }
    }
  };

  const submitLink = async () => {
    let link = value;
    let linkLower = link.toLowerCase();
    await setDoc(doc(fb.firestore, "allUsers", linkLower), {
      userName: linkLower,
      userId: authUser.uid,
    }).then(() => {
      console.log("reached");
      setOpened(false);
    });
  };
  const [checkSubscriptionProp, setCheckSubscriptionProp] = useState(false);
  const [proRole, setProRole] = useState(false);
  const [proPlusRole, setProPlusRole] = useState(false);

  useEffect(() => {
    if (authUser) {
      getCustomClaimRole().then((res) => {
        // console.log(res);
        if (res === "premium") {
          setCheckSubscriptionProp(true);
          setProRole(true);
        }
        if (res === "premiumPlus") {
          setCheckSubscriptionProp(true);
          setProPlusRole(true);
        }
      });
    }
  }, [authUser]);

  async function getCustomClaimRole() {
    await fb.auth.currentUser.getIdToken(true);
    const decodedToken = await fb.auth.currentUser.getIdTokenResult();
    return decodedToken.claims.stripeRole;
  }

  const portalNav = async () => {
    const functionRef = httpsCallable(
      fb.functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );

    const { data } = await functionRef({
      returnUrl: window.location.origin,
      locale: "auto", // Optional, defaults to "auto"
      // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    });
    window.location.assign(data.url);
  };

  return (
    <>
      <Leva hideTitleBar />

      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          breakpoints: {
            xs: 365,
          },

          colorScheme: "dark",
        }}
      >
        <NotificationsProvider>
          {navTrigger && (
            <NavBar checkSubscriptionProp={checkSubscriptionProp} />
          )}

          {authUser === undefined ? (
            <></>
          ) : isAuthed && fb.auth.currentUser.emailVerified ? (
            <>
              <div
                style={{ boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px" }}
              >
                <Modal
                  shadow={theme.shadows.md}
                  radius="lg"
                  overlayColor={theme.colors.dark[9]}
                  transition="fade"
                  transitionDuration={700}
                  transitionTimingFunction="ease"
                  centered
                  withCloseButton={false}
                  overlayOpacity={0.7}
                  overlayBlur={10}
                  opened={opened}
                  onClose={() => setOpened(false)}
                  closeOnEscape={false}
                  closeOnClickOutside={false}
                >
                  {isMobile ? (
                    <Group position="center">
                      <Title align="center">Welcome to SoundDrop</Title>
                      <TextInput
                        value={value}
                        onChange={(event) => {
                          setValue(event.currentTarget.value);
                          setError("");
                        }}
                        style={{ maxWidth: "100%" }}
                        iconWidth={120}
                        icon={
                          <Text ml={10} mt={1}>
                            SoundDrop.io/
                          </Text>
                        }
                        size="lg"
                        radius="xl"
                        required
                        placeholder="artist"
                      />
                      <div className={styles.box2}>
                        <Button
                          style={{ height: "40px" }}
                          onClick={setLink}
                          variant="default"
                        >
                          Claim your link
                        </Button>
                      </div>

                      <List mt={25} size="sm" withPadding>
                        <List.Item>
                          We recommend using your artist name
                        </List.Item>
                        <List.Item>
                          {" "}
                          You can change your URL up to 3 times
                        </List.Item>
                      </List>
                    </Group>
                  ) : (
                    <>
                      <Group position="center">
                        <Title mt={20} mb={20} align="center">
                          Welcome to SoundDrop
                        </Title>
                        <TextInput
                          value={value}
                          onChange={(event) => {
                            setValue(event.currentTarget.value);
                            setError("");
                          }}
                          style={{ maxWidth: "60%" }}
                          iconWidth={120}
                          icon={
                            <Text ml={10} mt={1}>
                              SoundDrop.io/
                            </Text>
                          }
                          size="lg"
                          radius="xl"
                          placeholder="artist"
                        />
                        <div className={styles.box2}>
                          <Button
                            // disabled={claimDisabled}
                            style={{ height: "40px" }}
                            onClick={setLink}
                            variant="default"
                          >
                            Claim your link
                          </Button>
                        </div>
                      </Group>

                      <List mt={25} size="sm" withPadding>
                        <List.Item>
                          We recommend using your artist name
                        </List.Item>
                        <List.Item>
                          {" "}
                          You can change your URL up to 3 times
                        </List.Item>
                      </List>
                      {/* </Center> */}
                    </>
                  )}
                  {error && isMobile ? (
                    <Center>
                      <div
                        style={{
                          marginTop: "10px",
                          color: "red",
                          fontSize: "13px",
                        }}
                      >
                        {error}
                      </div>
                    </Center>
                  ) : (
                    <div
                      style={{
                        marginTop: "10px",
                        marginLeft: "10px",
                        color: "red",
                        fontSize: "13px",
                      }}
                    >
                      {error}
                    </div>
                  )}
                </Modal>
              </div>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <div
                        style={{
                          position: "absolute",
                          zIndex: "0",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <LoginCanvas2 />
                      </div>
                      <ScrollArea style={{ height: "100%" }}>
                        {cardGridTrigger && hookUser.length > 0 && (
                          <CardGrid
                            checkSubscriptionProp={checkSubscriptionProp}
                            proRole={proRole}
                            proPlusRole={proPlusRole}
                            finalHookUser={finalHookUser}
                          />
                        )}
                      </ScrollArea>
                    </>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <>
                      <div
                        style={{
                          position: "absolute",
                          zIndex: "0",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <LoginCanvas2 />
                      </div>
                      <ScrollArea style={{ height: "100%" }}>
                        {cardGridTrigger && hookUser.length > 0 && (
                          <CardGrid
                            checkSubscriptionProp={checkSubscriptionProp}
                            proRole={proRole}
                            proPlusRole={proPlusRole}
                            finalHookUser={finalHookUser}
                          />
                        )}
                      </ScrollArea>
                    </>
                  }
                />
                <Route
                  path="/create"
                  element={
                    <>
                      <div
                        style={{
                          position: "absolute",
                          zIndex: "0",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <LoginCanvas2 />
                      </div>
                      <NewCreatePageM
                        checkSubscriptionProp={checkSubscriptionProp}
                        proRole={proRole}
                        proPlusRole={proPlusRole}
                        finalHookUser={finalHookUser}
                      />
                    </>
                  }
                />
                <Route
                  path="/:id"
                  element={
                    <>
                      <PageM />
                      <FinalCanvas />
                    </>
                  }
                />
                <Route
                  path="/live/:id"
                  element={
                    <>
                      <LiveCanvas
                        checkSubscriptionProp={checkSubscriptionProp}
                        proRole={proRole}
                        proPlusRole={proPlusRole}
                      />
                    </>
                  }
                />
                <Route
                  path="/pricing"
                  element={
                    <>
                      <div
                        style={{
                          position: "absolute",
                          zIndex: "0",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <LoginCanvas2 />
                      </div>

                      <ScrollArea style={{ height: "100%" }}>
                        {isMobile ? <Pricing2Mobile /> : <Pricing2 />}
                      </ScrollArea>
                    </>
                  }
                />
              </Routes>
            </>
          ) : isAuthed ? (
            <>
              {isMobile ? (
                <>
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "0",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <LoginCanvas2 />
                  </div>
                  <div
                    style={{
                      paddingTop: "50px",
                      width: "100vw",

                      position: "relative",
                      boxSizing: "border-box",
                    }}
                  >
                    <Container
                      size={800}
                      style={{
                        position: "relative",
                        paddingBottom: 120,

                        [BREAKPOINT]: {
                          paddingBottom: 80,
                          paddingTop: 0,
                        },
                      }}
                    >
                      <LottieYo mobile={true} />

                      <h1
                        style={{
                          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                          fontSize: 52,
                          fontWeight: 900,
                          lineHeight: 1.1,
                          margin: 0,
                          padding: 0,
                          textAlign: "center",

                          color: theme.white,

                          [BREAKPOINT]: {
                            fontSize: 42,
                            lineHeight: 1.2,
                          },
                        }}
                      >
                        Verify Your Email to Get Started
                      </h1>
                      <Text
                        style={{
                          textAlign: "center",
                          marginTop: theme.spacing.xl,
                          fontSize: 22,
                          fontWeight: 500,

                          [BREAKPOINT]: {
                            fontSize: 18,
                          },
                        }}
                        color="dimmed"
                      >
                        We've sent an email to your inbox
                      </Text>
                      <Text
                        style={{
                          textAlign: "center",
                          marginTop: theme.spacing.xs,
                          fontSize: 16,

                          [BREAKPOINT]: {
                            fontSize: 18,
                          },
                        }}
                        color="dimmed"
                      >
                        Make sure to check your spam folder
                      </Text>

                      <Group
                        style={{
                          marginTop: theme.spacing.xl,

                          [BREAKPOINT]: {
                            marginTop: theme.spacing.xl,
                          },
                        }}
                        position="center"
                      >
                        <div className={styles.box3}>
                          <Button
                            onClick={() => window.location.reload()}
                            style={{
                              [BREAKPOINT]: {},
                            }}
                            size="lg"
                            color="gray"
                            variant="default"
                          >
                            I've Verified!
                          </Button>
                        </div>
                      </Group>
                    </Container>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "0",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <LoginCanvas2 />
                  </div>
                  <div
                    style={{
                      // marginTop: "100px",
                      paddingTop: "200px",
                      width: "100vw",
                      // marginRight: "810px",

                      position: "relative",
                      boxSizing: "border-box",
                    }}
                  >
                    <Container
                      size={800}
                      // className={classes.inner11}
                      style={{
                        position: "relative",
                        // paddingTop: 200,
                        paddingBottom: 120,

                        [BREAKPOINT]: {
                          paddingBottom: 80,
                          paddingTop: 0,
                        },
                      }}
                    >
                      <LottieYo mobile={false} />

                      <h1
                        style={{
                          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                          fontSize: 72,
                          fontWeight: 900,
                          lineHeight: 1.1,
                          margin: 0,
                          padding: 0,
                          textAlign: "center",

                          color: theme.white,

                          [BREAKPOINT]: {
                            fontSize: 42,
                            lineHeight: 1.2,
                          },
                        }}
                      >
                        Verify Your Email to Get Started
                      </h1>
                      <Text
                        style={{
                          textAlign: "center",
                          marginTop: theme.spacing.xl,
                          fontSize: 22,
                          fontWeight: 500,

                          [BREAKPOINT]: {
                            fontSize: 18,
                          },
                        }}
                        color="dimmed"
                      >
                        We've sent an email to your inbox
                      </Text>
                      <Text
                        style={{
                          textAlign: "center",
                          marginTop: theme.spacing.xs,
                          fontSize: 16,

                          [BREAKPOINT]: {
                            fontSize: 18,
                          },
                        }}
                        color="dimmed"
                      >
                        Make sure to check your spam folder
                      </Text>

                      <Group
                        style={{
                          marginTop: theme.spacing.xl * 2,

                          [BREAKPOINT]: {
                            marginTop: theme.spacing.xl,
                          },
                        }}
                        position="center"
                      >
                        <div className={styles.box3}>
                          <Button
                            onClick={() => window.location.reload()}
                            style={{
                              [BREAKPOINT]: {},
                            }}
                            size="lg"
                            color="gray"
                            variant="default"
                          >
                            I've Verified!
                          </Button>
                        </div>
                      </Group>
                    </Container>
                  </div>
                </>
              )}
            </>
          ) : (
            <Routes>
              <Route
                path="/:id"
                element={
                  <>
                    <PageM />
                    <FinalCanvas />
                  </>
                }
              />
              <Route
                path="/signup"
                element={
                  <>
                    <LoginM loginProp={"signup"} />
                    <Canvas
                      linear
                      flat
                      shadows
                      dpr={[1, 2]}
                      camera={{ position: [0, -3.2, 40], fov: 12 }}
                    >
                      <ambientLight intensity={1.5} />
                      <LoginCanvas />
                    </Canvas>
                  </>
                }
              />
              <Route
                path="/login"
                element={
                  <>
                    {/* <Login /> */}
                    <LoginM loginProp={"login"} />
                    <Canvas
                      linear
                      flat
                      shadows
                      dpr={[1, 2]}
                      camera={{ position: [0, -3.2, 40], fov: 12 }}
                    >
                      {/* <color attach="background" args={["#1A1B1E"]} /> */}
                      <ambientLight intensity={1.5} />
                      <LoginCanvas />
                    </Canvas>
                  </>
                }
              />
              <Route
                path="/password-recovery"
                element={
                  <>
                    <PasswordRecovery />
                    <Canvas
                      linear
                      flat
                      shadows
                      dpr={[1, 2]}
                      camera={{ position: [0, -3.2, 40], fov: 12 }}
                    >
                      <ambientLight intensity={1.5} />
                      <LoginCanvas />
                    </Canvas>
                  </>
                }
              />
              <Route
                path="/"
                element={<>{isMobile ? <ContentMobile /> : <Content />}</>}
              />
            </Routes>
          )}
        </NotificationsProvider>
      </MantineProvider>
    </>
  );
}

function LottieYo(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <Lottie options={defaultOptions} height={150} width={150} />;
}

export default App;
