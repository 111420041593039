import { useFrame, extend, useThree } from "@react-three/fiber";
import React, { useEffect, useRef, useState, useMemo } from "react";
import LandingWorld from "./LandingWorld";
import useStore2 from "./store2";
import { KaleidoShader } from "three/examples/jsm/shaders/KaleidoShader.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";

const getResizedImage = async (path) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const img = new Image();
  img.crossOrigin = "anonymous";
  img.src = path;

  await img.decode();

  canvas.width = 1000;
  canvas.height = 1000;

  // step 1 - resize to 50%
  const oc = document.createElement("canvas");
  const octx = oc.getContext("2d");

  oc.width = 1000;
  oc.height = 1000;
  octx.drawImage(img, 0, 0, oc.width, oc.height);

  // step 2
  octx.drawImage(oc, 0, 0, oc.width, oc.height);

  // step 3, resize to final size
  ctx.drawImage(
    oc,
    0,
    0,
    oc.width,
    oc.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  // console.log({ w: canvas.width, imagewidth: img.width });

  return canvas.toDataURL();
};
export default function EditPointsWorldContainer(props) {
  const landingImage = useStore2((state) => state.landingImage);
  const [prismaTrigger, setPrismaTrigger] = useState(false);
  const landingBackground = useStore2((state) => state.landingBackground);
  // useEffect(() => {
  //   if (landingTrigger === "PRISMA") {
  //     setPrismaTrigger(true);
  //   } else {
  //     setPrismaTrigger(false);
  //   }
  // }, [landingImage]);
  //   const [width, setWidth] = useState(window.innerWidth);

  //   function handleWindowSizeChange() {
  //     setWidth(window.innerWidth);
  //   }
  //   useEffect(() => {
  //     window.addEventListener("resize", handleWindowSizeChange);
  //     return () => {
  //       window.removeEventListener("resize", handleWindowSizeChange);
  //     };
  //   }, []);
  //   const isMobile = width <= 768;
  const pointsRef = useRef();

  const [imageList, setImageList] = useState([]);
  const [woahImage, setWoahImage] = useState("");
  // const  = useStore2((state) => state.pageId);
  const [pageNameState, setPageNameState] = useState(null);
  const [finalImageUrl, setFinalImageUrl] = useState(null);

  useEffect(() => {
    if (landingImage !== null) {
      setWoahImage(landingImage);
    }
  });

  const [imageData, setImageData] = useState(null);
  const img = useRef(null);

  const createImage = async (imagePath) => {
    const resizedImage = await getResizedImage(imagePath);

    img.current = document.createElement("img");
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    img.current.crossOrigin = "Anonymous";
    img.current.src = resizedImage;

    await img.current.decode();

    canvas.width = img.current.width;
    canvas.height = img.current.height;

    context.fillStyle = context.createPattern(img.current, "no-repeat");
    context.fillRect(0, 0, img.current.width, img.current.height);

    setImageData(
      context.getImageData(0, 0, img.current.width, img.current.height).data
    );
  };
  useEffect(() => {
    if (woahImage !== null) {
      createImage(woahImage);
    }
  }, [woahImage]);

  const { gl, scene, camera, size } = useThree();
  useEffect(() => {
    if (landingBackground === "PRISMA") {
      camera.position.z = 35;
    } else {
      camera.position.z = 40;
    }
    console.log(camera.position.z);
  }, [landingBackground]);
  var x = 0;
  var sin = 0;
  var cos = 0;
  useFrame((state, delta) => {
    // console.log(props);
    x += delta;
    sin = Math.sin(x);
    cos = Math.cos(x / 10);
    // console.log(props);

    if (landingBackground === "PRISMA") {
      // pointsRef.current.rotation.y += delta / 2;
      pointsRef.current.rotation.z += delta / 12;
      pointsRef.current.rotation.x += delta / 16;
      // pointsRef.current.position.z = 1000;
      // camera.position.z = cos * 10 + 10;
      pointsRef.current.rotation.y = cos * Math.PI;
      // camera.position.z =
    } else {
      // camera.position.z = 40;
      pointsRef.current.rotation.set(0, 0, 0);
      // pointsRef.updateMatrix();
    }
  });

  if (imageData === null) return null;
  if (!img.current) return null;

  return (
    <>
      <group ref={pointsRef}>
        {landingBackground === "PRISMA" && <Prisma />}
        <LandingWorld
          avgPlease={props.avgPlease}
          audioInput={props.audioInput}
          zAmount={props.zAmount}
          speed={props.speed}
          palette={props.palette}
          bgProp={props.bgProp}
          imageData={imageData}
          imageWidth={img.current.width}
          imageHeight={img.current.height}
        />
      </group>
    </>
  );
}
const Prisma = (props) => {
  //   console.log(props.effect);
  //   console.log(props.blur);
  const { gl, scene, camera, size } = useThree();
  const [bloom, final] = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const comp = new EffectComposer(gl);
    comp.renderToScreen = false;
    comp.addPass(renderScene);
    const finalComposer = new EffectComposer(gl);

    finalComposer.addPass(renderScene);
    finalComposer.addPass(new AfterimagePass(0.88));

    const kaleidoShader = new ShaderPass(KaleidoShader);
    // OG
    kaleidoShader.uniforms["sides"].value = 3;
    kaleidoShader.uniforms["angle"].value = 0.5;
    finalComposer.addPass(kaleidoShader);

    // Simple
    // kaleidoShader.uniforms["sides"].value = 2;
    // finalComposer.addPass(kaleidoShader);

    // Extreme
    // kaleidoShader.uniforms["sides"].value = 10;
    // kaleidoShader.uniforms["angle"].value = 0.9;

    return [comp, finalComposer];
  }, []);

  useEffect(() => {
    bloom.setSize(size.width, size.height);
    final.setSize(size.width, size.height);
  }, [bloom, final, size]);

  useFrame(() => {
    bloom.render();
    final.render();
  }, 1);
  return null;
};
