import {
  createStyles,
  Paper,
  Title,
  Text,
  TextInput,
  Button,
  Container,
  Group,
  Anchor,
  Center,
  Box,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { Notification } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 26,
    fontWeight: 900,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  controls: {
    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column-reverse",
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      width: "100%",
      textAlign: "center",
    },
  },
}));

export default function PasswordRecovery() {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const auth = getAuth();
  const [alert, setAlert] = useState(false);
  const [invalidEmailAlert, setInvalidEmailAlert] = useState(false);
  const [userNotFoundAlert, setUserNotFoundAlert] = useState(false);

  const passwordReset = (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setAlert(true);
        setUserNotFoundAlert(false);
        setInvalidEmailAlert(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        if (errorCode === "auth/user-not-found") {
          setUserNotFoundAlert(true);
        }
        if (errorCode === "auth/invalid-email") {
          setInvalidEmailAlert(true);
        }
        const errorMessage = error.message;
      });
  };
  const [value, setValue] = useState("");

  return (
    <Center>
      <Container
        size={460}
        style={{
          position: "absolute",
          zIndex: "10",
          marginTop: "500px",
        }}
      >
        {alert && (
          <Notification
            onClick={() => navigate("/login")}
            mb={50}
            icon={<IconCheck size={20} />}
            color="green"
            radius="lg"
            title="Password Reset link sent!"
          >
            Be sure to check your spam folder
          </Notification>
        )}
        {invalidEmailAlert && (
          <Notification
            onClick={() => setInvalidEmailAlert(false)}
            mb={50}
            icon={<IconX size={20} />}
            color="red"
            radius="lg"
            title="Invalid Email"
          >
            Please try again
          </Notification>
        )}
        {userNotFoundAlert && (
          <Notification
            onClick={() => setUserNotFoundAlert(false)}
            mb={50}
            icon={<IconX size={20} />}
            color="red"
            radius="lg"
            title="User not found in database"
          >
            Please try again or create an account
          </Notification>
        )}

        <Title className={classes.title} align="center">
          Forgot your password?
        </Title>
        <Text color="dimmed" size="sm" align="center">
          Enter your email to get a reset link
        </Text>
        <Paper
          withBorder
          shadow="md"
          p={30}
          radius="md"
          mt="xl"
          style={{ backgroundColor: "transparent" }}
        >
          <TextInput
            size="md"
            label="Your email"
            placeholder="example@mail.com"
            required
            value={value}
            onChange={(event) => setValue(event.currentTarget.value)}
          />
          <Group position="apart" mt="lg" className={classes.controls}>
            <Anchor
              color="dimmed"
              size="sm"
              className={classes.control}
              onClick={() => {
                navigate("/login");
              }}
            >
              <Center inline>
                <IconArrowLeft size={12} stroke={1.5} />
                <Box ml={5}>Back to login page</Box>
              </Center>
            </Anchor>
            <Button
              disabled={!value}
              onClick={() => {
                passwordReset(value);
              }}
              className={classes.control}
            >
              Reset password
            </Button>
          </Group>
        </Paper>
      </Container>
    </Center>
  );
}
