import {
  createStyles,
  SimpleGrid,
  Text,
  Container,
  Button,
  Group,
  Center,
  Modal,
  useMantineTheme,
  TextInput,
  Stack,
  Badge,
  Tooltip,
  ActionIcon,
} from "@mantine/core";
import AOS from "aos";
import CardGridLinkSingle from "./CardGridLinkSingle";
import styles from "../styles.module.css";
import { useEffect, useState } from "react";
import { useUserPages } from "../Firebase/hooks";
import { useNavigate } from "react-router-dom";
import { fb } from "../Firebase/Shared/Service";
import CardGridLink from "./CardGridLink";
import { VscDebugStart } from "react-icons/vsc";
import { HiOutlineDocumentAdd } from "react-icons/hi";
import { TheFooter } from "./TheFooter";
import { FiEdit } from "react-icons/fi";
const BREAKPOINT = "@media (maxWidth: 755px)";

const useStyles = createStyles((theme) => ({
  add: {
    transition: "transform 150ms ease, box-shadow 150ms ease",
    cursor: "pointer",
    color: theme.colors.blue[6],

    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: theme.shadows.md,
    },
  },
  card: {
    transition: "transform 150ms ease, box-shadow 150ms ease",
    cursor: "pointer",

    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: theme.shadows.md,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 600,
  },
}));

export default function CardGrid(props) {
  const [openedYo, setOpenedYo] = useState(false);
  useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
    });
  }, []);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  const theme = useMantineTheme();

  const navigate = useNavigate();

  const [value, setValue] = useState("");

  const pages = useUserPages(props.finalHookUser);

  const [error, setError] = useState("");
  let lowerUser = props.finalHookUser.toLowerCase();
  const [newDisabled, setNewDisabled] = useState(true);
  const [ogPlaceholder, setOgPlaceholder] = useState(lowerUser + "-_____");

  useEffect(() => {
    newDisabled
      ? setOgPlaceholder(lowerUser + "-_____")
      : setOgPlaceholder("artist");
  }, [newDisabled]);

  const [startButton, setStartButton] = useState("first");
  useEffect(() => {
    if (pages?.length === 0) {
      setStartButton("first");
    } else {
      setStartButton("second");
    }
  }, [pages?.length]);

  const [createValue, setCreateValue] = useState("Create Your First Page");
  const [theIcon, setTheIcon] = useState(<VscDebugStart />);

  const [checkSubscriptionProp, setcheckSubscriptionProp] = useState(false);
  const [proRole, setProRole] = useState(false);
  const [proPlusRole, setProPlusRole] = useState(false);

  async function getCustomClaimRole() {
    await fb.auth.currentUser.getIdToken(true);
    const decodedToken = await fb.auth.currentUser.getIdTokenResult();
    return decodedToken.claims.stripeRole;
  }

  useEffect(() => {
    getCustomClaimRole().then((res) => {
      if (res === "premium") {
        setcheckSubscriptionProp(true);
        setProRole(true);
      }
      if (res === "premiumPlus") {
        setcheckSubscriptionProp(true);
        setProPlusRole(true);
      }
    });
  }, []);

  useEffect(() => {
    if (pages?.length > 0) {
      setCreateValue("Create New Page");
      setTheIcon(<HiOutlineDocumentAdd style={{ marginTop: "3px" }} />);
    }
    if (pages?.length > 0 && checkSubscriptionProp === false) {
      setCreateValue("Go PRO to create more pages");
      setTheIcon("");
    }
    if (pages?.length > 11 && proRole === true) {
      setCreateValue("Go PRO + to create more pages");
      setTheIcon("");
    }
    if (pages?.length > 29 && proPlusRole === true) {
      setCreateValue("Maximum pages reached");
      setTheIcon("");
    }
  }, [pages?.length, checkSubscriptionProp, proRole, proPlusRole]);
  return (
    <>
      <div style={{ position: "relative", zIndex: "3" }}>
        <Group position="center" mb={10} mt={70}>
          {isMobile ? (
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              style={{ fontSize: 45, marginBottom: "0px" }}
              className={styles.gradientText10}
            >
              SoundDrop
            </div>
          ) : (
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              style={{ fontSize: 55, marginBottom: "0px" }}
              className={styles.gradientText10}
            >
              SoundDrop
            </div>
          )}
        </Group>
        <Group position="center">
          <TextInput
            mb={50}
            disabled
            value={value}
            onChange={(event) => {
              setValue(event.currentTarget.value);
              setError("");
            }}
            style={{ maxWidth: "60%" }}
            iconWidth={122}
            icon={
              <Text ml={16} mt={1}>
                SoundDrop.io/
              </Text>
            }
            rightSectionWidth={60}
            rightSection={
              <Tooltip label={"Change URL"}>
                <ActionIcon>
                  <FiEdit
                    onClick={() => {
                      setOpenedYo(true);
                    }}
                  />
                </ActionIcon>
              </Tooltip>
            }
            size="md"
            radius="xl"
            placeholder={ogPlaceholder}
          />

          <div style={{ position: "absolute", marginTop: "-5px" }}>
            <Badge color="gray" variant="outline" size="sm">
              Your Link
            </Badge>
          </div>
        </Group>

        <Container py="md" style={{ minHeight: "600px" }}>
          <Center mb={55} mt={15}>
            <Stack>
              {pages?.length === 0 && (
                <>
                  <h1
                    style={{
                      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                      fontSize: 35,
                      fontWeight: 750,
                      lineHeight: 1.1,
                      margin: 0,
                      padding: 0,
                      textAlign: "center",
                      marginBottom: 50,

                      color: theme.white,

                      [BREAKPOINT]: {
                        fontSize: 42,
                        lineHeight: 1.2,
                      },
                    }}
                  >
                    SoundDrop takes your cover art and morphs it into an
                    interactive point cloud that reacts to your track in real
                    time.
                  </h1>
                </>
              )}
              <Center>
                <Stack>
                  <div className={styles.box3}>
                    {checkSubscriptionProp !== undefined &&
                      checkSubscriptionProp === false && (
                        <Button
                          disabled={pages?.length > 0}
                          size="xl"
                          radius="sm"
                          variant="default"
                          onClick={() => navigate("/create")}
                          rightIcon={theIcon}
                        >
                          {createValue}
                        </Button>
                      )}

                    {proRole === true && (
                      <Button
                        disabled={pages?.length > 11}
                        size="xl"
                        radius="sm"
                        variant="default"
                        onClick={() => navigate("/create")}
                        rightIcon={theIcon}
                      >
                        {createValue}
                      </Button>
                    )}
                    {proPlusRole === true && (
                      <Button
                        disabled={pages?.length > 29}
                        size="xl"
                        radius="sm"
                        variant="default"
                        onClick={() => navigate("/create")}
                        rightIcon={theIcon}
                      >
                        {createValue}
                      </Button>
                    )}
                  </div>
                </Stack>
              </Center>
              {pages?.length === 0 && (
                <h3
                  style={{
                    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                    fontSize: 25,
                    fontWeight: 800,
                    lineHeight: 1.1,
                    margin: 0,
                    padding: 0,
                    textAlign: "center",
                    marginTop: 50,

                    // color: theme.white,

                    [BREAKPOINT]: {
                      fontSize: 42,
                      lineHeight: 1.2,
                    },
                  }}
                >
                  Add links to your streaming platforms and get your own URL to
                  share with fans
                </h3>
              )}
            </Stack>
          </Center>

          {pages?.length > 1 && (
            <SimpleGrid
              pb={100}
              cols={2}
              breakpoints={[{ maxWidth: "sm", cols: 1 }]}
            >
              {pages?.map((p, index, key) => {
                return (
                  <CardGridLink
                    checkSubscriptionProp={checkSubscriptionProp}
                    proRole={proRole}
                    proPlusRole={proPlusRole}
                    usernameProp={props.finalHookUser}
                    key={index}
                    page={p}
                  />
                );
              })}

              {isMobile && <TheFooter />}
            </SimpleGrid>
          )}
          {pages?.length === 1 && (
            <SimpleGrid
              pb={100}
              cols={1}
              breakpoints={[{ maxWidth: "sm", cols: 1 }]}
            >
              {pages?.map((p, index, key) => {
                return (
                  <CardGridLinkSingle
                    // authUserProp={authUser.uid}
                    checkSubscriptionProp={checkSubscriptionProp}
                    proRole={proRole}
                    proPlusRole={proPlusRole}
                    usernameProp={props.finalHookUser}
                    key={index}
                    page={p}
                  />
                );
              })}

              {isMobile && <TheFooter />}
            </SimpleGrid>
          )}
        </Container>
      </div>
      {checkSubscriptionProp === false ? (
        <Modal centered opened={openedYo} onClose={() => setOpenedYo(false)}>
          <h1
            style={{
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontSize: 42,
              fontWeight: 900,
              lineHeight: 1.1,
              margin: 0,
              padding: 0,
              textAlign: "center",

              color: theme.white,

              [BREAKPOINT]: {
                fontSize: 42,
                lineHeight: 1.2,
              },
            }}
          >
            Go PRO to change your URL
          </h1>
          <Center mt={25} pb={50}>
            <Group
              style={{
                marginTop: theme.spacing.xl * 2,

                [BREAKPOINT]: {
                  marginTop: theme.spacing.xl,
                },
              }}
              position="center"
            >
              <div className={styles.box3}>
                <Button
                  onClick={() => {
                    navigate("/pricing");
                  }}
                  style={{
                    [BREAKPOINT]: {},
                  }}
                  size="lg"
                  color="gray"
                  variant="default"
                >
                  Go PRO
                </Button>
              </div>
            </Group>
          </Center>
        </Modal>
      ) : (
        <Modal centered opened={openedYo} onClose={() => setOpenedYo(false)}>
          <Center>
            <Stack>
              <h1
                style={{
                  fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                  fontSize: 52,
                  fontWeight: 900,
                  lineHeight: 1.1,
                  margin: 0,
                  padding: 0,
                  textAlign: "center",

                  color: theme.white,

                  [BREAKPOINT]: {
                    fontSize: 42,
                    lineHeight: 1.2,
                  },
                }}
              >
                Change Your URL
              </h1>
              <Text
                style={{
                  textAlign: "center",
                  marginTop: theme.spacing.xl,
                  fontSize: 20,
                  fontWeight: 500,

                  [BREAKPOINT]: {
                    fontSize: 20,
                  },
                }}
                color="dimmed"
              >
                Send us an email at{" "}
                <span
                  onClick={() => {
                    window.open("mailto:contact@sounddrop.io");
                  }}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#046bba",
                  }}
                >
                  contact@sounddrop.io
                </span>{" "}
                to change your URL
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  marginTop: theme.spacing.xs,
                  fontSize: 16,

                  [BREAKPOINT]: {
                    fontSize: 16,
                  },
                }}
                color="dimmed"
              >
                We will get back to you within 24-48 hours. Thank you!
              </Text>
              <Group
                style={{
                  marginTop: theme.spacing.xl * 2,

                  [BREAKPOINT]: {
                    marginTop: theme.spacing.xl,
                  },
                }}
                position="center"
              >
                <div className={styles.box3}>
                  <Button
                    onClick={() => {
                      window.open("mailto:contact@sounddrop.io");
                    }}
                    style={{
                      [BREAKPOINT]: {},
                    }}
                    size="lg"
                    color="gray"
                    variant="default"
                  >
                    Send Email
                  </Button>
                </div>
              </Group>
            </Stack>
          </Center>
        </Modal>
      )}
    </>
  );
}
