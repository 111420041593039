import React from "react";
import { useEffect, useState } from "react";
import { useDownloadURL } from "react-firebase-hooks/storage";
import {
  getStorage,
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
} from "firebase/storage";
import useStore2 from "../store2";
import { fb } from "./Shared/Service";
// import useStore from "../store";

export default function AudioStart(props) {
  // const woahAudio2 = useStore2((state) => state.woahAudio2);
  const changeWoahAudio2 = useStore2((state) => state.changeWoahAudio2);
  // const woahAudio2 = useStore((state) => state.woahAudio2);
  // const changeWoahAudio2 = useStore((state) => state.changeWoahAudio2);
  // const api = useStore((state) => state.api);

  const [woahAudio, setWoahAudio] = useState();
  //   console.log(props.theId);
  //   console.log(props.pageName);
  // const { authUser } = useAuth();

  const [value, loading, error] = useDownloadURL(
    ref(
      fb.storage,
      // `users/${props.authUserProp}/${props.pageNameStore}/audio/0`
      // `users/${props.theId}/${props.pageName}/tracks/0`
      `users/${props.theUser}/${props.theTrack}/tracks/0`
    )
  );
  // if (error) {
  //   console.log(error);
  // }

  useEffect(() => {
    // console.log(props.theId);
    // console.log(value);
    if (value !== null) {
      // console.log(value);
      // console.log(value);
      // setWoahAudio(value);
      changeWoahAudio2(value);
      // console.log(value);
      // api.changeWoahAudio2(value);
      //   console.log(value);
      //   changeWoahAudio2(value);
      //   console.log(woahAudio);
    }
  });

  //   console.log(props);

  return <div></div>;
}
