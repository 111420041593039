import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useMemo } from "react";

export default function Model(props) {
  const { scene, nodes, materials } = useGLTF("/stageNEW2.glb");
  useMemo(
    () =>
      scene.traverse((obj) => {
        if (obj.material) {
          obj.material.color.set("#000000");
          obj.material.transparent = true;
          obj.material.opacity = 0.3;
        }
      }),
    [scene]
  );
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person"].geometry}
        material={materials["03_person.001"]}
        position={[0.4, 0, 5.16]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person001"].geometry}
        material={materials["03_person.001"]}
        position={[2.13, -0.04, 4.99]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person002"].geometry}
        material={materials["03_person.001"]}
        position={[3.9, -0.12, 4.76]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person003"].geometry}
        material={materials["03_person.001"]}
        position={[-2.97, 0.04, 5.28]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person004"].geometry}
        material={materials["03_person.001"]}
        position={[-1.21, -0.04, 5.05]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person005"].geometry}
        material={materials["03_person.001"]}
        position={[0.41, 0.01, 6.4]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person006"].geometry}
        material={materials["03_person.001"]}
        position={[2.14, -0.03, 6.23]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person007"].geometry}
        material={materials["03_person.001"]}
        position={[3.91, -0.11, 6]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person008"].geometry}
        material={materials["03_person.001"]}
        position={[-2.96, 0.05, 6.52]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person009"].geometry}
        material={materials["03_person.001"]}
        position={[-1.2, -0.03, 6.29]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person010"].geometry}
        material={materials["03_person.001"]}
        position={[0.45, -0.06, 7.95]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person011"].geometry}
        material={materials["03_person.001"]}
        position={[2.18, -0.1, 7.79]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person012"].geometry}
        material={materials["03_person.001"]}
        position={[3.95, -0.18, 7.55]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person013"].geometry}
        material={materials["03_person.001"]}
        position={[-2.92, -0.02, 8.08]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person014"].geometry}
        material={materials["03_person.001"]}
        position={[-1.16, -0.1, 7.84]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person015"].geometry}
        material={materials["03_person.001"]}
        position={[0.42, -0.07, 9.38]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person016"].geometry}
        material={materials["03_person.001"]}
        position={[2.15, -0.11, 9.22]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person017"].geometry}
        material={materials["03_person.001"]}
        position={[3.92, -0.19, 8.98]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person018"].geometry}
        material={materials["03_person.001"]}
        position={[-2.96, -0.03, 9.51]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person019"].geometry}
        material={materials["03_person.001"]}
        position={[-1.19, -0.11, 9.27]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person020"].geometry}
        material={materials["03_person.001"]}
        position={[0.41, -0.15, 10.88]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person021"].geometry}
        material={materials["03_person.001"]}
        position={[2.15, -0.19, 10.72]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person022"].geometry}
        material={materials["03_person.001"]}
        position={[3.91, -0.27, 10.48]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person023"].geometry}
        material={materials["03_person.001"]}
        position={[-2.96, -0.11, 11]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03_person024"].geometry}
        material={materials["03_person.001"]}
        position={[-1.19, -0.19, 10.77]}
        rotation={[3, 0, -Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person001"].geometry}
        material={materials["02_person.001"]}
        position={[-0.4, 0.02, 5.2]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person002"].geometry}
        material={materials["02_person.001"]}
        position={[1.34, -0.02, 5.03]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person003"].geometry}
        material={materials["02_person.001"]}
        position={[3.1, -0.1, 4.8]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person004"].geometry}
        material={materials["02_person.001"]}
        position={[-3.77, 0.06, 5.32]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person005"].geometry}
        material={materials["02_person.001"]}
        position={[-2, -0.02, 5.09]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person006"].geometry}
        material={materials["02_person.001"]}
        position={[-0.39, 0.03, 6.44]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person007"].geometry}
        material={materials["02_person.001"]}
        position={[1.35, -0.01, 6.28]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person008"].geometry}
        material={materials["02_person.001"]}
        position={[3.11, -0.09, 6.04]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person009"].geometry}
        material={materials["02_person.001"]}
        position={[-3.76, 0.07, 6.56]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person010"].geometry}
        material={materials["02_person.001"]}
        position={[-1.99, -0.01, 6.33]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person011"].geometry}
        material={materials["02_person.001"]}
        position={[-0.35, -0.04, 8]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person012"].geometry}
        material={materials["02_person.001"]}
        position={[1.39, -0.08, 7.83]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person013"].geometry}
        material={materials["02_person.001"]}
        position={[3.15, -0.16, 7.59]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person014"].geometry}
        material={materials["02_person.001"]}
        position={[-3.72, 0, 8.12]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person015"].geometry}
        material={materials["02_person.001"]}
        position={[-1.95, -0.08, 7.88]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person016"].geometry}
        material={materials["02_person.001"]}
        position={[-0.38, -0.05, 9.43]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person017"].geometry}
        material={materials["02_person.001"]}
        position={[1.35, -0.09, 9.26]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person018"].geometry}
        material={materials["02_person.001"]}
        position={[3.12, -0.17, 9.03]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person019"].geometry}
        material={materials["02_person.001"]}
        position={[-3.75, -0.01, 9.55]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person020"].geometry}
        material={materials["02_person.001"]}
        position={[-1.99, -0.09, 9.31]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person021"].geometry}
        material={materials["02_person.001"]}
        position={[-0.38, -0.13, 10.92]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person022"].geometry}
        material={materials["02_person.001"]}
        position={[1.35, -0.17, 10.76]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person023"].geometry}
        material={materials["02_person.001"]}
        position={[3.12, -0.25, 10.52]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person024"].geometry}
        material={materials["02_person.001"]}
        position={[-3.76, -0.09, 11.05]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["02_person025"].geometry}
        material={materials["02_person.001"]}
        position={[-1.99, -0.17, 10.81]}
        rotation={[-3.04, 0, Math.PI]}
        scale={1.57}
      />
    </group>
  );
}

useGLTF.preload("/stageNEW2.glb");
