import React from "react";

import create from "zustand";

const useStore2 = create((set) => ({
  musicWorldStore: 0,
  changeMusicWorld: (input) => set((state) => ({ musicWorldStore: input })),
  //   removeAllBears: () => set({ bears: 0 }),
  imageUrl: null,
  changeImageUrl: (input) => set((state) => ({ imageUrl: input })),
  pageId: null,
  changePageId: (input) => set((state) => ({ pageId: input })),
  pageNameStore: null,
  changePageNameStore: (input) => set((state) => ({ pageNameStore: input })),
  woahAudio2: null,
  changeWoahAudio2: (input) => set((state) => ({ woahAudio2: input })),
  storeTrack: null,
  changeStoreTrack: (input) => set((state) => ({ storeTrack: input })),
  storeUser: null,
  changeStoreUser: (input) => set((state) => ({ storeUser: input })),
  avgFreq: null,
  changeAvgFreq: (input) => set((state) => ({ avgFreq: input })),
  landingImage: "/images/sounddropLogo.png",
  changeLandingImage: (input) => set((state) => ({ landingImage: input })),
  landingBackground: "DREAM",
  changeLandingBackground: (input) =>
    set((state) => ({ landingBackground: input })),
  landingAvg: 1,
  changeLandingAvg: (input) => set((state) => ({ landingAvg: input })),
  enabledAudio: false,
  changeEnabledAudio: (input) => set((state) => ({ enabledAudio: input })),
  // liveNav: null,
  // changeLiveNav: (input) => set((state) => ({ liveNav: input })),

  // pageNameStore: null,
  // changePageNameStore: (input) => set((state) => ({ pageNameStore: input })),
}));

export default useStore2;
