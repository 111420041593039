import { fb } from "../Shared/Service";
import { useEffect, useState } from "react";

export const useAuth = () => {
  const [isAuthed, setIsAuthed] = useState();
  const [authUser, setAuthUser] = useState();

  useEffect(() => {
    const unsubscribe = fb.auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthed(true);
        setAuthUser(user);
      } else {
        setIsAuthed(false);
        setAuthUser(null);
      }
    });

    return unsubscribe;
  }, []);
  return {
    isAuthed,
    authUser,
  };
};
