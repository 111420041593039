import React from "react";
import ReactDOM from "react-dom/client";
import "./styles.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

function Overlay2() {
  return (
    <>
      <React.StrictMode>
        <Router>
          <App />
        </Router>
      </React.StrictMode>
    </>
  );
}

root.render(
  <>
    <Overlay2 />
  </>
);
