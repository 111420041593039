import {
  createStyles,
  Card,
  Image,
  Text,
  AspectRatio,
  Group,
  CopyButton,
  ActionIcon,
  Tooltip,
  Button,
  Modal,
  Center,
  Switch,
  Menu,
  Select,
  ScrollArea,
  Stack,
  Table,
  TextInput,
  CloseButton,
  Badge,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { BiArrowBack } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import { IconCheck } from "@tabler/icons";
import { FiEdit } from "react-icons/fi";
import { RiLiveLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { fb } from "../Firebase/Shared/Service";
import React, { useEffect, useState, forwardRef } from "react";
import { BiCheck } from "react-icons/bi";
import { doc, updateDoc } from "firebase/firestore";
import { IconExternalLink } from "@tabler/icons";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { TiDelete, TiDeleteOutline } from "react-icons/ti";
import { FiClipboard } from "react-icons/fi";
import EditCanvas from "../EditCanvas";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import { BsSpotify } from "react-icons/bs";
import { SiApplemusic } from "react-icons/si";
import { SiTidal } from "react-icons/si";
import { AiFillYoutube } from "react-icons/ai";
import { RiSoundcloudFill } from "react-icons/ri";
import { TiCancel } from "react-icons/ti";

const useStyles = createStyles((theme) => ({
  edit: {
    color: theme.colors.gray[0],
    transition: "transform 150ms ease, box-shadow 150ms ease",
    "&:hover": {
      transform: "scale(1.5)",
      boxShadow: theme.shadows.md,
    },
  },
  delete: {
    color: theme.colors.red,
    transition: "transform 150ms ease, box-shadow 150ms ease",
    "&:hover": {
      transform: "scale(1.2)",
      boxShadow: theme.shadows.md,
    },
  },

  card: {
    transition: "transform 150ms ease, box-shadow 150ms ease",
    cursor: "pointer",
    background: "rgb(0 0 0 / 25%)",
    backdropFilter: "blur(200px)",
    webkitBackdropFilter: "blur(200px)",

    boxShadow: "rgb(0 0 0 / 15%) 0px 12px 17px 12px",

    "&:hover": {
      transform: "scale(1.03)",
      boxShadow: theme.shadows.md,
    },
  },
  image: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 600,
    transition: "transform 150ms ease, box-shadow 150ms ease",

    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: theme.shadows.md,
    },
  },
  copy: {
    color: "red",
    transition: "transform 150ms ease, box-shadow 150ms ease",
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 600,
  },
  rowSelected: {},
}));

export default function CardGridLink({ page, usernameProp }) {
  const [checkSubscriptionProp, setcheckSubscriptionProp] = useState(false);
  const [proRole, setProRole] = useState(false);
  const [proPlusRole, setProPlusRole] = useState(false);

  async function getCustomClaimRole() {
    await fb.auth.currentUser.getIdToken(true);
    const decodedToken = await fb.auth.currentUser.getIdTokenResult();
    return decodedToken.claims.stripeRole;
  }

  useEffect(() => {
    getCustomClaimRole().then((res) => {
      if (res === "premium") {
        setcheckSubscriptionProp(true);
        setProRole(true);
      }
      if (res === "premiumPlus") {
        setcheckSubscriptionProp(true);
        setProPlusRole(true);
      }
    });
  }, []);
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      links: page.links,
    },
  });
  const SelectItem = forwardRef(({ label, iconName, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        {label === "None" && <TiCancel width={18} height={18} />}
        {label === "Spotify" && <BsSpotify width={18} height={18} />}
        {label === "Apple Music" && <SiApplemusic width={18} height={18} />}
        {label === "Tidal" && <SiTidal width={18} height={18} />}
        {label === "YouTube" && <AiFillYoutube width={18} height={18} />}
        {label === "SoundCloud" && <RiSoundcloudFill width={18} height={18} />}

        <div>
          <Text size="sm">{label}</Text>
        </div>
      </Group>
    </div>
  ));
  const rows = form.values.links.map((item, index) => {
    let woahThere;
    if (item.icon === "None") {
      woahThere = <TiCancel width={18} height={18} />;
    }
    if (item.icon === "Spotify") {
      woahThere = <BsSpotify width={18} height={18} />;
    }
    if (item.icon === "Apple Music") {
      woahThere = <SiApplemusic width={18} height={18} />;
    }
    if (item.icon === "Tidal") {
      woahThere = <SiTidal width={18} height={18} />;
    }
    if (item.icon === "YouTube") {
      woahThere = <AiFillYoutube width={18} height={18} />;
    }
    if (item.icon === "SoundCloud") {
      woahThere = <RiSoundcloudFill width={18} height={18} />;
    }
    return (
      <tr key={index} className={classes.rowSelected}>
        <td>
          <Select
            itemComponent={SelectItem}
            size="md"
            icon={woahThere}
            style={{ maxWidth: 200 }}
            dropdownPosition="right"
            placeholder="Icon"
            {...form.getInputProps(`links.${index}.icon`)}
            data={[
              { value: "None", label: "None" },
              { value: "Spotify", label: "Spotify" },
              { value: "Apple Music", label: "Apple Music" },
              { value: "Tidal", label: "Tidal" },
              { value: "YouTube", label: "YouTube" },
              { value: "SoundCloud", label: "SoundCloud" },
            ]}
          />
        </td>
        {index % 2 === 0 ? (
          <>
            <td>
              <TextInput
                placeholder="e.g. Spotify"
                {...form.getInputProps(`links.${index}.name`)}
                size="md"
              />
            </td>
            <td>
              <TextInput
                placeholder="https://open.spotify.com/track/..."
                {...form.getInputProps(`links.${index}.url`)}
                size="md"
              />
            </td>
            <td>
              <TiDelete
                style={{ cursor: "pointer" }}
                onClick={() => form.removeListItem("links", index)}
                size={20}
              />
            </td>
          </>
        ) : (
          <>
            <td>
              <TextInput
                placeholder="e.g. Apple Music"
                {...form.getInputProps(`links.${index}.name`)}
              />
            </td>
            <td>
              <TextInput
                placeholder="https://music.apple.com/..."
                {...form.getInputProps(`links.${index}.url`)}
              />
            </td>
            <td>
              <TiDelete
                style={{ cursor: "pointer" }}
                onClick={() => form.removeListItem("links", index)}
                size={20}
              />
            </td>
          </>
        )}
      </tr>
    );
  });

  const modifiedPageName = page.name.replace(/ /g, "-");
  const storage = getStorage();

  const livePage = (event) => {
    event.stopPropagation();
    if (isMobile) {
      alert(
        "Live mode is not available on mobile. Please use a desktop browser."
      );
    } else {
      window.open(`/live/${usernameProp}-${modifiedPageName}`);
    }
  };
  const viewPage = () => {
    window.open(`/${usernameProp}-${modifiedPageName}`);
  };
  const editPage = (event) => {
    event.stopPropagation();
    if (isMobile) {
      alert(
        "Editing is not available on mobile devices. Please use a desktop browser to edit your page."
      );
    } else {
      setOpened(true);
    }
  };
  const deletePage = (event) => {
    let lowercasePage = page.name.toLowerCase();
    event.stopPropagation();
    const imageRef = ref(
      storage,
      `users/${usernameProp}/${lowercasePage}/images/0`
    );
    const audioRef = ref(
      storage,
      `users/${usernameProp}/${page.name}/tracks/0`
    );

    if (window.confirm("Are you sure you want to delete " + page.name + "?")) {
      fb.firestore.collection(usernameProp).doc(page.name).delete();
      deleteObject(imageRef)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
      deleteObject(audioRef)
        .then(() => {
          window.location.reload();
        })

        .catch((error) => {
          console.log(error);
        });
    }
  };

  const [value] = useDownloadURL(
    ref(
      fb.storage,

      `users/${usernameProp}/${page.name}/images/0`
    )
  );

  let lowerUser = usernameProp.toLowerCase();
  let lowerPage = page.name.toLowerCase().replace(/ /g, "-");
  const [opened, setOpened] = useState(false);
  const [positionDecider, setPositionDecider] = useState("absolute");
  const [marginY, setMarginY] = useState("md");
  const [backgroundSize, setBackgroundSize] = useState("md");
  const [effectsMarginDecider, setEffectsMarginDecider] = useState("87px");
  const [bgPositionDecider, setBgPositionDecider] = useState("absolute");
  const [bgMarginLeftDecider, setBgMarginLeftDecider] = useState("975px");
  const [bgMarginTopDecider, setBgMarginTopDecider] = useState("87px");
  const [paletteHeightDecider, setPaletteHeightDecider] = useState(500);
  const [labelArray, setLabelArray] = useState([]);
  const [paddingTop, setPaddingTop] = useState(0);
  const [canvasDecider, setCanvasDecider] = useState(800);
  const [navDecider, setNavDecider] = useState("lg");
  const [previewWidth, setPreviewWidth] = useState("53%");
  const [orientationDecider, setOrientationDecider] = useState("vertical");
  const [spacingDecider, setSpacingDecider] = useState("lg");
  const [effectsSize, setEffectsSize] = useState("md");
  const [effectsArray, setEffectsArray] = useState(page.effects);
  const [bgValue, setBgValue] = useState(page.background);
  const [finalPalette, setFinalPalette] = useState(page.palette);

  const hundredColors = require("./hundredColors.json");
  const tenColors = require("./tenColors.json");
  const fiftyColors = require("./fiftyColors.json");
  const [paletteHider, setPaletteHider] = useState(false);
  const [pa, setPa] = useState(tenColors);

  let ind = Math.floor(Math.random() * 100);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  useEffect(() => {
    if (!isMobile) {
      setPaletteHeightDecider(400);
      setBgMarginLeftDecider("0px");
      setBgMarginTopDecider("16px");
      setBgPositionDecider("absolute");
      setEffectsMarginDecider("0px");
      setBackgroundSize("md");
      setMarginY(0);
      setPreviewWidth("100%");
      setNavDecider("xs");
      setCanvasDecider(800);
      setLabelArray(["", "", ""]);
      setOrientationDecider("horizontal");
      setSpacingDecider("xs");
      setEffectsSize("sm");
      setPositionDecider("");
      setPaddingTop(0);
    }
    if (isMobile) {
      setPaletteHeightDecider(250);
      setBgMarginLeftDecider("0px");
      setBgMarginTopDecider("16px");
      setBgPositionDecider("absolute");
      setEffectsMarginDecider("0px");
      setBackgroundSize("md");
      setMarginY(0);
      setPreviewWidth("100%");
      setNavDecider("xs");
      setCanvasDecider(400);
      setLabelArray(["", "", ""]);
      setOrientationDecider("horizontal");
      setSpacingDecider("xs");
      setEffectsSize("sm");
      setPositionDecider("");
      setPaddingTop(0);
    }
  }, [isMobile]);
  const [loadingYo, setLoadingYo] = useState(false);

  const updateLink = async () => {
    if (!finalPalette) {
      setFinalPalette("");
    }
    if (!effectsArray) {
      setEffectsArray([]);
    }
    if (!bgValue) {
      setBgValue("");
    }

    await updateDoc(doc(fb.firestore, page.userName, page.name), {
      links: form.values.links,
      effects: effectsArray,
      background: bgValue,
      palette: finalPalette,
    }).then(() => {
      setOpened(false);
      setLoadingYo(false);
    });
  };
  useEffect(() => {
    if (checkSubscriptionProp === false) {
      setPa(tenColors);
    }
    if (proRole === true) {
      setPa(fiftyColors);
    }
    if (proPlusRole === true) {
      setPa(hundredColors);
    }
  }, [checkSubscriptionProp, proRole, proPlusRole]);

  const data5 = pa.map((item, index) => (
    <Group spacing={0} position="center" m={25}>
      <Button
        color="gray"
        onClick={() => {
          setFinalPalette(index);
        }}
      >
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[0],
          }}
        ></div>
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[1],
          }}
        ></div>
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[2],
          }}
        ></div>
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[3],
          }}
        ></div>
        <div
          style={{
            width: "20px",
            height: "15px",
            backgroundColor: item[4],
          }}
        ></div>
      </Button>
    </Group>
  ));
  data5.shift();

  return (
    <>
      <Modal
        withCloseButton={false}
        lockScroll={false}
        radius="lg"
        transition="fade"
        transitionDuration={700}
        transitionTimingFunction="ease"
        overlayOpacity={0.4}
        overlayBlur={2}
        size={900}
        centered
        opened={opened}
        onClose={() => {
          setOpened(false);
          setEffectsArray(page.effects);
          setBgValue(page.background);
          setFinalPalette(page.palette);
        }}
      >
        <Stack align="center" spacing="xs">
          <Stack align="center" spacing={0}>
            <CloseButton
              style={{ marginLeft: "830px" }}
              onClick={() => {
                setOpened(false);
                setEffectsArray(page.effects);
                setBgValue(page.background);
                setFinalPalette(page.palette);
              }}
            />
            <h3>{page.name}</h3>
          </Stack>
          <h6>{page.artist}</h6>
        </Stack>
        <div
          style={{
            position: positionDecider,
            zIndex: "1",
            marginTop: effectsMarginDecider,
            width: previewWidth,
          }}
        >
          <Switch.Group
            orientation={orientationDecider}
            label="Filters"
            spacing={spacingDecider}
            offset={spacingDecider}
            size={effectsSize}
            value={effectsArray}
            onChange={setEffectsArray}
          >
            <Switch
              value="PHANTOM"
              label="PHANTOM"
              disabled={effectsArray.includes("NONE")}
            />
            <Switch
              value="PRISMA"
              label="PRISMA"
              disabled={effectsArray.includes("NONE")}
            />

            <Switch
              value="NOIR"
              label="NOIR"
              onLabel={"PRO"}
              offLabel={"PRO"}
              disabled={checkSubscriptionProp === false}
            />
            <Switch
              value="RGB"
              label="RGB"
              onLabel={"PRO"}
              offLabel={"PRO"}
              disabled={checkSubscriptionProp === false}
            />
            <Switch value="NONE" label="NONE" />
          </Switch.Group>
        </div>
        <div
          style={{
            position: "absolute",
            zIndex: "1",
            marginTop: "16px",
            marginLeft: "650px",
            width: "100%",
            maxWidth: "200px",
          }}
        >
          <Select
            label="Background"
            placeholder="DEFAULT"
            size={backgroundSize}
            value={bgValue}
            onChange={setBgValue}
            data={[
              { value: "default", label: "DEFAULT" },
              { value: "dream", label: "DREAM" },
              { value: "astra", label: "ASTRA" },
              {
                value: "amethyst",
                label: "AMETHYST (PRO)",
                disabled: !checkSubscriptionProp,
              },
            ]}
          />
          {bgValue === "dream" && checkSubscriptionProp === false && (
            <>
              <div style={{ cursor: "pointer", marginTop: 10 }}>
                <Badge
                  onClick={() =>
                    window.open("https://www.sounddrop.io/pricing")
                  }
                  mt={10}
                  color="gray"
                  variant="outline"
                >
                  Go PRO for more palettes
                </Badge>
              </div>
              <Menu mt={15} mb={15}>
                <Menu.Target>
                  <Button
                    size={backgroundSize}
                    rightIcon={
                      paletteHider ? (
                        <RiArrowDropDownLine size={20} />
                      ) : (
                        <RiArrowDropUpLine size={20} />
                      )
                    }
                    color="gray"
                    onClick={() => {
                      setPaletteHider(!paletteHider);
                    }}
                  >
                    Select Palette
                  </Button>
                </Menu.Target>
                <ScrollArea
                  hidden={paletteHider}
                  // mt={20}
                  style={{ height: paletteHeightDecider, width: 200 }}
                  type="always"
                >
                  {data5}
                </ScrollArea>
              </Menu>
            </>
          )}
          {bgValue === "dream" && checkSubscriptionProp === true && (
            <>
              <Menu mt={15} mb={15}>
                <Menu.Target>
                  <Button
                    size={backgroundSize}
                    rightIcon={
                      paletteHider ? (
                        <RiArrowDropDownLine size={20} />
                      ) : (
                        <RiArrowDropUpLine size={20} />
                      )
                    }
                    color="gray"
                    onClick={() => {
                      setPaletteHider(!paletteHider);
                    }}
                  >
                    Select Palette
                  </Button>
                </Menu.Target>
                <ScrollArea
                  hidden={paletteHider}
                  style={{ height: paletteHeightDecider, width: 200 }}
                  type="always"
                >
                  {data5}
                </ScrollArea>
              </Menu>
            </>
          )}
        </div>

        <EditCanvas
          artist={page.artist}
          track={page.name}
          image={value}
          background={bgValue}
          effects={effectsArray}
          palette={finalPalette}
        />

        <div style={{ width: "600px" }}>
          <Group position="center" sx={{ padding: 10 }}>
            {form.values.links?.map((l, index) => {
              let woahThere;

              if (l.icon === "Spotify") {
                woahThere = <BsSpotify width={18} height={18} />;
              }
              if (l.icon === "Apple Music") {
                woahThere = <SiApplemusic width={18} height={18} />;
              }
              if (l.icon === "Tidal") {
                woahThere = <SiTidal width={18} height={18} />;
              }
              if (l.icon === "YouTube") {
                woahThere = <AiFillYoutube width={18} height={18} />;
              }
              if (l.icon === "SoundCloud") {
                woahThere = <RiSoundcloudFill width={18} height={18} />;
              }
              let theUrl = l.url;
              if (!theUrl.includes("https://")) {
                theUrl = "https://" + theUrl;
              }
              return (
                <>
                  <div
                    onClick={() => window.open(theUrl)}
                    key={index}
                    style={{ marginTop: "20px" }}
                  >
                    {l.name !== "" && (
                      <Button
                        size="xs"
                        leftIcon={woahThere}
                        variant="default"
                        color="gray"
                        onClick={() => window.open(theUrl)}
                        styles={(theme) => ({
                          root: {
                            height: "27.5px",
                          },
                        })}
                      >
                        {l.name}
                      </Button>
                    )}
                  </div>
                </>
              );
            })}
          </Group>
          <Group spacing={337}>
            <Button
              variant="light"
              color="red"
              style={{
                marginTop: "10px",
              }}
              onClick={() => {
                setOpened(false);
                setEffectsArray(page.effects);
                setBgValue(page.background);
                setFinalPalette(page.palette);
              }}
            >
              <BiArrowBack style={{ marginRight: "8px", marginTop: "1px" }} />
              Cancel
            </Button>
            <Button
              style={{
                marginTop: "10px",
              }}
              onClick={() => {
                updateLink();
                setLoadingYo(true);
              }}
              loading={loadingYo}
              variant="light"
              color="green"
            >
              Submit
              <BiCheck style={{ marginLeft: "8px", marginTop: "1px" }} />
            </Button>
          </Group>
        </div>
        <ScrollArea scrollbarSize={6}>
          <Table mt={7} sx={{ minWidth: 374 }} verticalSpacing="md">
            <thead>
              <tr>
                <th>Icon</th>
                <th>
                  <Group spacing={4}>Link Label</Group>
                </th>
                <th>Link URL</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
          <Group position="center" mt="xl" pb={30} mb={40}>
            <Button
              onClick={() =>
                form.insertListItem("links", {
                  icon: "",
                  name: "",
                  url: "",
                })
              }
            >
              Add Link
              <AiOutlinePlus style={{ marginLeft: "5" }} size={11} />
            </Button>
          </Group>
        </ScrollArea>
      </Modal>

      <Center>
        <Card
          sx={{ width: "450px" }}
          key={page.name}
          p="md"
          radius="md"
          component="a"
          className={classes.card}
          onClick={viewPage}
        >
          <Center>
            <Button.Group mb="xs">
              <Button size="xs" variant="default" onClick={livePage}>
                Live Mode
                <RiLiveLine
                  style={{ marginLeft: "10px", marginTop: "1px" }}
                  size="15"
                  color="teal"
                />
              </Button>
              <Button size="xs" variant="default" onClick={editPage}>
                Edit
                <FiEdit
                  style={{ marginLeft: "10px", marginTop: "1px" }}
                  color="#0E86D4"
                  size="15"
                />
              </Button>
              <Button size="xs" variant="default" onClick={deletePage}>
                {" "}
                Delete
                <TiDeleteOutline
                  style={{ marginLeft: "5px", marginTop: "1px" }}
                  size="20"
                  color="red"
                  onClick={deletePage}
                />
              </Button>
            </Button.Group>
          </Center>

          <AspectRatio ratio={1400 / 1080}>
            <Image radius="md" src={value} />
          </AspectRatio>
          <Text
            color="dimmed"
            size="xs"
            transform="uppercase"
            weight={700}
            mt="md"
          >
            {page.artist}
          </Text>

          <Group position="apart">
            <Group spacing={8}>
              <Text className={classes.title} mt={5}>
                {page.name}
              </Text>
              <IconExternalLink size={15} style={{ marginTop: "6px" }} />
            </Group>
            {lowerUser && (
              <div onMouseDown={(e) => e.stopPropagation()}>
                <Group position="right" mt={6}>
                  <Text size={15} color={"#A5D8FF"}>
                    sounddrop.io/{lowerUser}-{lowerPage}
                  </Text>

                  <CopyButton
                    value={`sounddrop.io/${lowerUser}-${lowerPage}`}
                    timeout={2000}
                  >
                    {({ copied, copy }) => (
                      <Tooltip
                        color={"blue"}
                        label={copied ? "Copied" : "Copy URL"}
                        withArrow
                      >
                        <ActionIcon
                          color={copied ? "white" : "white"}
                          onClick={(event) => {
                            event.stopPropagation();
                            copy();
                          }}
                        >
                          {copied ? (
                            <IconCheck size={18} />
                          ) : (
                            <FiClipboard size={18} />
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Group>
              </div>
            )}
          </Group>
        </Card>
      </Center>
    </>
  );
}
