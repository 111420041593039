import * as React from "react";
import * as THREE from "three";
import useStore2 from "./store2";

import { useControls } from "leva";
import gsap from "gsap";
import { suspend } from "suspend-react";
import { useEffect, useRef, useState } from "react";

import { Point, Points, shaderMaterial, Stars } from "@react-three/drei";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { browserName } from "react-device-detect";

import * as buffer from "maath/buffer";
import * as misc from "maath/misc";
import glsl from "babel-plugin-glsl/macro";

const rotationAxis = new THREE.Vector3(0, 1, 0).normalize();
const q = new THREE.Quaternion();

const hundredColors = require("./Mantine/hundredColors.json");

// var colors = require("nice-color-palettes");
// let ind = Math.floor(Math.random() * colors.length);
// let pallete = colors[ind];
// console.log(pallete);
// pallete = pallete.map((color) => new THREE.Color(color));
let firstPalette = hundredColors[1];
firstPalette = firstPalette.map((color) => new THREE.Color(color));

const WaveShaderMaterial = shaderMaterial(
  {
    uFrequency: { value: 0 },
    uAmplitude: { value: 0 },
    uDensity: { value: 0 },
    uStrength: { value: 0 },
    uDeepPurple: { value: 0 },
    uOpacity: { value: 1 },
  },
  glsl`
  #pragma glslify: pnoise = require(glsl-noise/periodic/3d)
  #pragma glslify: rotateY = require(glsl-rotate/rotateY)
  
  uniform float uFrequency;
  uniform float uAmplitude;
  uniform float uDensity;
  uniform float uStrength;
  
  varying float vDistortion;
  
  void main() {  
    float distortion = pnoise(normal * uDensity, vec3(10.)) * uStrength;
  
    vec3 pos = position + (normal * distortion);
    float angle =  sin(uv.y * uFrequency) * uAmplitude;
    // pos = rotateY(pos, angle);    
  
    vDistortion = distortion;
  
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
  }
  `,
  glsl`
  uniform float uOpacity;
uniform float uDeepPurple;

varying float vDistortion;

vec3 cosPalette(float t, vec3 a, vec3 b, vec3 c, vec3 d) {
  return a + b * cos(6.28318 * (c * t + d));
}     

void main() {
  float distort = vDistortion * 3.;

  vec3 brightness = vec3(.1, .1, .9);
  vec3 contrast = vec3(.3, .3, .3);
  vec3 oscilation = vec3(.5, .5, .9);
  vec3 phase = vec3(.9, .1, .8);

  vec3 color = cosPalette(distort, brightness, contrast, oscilation, phase);

  gl_FragColor = vec4(color, vDistortion);
  gl_FragColor += vec4(min(uDeepPurple, 1.), 0., .5, min(uOpacity, 1.));
}
  `
);

extend({ WaveShaderMaterial });

const GradientMaterial = shaderMaterial(
  {
    time: 0,
    uColor: firstPalette,
    resolution: new THREE.Vector4(),
    // u: 1,
    // amplitude: 0.5,
  },
  /* glsl */ `
  uniform float time;
  // varying vec2 vUv;
  varying vec3 vNormal;
  varying vec3 vPosition;
  uniform vec3 uColor[5];
  varying vec3 vColor;
  uniform vec2 pixels;
  float PI = 3.141592653589793238;
  //	Simplex 3D Noise 
  //	by Ian McEwan, Ashima Arts
  //
  vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
  vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
  
  float snoise(vec3 v){ 
    const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
    const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);
  
  // First corner
    vec3 i  = floor(v + dot(v, C.yyy) );
    vec3 x0 =   v - i + dot(i, C.xxx) ;
  
  // Other corners
    vec3 g = step(x0.yzx, x0.xyz);
    vec3 l = 1.0 - g;
    vec3 i1 = min( g.xyz, l.zxy );
    vec3 i2 = max( g.xyz, l.zxy );
  
    //  x0 = x0 - 0. + 0.0 * C 
    vec3 x1 = x0 - i1 + 1.0 * C.xxx;
    vec3 x2 = x0 - i2 + 2.0 * C.xxx;
    vec3 x3 = x0 - 1. + 3.0 * C.xxx;
  
  // Permutations
    i = mod(i, 289.0 ); 
    vec4 p = permute( permute( permute( 
               i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
             + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
             + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));
  
  // Gradients
  // ( N*N points uniformly over a square, mapped onto an octahedron.)
    float n_ = 1.0/7.0; // N=7
    vec3  ns = n_ * D.wyz - D.xzx;
  
    vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)
  
    vec4 x_ = floor(j * ns.z);
    vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)
  
    vec4 x = x_ *ns.x + ns.yyyy;
    vec4 y = y_ *ns.x + ns.yyyy;
    vec4 h = 1.0 - abs(x) - abs(y);
  
    vec4 b0 = vec4( x.xy, y.xy );
    vec4 b1 = vec4( x.zw, y.zw );
  
    vec4 s0 = floor(b0)*2.0 + 1.0;
    vec4 s1 = floor(b1)*2.0 + 1.0;
    vec4 sh = -step(h, vec4(0.0));
  
    vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
    vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;
  
    vec3 p0 = vec3(a0.xy,h.x);
    vec3 p1 = vec3(a0.zw,h.y);
    vec3 p2 = vec3(a1.xy,h.z);
    vec3 p3 = vec3(a1.zw,h.w);
  
  //Normalise gradients
    vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;
  
  // Mix final noise value
    vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
    m = m * m;
    return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                  dot(p2,x2), dot(p3,x3) ) );
  }
  
  void main() {
  
  
    
  
    vec3 noiseCoord = normal;
  
    float tilt = -0.8*normal.y;
  
    float incline  = normal.x*0.1;
    // float incline  = uv.x*10.;

  
    float offset = incline*mix(-.25,0.25,normal.x);
    // float offset = incline*mix(-2.,2.,normal.y);

  
  
  
    float noise = snoise(vec3(noiseCoord.x + time*3.,noiseCoord.y, time * 10.));
  
    noise = max(0.,noise);
  
    vec3 pos = vec3(position.x,position.y,position.z + noise * 0.3 +tilt + incline + offset);
    // vec3 pos = vec3(position.x,position.y,position.z + noise * 200. +tilt + incline + offset);

  
  
  
  
    vColor = uColor[4];
  
    for(int i = 0; i < 4; i++) {
  
      float noiseFlow  = 5. + float(i)*0.3;
      float noiseSpeed  = 10. + float(i)*0.3;
  
      float noiseSeed = 1. + float(i)*10.;
      vec2 noiseFreq = vec2(1.,1.4)*.4;
  
      float noiseFloor = 0.1;
      float noiseCeil = 0.6 + float(i)*0.07;
  
  
  
      float noise = smoothstep(noiseFloor,noiseCeil,
        snoise(
          vec3(
            noiseCoord.x*noiseFreq.x + time*noiseFlow,
            noiseCoord.y*noiseFreq.y, 
            time / 2.0 * noiseSpeed + noiseSeed
          )
        )
      );
  
      vColor = mix(vColor,uColor[i],noise);
  
      
    }
  
    // vUv = uv;
    vNormal = normal;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( pos, 1.0 );
  }
  
    `,
  /* glsl */ `
  uniform float time;
  uniform float progress;
  uniform sampler2D texture1;
  uniform vec4 resolution;
  // varying vec2  vUv;
  varying vec3 vNormal;
  varying vec3 vPosition;
  varying vec3 vColor;
  float PI = 3.141592653589793238;
  void main()	{
      // vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
      gl_FragColor = vec4(vNormal,1.);
      gl_FragColor = vec4(vColor,1.0);
  }
    `
);

extend({ GradientMaterial });

const MyPointsMaterial = shaderMaterial(
  {
    u: 1,
    amplitude: 0.5,
  },
  /* glsl */ `
    attribute float size;
    attribute vec3 color;

    varying vec3 vColor;

    uniform float amplitude;

    attribute vec3 vertexColor;

    varying vec4 varColor;

    void main()
    {
    vColor = color;
    varColor = vec4(vertexColor, 1.0);

    vec4 pos = vec4(position, 1.0);
    pos.z *= amplitude;

    vec4 mvPosition = modelViewMatrix * pos;

    gl_PointSize = 1.0;
    gl_Position = projectionMatrix * mvPosition;
    }

  `,
  /* glsl */ `
    varying vec3 vColor;

    void main() {
      gl_FragColor = vec4( vColor, 1.0 );

      #include <tonemapping_fragment>
      #include <encodings_fragment>
    }
  `
);

extend({ MyPointsMaterial });

// @ts-ignore
const makeBuffer = (...args) => Float32Array.from(...args);

export default function PointsWorld5(props) {
  useEffect(() => {
    if (props.palette) {
      //   console.log(props.palette);
      //   console.log(hundredColors[0]);
      let palette = hundredColors[props.palette];
      palette = palette.map((color) => new THREE.Color(color));
      gradientRef.current.material.uniforms.uColor.value = palette;
    }
  }, [props.palette]);
  const [dreamVisible, setDreamVisible] = useState(false);
  const [astraVisible, setAstraVisible] = useState(false);
  const [amethystVisible, setAmethystVisible] = useState(false);
  useEffect(() => {
    // console.log(props.bgProp);

    if (props.bg === "default") {
      setDreamVisible(false);
      setAstraVisible(false);
      setAmethystVisible(false);
    }
    if (props.bg === "dream") {
      setDreamVisible(true);
      setAstraVisible(false);
      setAmethystVisible(false);
    }
    if (props.bg === "astra") {
      setAstraVisible(true);
      setDreamVisible(false);
      setAmethystVisible(false);
    }
    if (props.bg === "amethyst") {
      setAmethystVisible(true);
      setDreamVisible(false);
      setAstraVisible(false);
    }
  }, [props.bg]);

  var x, y;

  var weights = [0.2126, 0.7152, 0.0722];
  var c = 0;
  x = props.imageWidth * -0.5;
  y = props.imageHeight * 0.5;
  var zRange = 400;
  var vertices2 = [];

  const imgRef = React.useRef();

  const [imageSwitch, setImageSwitch] = React.useState("./rufus.jpeg");

  const { camera } = useThree();
  React.useMemo(() => {
    camera.position.set(0, 0, 450);
  }, []);

  const musicWorld = 10;

  var newerArray = [];
  var verticesFloat = [];
  var floatArray = [];
  React.useMemo(() => {
    for (var i = 0; i < props.imageHeight; i++) {
      for (var j = 0; j < props.imageWidth; j++) {
        var color6 = new THREE.Color();
        color6.setRGB(
          props.imageData[c] / 255,
          props.imageData[c + 1] / 255,
          props.imageData[c + 2] / 255
        );
        //   console.log(color6);
        //   console.log(imageData[c] / 255);
        //   console.log(imageData[c + 1] / 255);
        //   console.log(imageData[c + 2] / 255);

        // shaderAttributes.vertexColor.value.push(color);
        var newArray = color6.toArray();
        newerArray.push(newArray);

        // colors.push(color);
        // //   colors.push(color);
        // //   colors.push(color);

        var weight =
          color6.r * weights[0] + color6.g * weights[1] + color6.b * weights[2];

        var vertex = new THREE.Vector3();

        vertex.x = x;
        vertex.y = y;
        vertex.z = zRange * -0.5 + zRange * weight;
        // //   vertex.z = zRange * -0.5 + zRange;

        // // geometry.vertices.push(vertex);
        vertices2.push(vertex);

        c += 4;
        //   x = imageWidth * -0.5;

        //   console.log(x);

        x++;
      }

      x = props.imageWidth * -0.5;
      y--;
    }
    const vertices3 = [];
    for (var i = 0; i < vertices2.length; i++) {
      vertices3.push(vertices2[i].x);
      vertices3.push(vertices2[i].y);
      vertices3.push(vertices2[i].z);
    }
    verticesFloat = new Float32Array(vertices3);

    var newestArray = [];
    for (var i = 0; i < newerArray.length; i++) {
      for (var j = 0; j < 3; j++) newestArray.push(newerArray[i][j]);
    }
    floatArray = new Float32Array(newestArray);
    //   console.log(floatArray);
    // console.log(verticesFloat);
    // }, [image.src]);
  });

  const n = 3000;

  const pointsRef = React.useRef();
  const [microphoneInput, setMicrophoneInput] = useState();

  //   const getMicrophone = async () => {
  // const audio = navigator.mediaDevices.getUserMedia({
  //   audio: true,
  //   video: false,
  // });
  // setMicrophoneInput({ audio });
  //   };
  //   useEffect(() => {
  //     const audio = navigator.mediaDevices.getUserMedia({
  //       audio: true,
  //       video: false,
  //     });
  //     setMicrophoneInput({ audio });
  //   }, []);

  //   useEffect(() => {
  //     // console.log(microphoneInput);
  //     if (microphoneInput) {
  //       //   console.log(microphoneInput.audio);
  //       createAudio(microphoneInput);
  //     }
  //   }, [microphoneInput]);

  //   };

  const { gainNode, context, update, data, source, avg } = suspend(
    () => createAudio(),
    []
  );

  //   useEffect(() => {
  //     // console.log("gainNode: ", gainNode);
  //     // console.log("context: ", context.destination);
  //     // console.log();
  //     // if (!isMobile) {
  //     //   source.start(0);
  //     // }

  //     // Connect the gain node, which plays the audio
  //     gainNode.connect(context.destination);
  //     // Disconnect it on unmount
  //     return () => gainNode.disconnect();
  //   }, [gainNode, context]);

  //   const beginTrack = () => {
  //     // console.log("yo");
  //     if (
  //       context.state !== "running" &&
  //       (isMobile || browserName.includes("Safari"))
  //     ) {
  //       source.start(0);
  //     }
  //   };

  var x;
  let avgFreq;
  var y;

  let sinYo = 0;
  let cosYo = 0;
  let sin = 0;
  let cos = 0;
  const starRef = useRef();
  const codropsRef = useRef();
  const theRef = useRef();

  useFrame((state, delta) => {
    let avgPlease = update();
    x += delta / 1.5;
    y = avgPlease / 30000;

    starRef.current.rotation.y += -delta / 30 - y * 1.5;
    starRef.current.rotation.x += -delta / 30 - y * 1.5;
    // starRef.current.rotation.y += -delta / 30;
    // starRef.current.rotation.x += -delta / 30;

    const t = misc.remap(Math.sin(x), [-1, 1], [0, 1]);

    pointsRef.current.material.uniforms.amplitude.value =
      avgPlease / 52 + Math.sin(x) / 1;

    gradientRef.current.material.uniforms.time.value += y / 2 + delta / 90;

    codropsRef.current.position.z = state.camera.position.z - 10;
    codropsRef.current.position.x = state.camera.position.x;
    codropsRef.current.position.y = state.camera.position.y;
    sinYo += delta / 2;
    cosYo += delta / 2;
    sin = Math.sin(sinYo);
    cos = Math.cos(cosYo);

    theRef.current.uFrequency = avgPlease / 40 + sin;
    theRef.current.uAmplitude = avgPlease / 40 + cos;
    theRef.current.uDensity = 0.8 + avgPlease / 40 + sin;
    theRef.current.uStrength = avgPlease / 40 + cos;
    theRef.current.uDeepPurple = (avgPlease / 16) * sin * 5 - 8;
  });

  const gsapYo = () => {
    gsap.to(camera.position, {
      z: 20,
      x: -200,
      y: 300,
      duration: 6,
      ease: "power1.inOut",
    });
  };

  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  // const [mobileSet, setMobileSet] = useState(false);
  const [mobileOffset, setMobileOffset] = React.useState(0);
  React.useEffect(() => {
    // console.log(width);
    // console.log(isMobile);
    if (isMobile) {
      camera.position.set(0, 0, 520);
      setMobileOffset(30);
    } else {
      camera.position.set(0, 0, 450);
      setMobileOffset(0);
    }
  }, [isMobile]);
  const gradientRef = useRef();

  return (
    <>
      <group ref={codropsRef} visible={amethystVisible}>
        <mesh>
          <icosahedronBufferGeometry args={[200, 64]} />
          <waveShaderMaterial
            wireframe={true}
            transparent={true}
            blending={THREE.AdditiveBlending}
            ref={theRef}
            uFrequency={1}
            uAmplitude={1}
            uDensity={1}
            uStrength={1}
            uDeepPurple={1}
            uOpacity={0.2}
          />
        </mesh>
      </group>
      <group ref={starRef} visible={astraVisible}>
        <Stars
          radius={500}
          depth={50}
          count={5000}
          factor={4}
          saturation={100}
          fade
          speed={2}
        />
      </group>
      <mesh ref={gradientRef} visible={dreamVisible}>
        <sphereBufferGeometry args={[1100, 128, 128]} />
        <gradientMaterial
          side={THREE.BackSide}
          // wireframe={true}
          extensions={{
            derivatives: "#extension GL_OES_standard_derivatives : enable",
          }}
        />
      </mesh>
      <group scale={0.5} position={[0, mobileOffset, -20]}>
        <Points
          //   onClick={beginTrack}
          // limit={40000000000000000000000}
          // range={40000000000000000000000}
          // positions={positionFinal}
          positions={verticesFloat}
          // colors={color}
          colors={floatArray}
          // sizes={size}
          ref={pointsRef}
        >
          {/* @ts-ignore */}
          <myPointsMaterial />
        </Points>
      </group>
    </>
  );
}

async function createAudio(userInput, { threshold, expire } = {}) {
  //   console.log(userInput);
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false,
  });
  //   const res = await fetch(url);
  //   const buffer = await res.arrayBuffer();
  const context = new (window.AudioContext || window.webkitAudioContext)();
  const analyser = context.createAnalyser();
  analyser.smoothingTimeConstant = 0.9;
  analyser.fftSize = 2048;
  const data = new Uint8Array(analyser.frequencyBinCount);
  const source = context.createMediaStreamSource(stream);

  // metronome start
  let notesInQueue = []; // notes that have been put into the web audio and may or may not have been played yet {note, time}
  let currentBeatInBar = 0;
  let beatsPerBar = 4;
  let tempo = 121;
  let lookahead = 25; // How frequently to call scheduling function (in milliseconds)
  let scheduleAheadTime = 0.1; // How far ahead to schedule audio (sec)
  let nextNoteTime = 0.0; // when the next note is due
  let isRunning = false;
  let intervalID = null;
  // let test = 0;
  let test = 0;
  let starter = 0;

  //   source.buffer = await new Promise((res) =>
  //     context.decodeAudioData(buffer, res)
  //   );

  source.loop = true;
  const gainNode = context.createGain();
  gainNode.gain.value = 0;
  gainNode.connect(context.destination);
  source.connect(analyser);
  analyser.connect(gainNode);

  isRunning = true;

  currentBeatInBar = 0;
  nextNoteTime = context.currentTime + 0.05;

  // intervalID = setInterval(() => scheduler(), lookahead);

  let time = Date.now();
  let state = {
    context,
    source,
    data,
    gainNode,
    gain: 1,
    signal: false,
    avg: 0,
    avgFreq: 0,
    avgGainNormalized: 0,
    avgFreqNormalized: 0,
    avgRounded: 0,
    tempoTrigger: 0,
    low: 0,
    medium: 0,
    high: 0,
    finalAvg: 0,

    update: () => {
      // test = 0;
      // console.log(test);
      // console.log(isRunning);
      state.tempoTrigger = test;
      // console.log(tempoTrigger);
      let now = Date.now();
      let value = 0;
      let value2 = 0;
      let value3 = 0;
      let lowBand = 0;
      let mediumBand = 0;
      let highBand = 0;
      // let start = context.currentTime;
      // let startRounded = start.toFixed(1);
      // let tempoRaw = 130 / 60;
      // let tempoRounded = tempoRaw.toFixed(1);
      // if (startRounded === tempoRounded) {
      //   console.log("beat");
      //   startRounded = startRounded - startRounded;
      // }
      // if (startRounded % tempoRounded === 0) {
      //   console.log("beat");
      // }
      // console.log(startRounded % tempoRounded);
      // console.log(startRounded);

      analyser.getByteFrequencyData(data);
      // console.log(data);
      // console.log(context.currentTime);
      // console.log(context.startedAt);
      // if (context.currentTime)
      for (let i = 0; i < data.length; i++) {
        value += data[i];
        value2 += (i + 1) * data[i];
        value3 += data[i];
      }
      for (let i = 0; i < 30; i++) {
        lowBand += data[i];
      }
      for (let i = 30; i < 650; i++) {
        mediumBand += data[i];
      }
      for (let i = 650; i < 1024; i++) {
        highBand += data[i];
      }

      const low = (state.low = lowBand / data.length);
      const medium = (state.medium = mediumBand / data.length);
      const high = (state.high = highBand / data.length);

      const avg = (state.avg = value / data.length);
      // const avgPlease = (state.avg = value / data.length);
      // return avgPlease;

      const avgRounded = (state.avgRounded = Math.round(avg * 10) / 10);
      const avgFreq = (state.avgFreq = value2 / value3);
      const avgGainNormalized = (state.avgGainNormalized =
        (10 * Math.pow(avg, 0.5)) / 160);
      // (7 * Math.pow(avg, 0.6)) / 194);
      const avgFreqNormalized = (state.avgFreqNormalized =
        (10.24 * Math.pow(avgFreq, 0.75)) / 1024);
      if (threshold && avg > threshold && now - time > expire) {
        time = Date.now();
        state.signal = true;
      } else state.signal = false;
      return value / data.length;
    },
    // setGain(level) {
    //   gainNode.gain.setValueAtTime((state.gain = level), context.currentTime);
    // },
  };

  return state;
}
