import * as THREE from "three";
import { IcosahedronBufferGeometry } from "three";
import { useRef, useState } from "react";
import { useFrame, useThree, useLoader } from "@react-three/fiber";
import { ParametricGeometry } from "three/examples/jsm/geometries/ParametricGeometry";
import { TextureLoader } from "three/src/loaders/TextureLoader";

export default function HyperbolicHelicoidZustand(props) {
  const colorMap = useLoader(
    TextureLoader,
    "/images/sounddropWordmarkBlack.png"
  );

  // const { data } = suspend(() => createAudio("/kick.mp3"), ["/kick.mp3"]);
  // const { data: data2 } = suspend(
  //   () => createAudio("/synth1.mp3"),
  //   ["/synth1.mp3"]
  // );
  // const { data: data3 } = suspend(
  //   () => createAudio("/synth2.mp3"),
  //   ["/synth2.mp3"]
  // );

  // const data2 = suspend(() => createAudio("/synth1.mp3"), ["/synth1.mp3"]);

  // const { kick, bass, synth1, synth2, vocal } = useStore(
  //   (state) => state.audio2
  // );
  let kick = 1;
  let bass = 1;
  let synth1 = 1;
  let synth2 = 1;
  let otherMusic = 1;
  let vocal = 1;
  // const musicWorld = useStore((state) => state.musicWorld);
  // const {
  //   kick: dreamingKick,
  //   bass: dreamingBass,
  //   synth1: dreamingSynth1,
  //   synth2: dreamingSynth2,
  //   otherMusic: dreamingOtherMusic,
  //   vocal: dreamingVocal,
  // } = useStore((state) => state.audio0);

  // const {
  //   kick: foxKick,
  //   bass: foxBass,
  //   synth1: foxSynth1,
  //   synth2: foxSynth2,
  //   otherMusic: foxOtherMusic,
  //   vocal: foxVocal,
  // } = useStore((state) => state.audio1);

  // const {
  //   kick: heartKick,
  //   bass: heartBass,
  //   synth1: heartSynth1,
  //   synth2: heartSynth2,
  //   otherMusic: heartOtherMusic,
  //   vocal: heartVocal,
  // } = useStore((state) => state.audio2);
  // const { total: touchTotal } = useStore((state) => state.audio3);
  // const { total: aloneTotal } = useStore((state) => state.audio4);
  // const { total: placeTotal } = useStore((state) => state.audio5);
  // const { loaded } = useStore((state) => state.loaded);

  const [click, setClick] = useState(1);

  var sinFunc = 1;
  const refYo = useRef();
  const ballRef = useRef();
  const ballRef2 = useRef();

  // 44:20
  // function Helicoid(u, v, target) {
  //   let alpha = Math.PI * 2 * (u - 0.5);
  //   let theta = Math.PI * 2 * (v - 0.5);
  //   let t = 1 * click;
  //   // let t = 5;

  //   let bottom = 1 + Math.cosh(alpha) * Math.cosh(theta);
  //   let x = (Math.sinh(alpha) * Math.cos(t * theta * click)) / bottom;
  //   let z = (Math.sinh(alpha) * Math.sin(t * theta * click)) / bottom;
  //   let y =
  //     (props.offset * (1.5 * Math.cosh(alpha) * Math.sinh(theta))) / bottom;
  //   target.set(x, y, z);
  // }

  // new
  // function Helicoid(u, v, target) {
  //   let alpha = Math.PI * 2 * (u - 0.5);
  //   let theta = Math.PI * 2 * (v - 0.5);
  //   let t = 1 * click;
  //   // if (props.decider2 === 1) {
  //   //   t = u * click * props.torque;
  //   // }
  //   // t = u * click;

  //   let bottom = 1 + Math.cosh(alpha) * Math.cosh(theta);
  //   let x = 1;
  //   // if (props.decider === 0) {
  //   x = (Math.sinh(alpha) * Math.cos(t * theta * click)) / bottom;
  //   // }
  //   // if (props.decider === 1) {
  //   //   x = (Math.sinh(theta) * Math.cos(t * theta * click)) / bottom;
  //   // }
  //   // if (props.decider2 === 1) {
  //   //   x = ((Math.cosh(theta) * Math.cos(t * theta * click)) / bottom) * 2;
  //   // }
  //   let z = (Math.sinh(alpha) * Math.sin(t * theta * click)) / bottom;

  //   let y = (1 * (1.5 * Math.cosh(alpha) * Math.sinh(theta))) / bottom;
  //   target.set(x, y, z);
  // }
  function Teardrop(u, v, target) {
    let alpha = Math.PI * 2 * (u - 0.5);
    let theta = Math.PI * 2 * (v - 0.5);
    let t = 1 * click;
    // if (props.decider2 === 1) {
    //   t = u * click * props.torque;
    // }
    // t = u * click;

    // let bottom = 1 + Math.cosh(alpha) * Math.cosh(theta);
    // let x = 1;
    // if (props.decider === 0) {
    let x = 0.5 * (1 - Math.cos(alpha)) * Math.sin(alpha) * Math.cos(theta) * 1;
    let z = Math.cos(alpha);
    let y = 0.5 * (1 - Math.cos(alpha)) * Math.sin(alpha) * Math.sin(theta) * 1;
    // let x = 0.5 * (1 - Math.cos(u)) * Math.sin(u) * Math.cos(v) * 1;
    // let z = Math.cos(u);
    // let y = 0.5 * (1 - Math.cos(u)) * Math.sin(u) * Math.sin(v) * 1;
    target.set(x, y, z);
  }
  // private Vector3 Eval(float theta, float phi)
  // {
  //     float sinT = Mathf.Sin(theta);
  //     float cosT = Mathf.Cos(theta);

  //     Vector3 p;

  //     p.x = 0.5f * (1f - cosT) * sinT * Mathf.Cos(phi) * radiusMultiplier;
  //     p.y = 0.5f * (1f - cosT) * sinT * Mathf.Sin(phi) * radiusMultiplier;
  //     p.z = cosT * heightMultiplier;

  //     return (p);
  // }

  function getMaterial() {
    let material = new THREE.MeshPhysicalMaterial({
      color: 0xffffff,
      roughness: 0,
      metalness: 0.5,
      clearcoat: 1,
      clearcoatRoughness: 0.4,

      // side: THREE.DoubleSide,

      // prism
      // clearcoat: 1,
      // clearcoatRoughness: 0,
      // transmission: 0.8,
      // thickness: 0.9,
      // roughness: 0,
      // toneMapped: false,
    });

    material.onBeforeCompile = function (shader) {
      shader.uniforms.playhead = { value: 0 };
      // shader.uniforms.music = { value: 0 };
      // shader.uniforms.musicTwo = { value: 0 };
      // shader.uniforms.musicThree = { value: 0 };

      shader.fragmentShader =
        `uniform float playhead;\n` + shader.fragmentShader;

      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <logdepthbuf_fragment>",
        `
        float diff = dot(vec3(1.),vNormal);

        // First
        vec3 a = vec3(0.5,0.5 ,0.5 );
        vec3 b = vec3(0.5,0.5,0.5);
        vec3 c = vec3(1.0,1.0,1.0 );
        vec3 d = vec3(0.00,0.10,0.20);


        // Second
        // vec3 a = vec3(0.5,0.5,0.5);
        // vec3 b = vec3(0.5,0.5,0.5);
        // vec3 c = vec3(2.0,1.0,0.0);
        // vec3 d = vec3(0.5,0.2,0.25);

        vec3 cc= a + b * cos(2.*3.141592*(c*diff+d + playhead ));


        // diffuseColor.rgb = vec3(diff,0.,0.);
        diffuseColor.rgb = cc ;

        ` + "#include <logdepthbuf_fragment>"
      );

      material.userData.shader = shader;
    };
    return material;
  }
  let materialYo = getMaterial();
  let theta3 = 0;
  let theta4 = 0 + Math.PI;
  const [vec] = useState(() => new THREE.Vector3());
  const { camera, mouse } = useThree();

  useFrame((state, delta, clock, playhead) => {
    // camera.position.lerp(
    //   vec.set(mouse.x * 100, mouse.y * 100, camera.position.z),
    //   0.025
    // );
    refYo.current.rotation.z += delta / 2.5;
    // refYo.current.rotation.x += delta / 2.5;
    // refYo.current.rotation.z += delta / 2.5;

    // refYo.current.scale.y = data.avg / 100;
    // refYo.current.rotation.y = data.avg / 60;

    // console.log(materialYo.userData);
    // console.log(geometryYo.userData);

    if (materialYo.userData.shader) {
      materialYo.userData.shader.uniforms.playhead.value += delta / 2.5;
      // materialYo.userData.shader.uniforms.playhead.value = data.avg / 150;
      // materialYo.userData.shader.uniforms.music.value = 1 - data.avg;
      // materialYo.userData.shader.uniforms.musicTwo.value = data2.avg;
      // materialYo.userData.shader.uniforms.musicThree.value = data3.avg;
      // if (musicWorld === 0) {
      //   // dreaming
      //   materialYo.userData.shader.uniforms.music.value = 1 - kick.avg;
      //   materialYo.userData.shader.uniforms.musicTwo.value = synth1.avg;
      // }
      // if (musicWorld === 1) {
      //   // fox
      //   materialYo.userData.shader.uniforms.music.value = 1 - kick.avg / 10;
      //   materialYo.userData.shader.uniforms.musicTwo.value = synth1.avg;
      //   // console.log(kick.avg / 1024);
      //   // console.log(synth1.data);
      // }
      // if (musicWorld === 2) {
      //   // fox
      //   materialYo.userData.shader.uniforms.music.value = synth2.avg / 10;
      //   materialYo.userData.shader.uniforms.musicTwo.value = -synth2.avg / 10;
      //   materialYo.userData.shader.uniforms.musicTwo.value = synth2.avg / 10;
      //   // console.log(kick.avg / 1024);
      //   // console.log(synth2.avgFreqNormalized * 5);
      // }
      // if (musicWorld === 0) {
      //   // dreaming
      //   materialYo.userData.shader.uniforms.music.value = 1 - dreamingKick.avg;
      //   materialYo.userData.shader.uniforms.musicTwo.value = dreamingSynth1.avg;
      // }
      // if (musicWorld === 1) {
      //   // fox
      //   materialYo.userData.shader.uniforms.music.value = 1 - foxKick.avg / 10;
      //   materialYo.userData.shader.uniforms.musicTwo.value = foxSynth1.avg;
      //   // console.log(kick.avg / 1024);
      //   // console.log(synth1.data);
      // }
      // if (musicWorld === 2) {
      //   // heart
      //   materialYo.userData.shader.uniforms.music.value = heartSynth2.avg / 20;
      //   materialYo.userData.shader.uniforms.musicTwo.value = heartVocal.avg;
      //   // materialYo.userData.shader.uniforms.musicTwo.value =
      //   //   heartSynth2.avg / 10;
      //   // console.log(kick.avg / 1024);
      //   // console.log(synth2.avgFreqNormalized * 5);
      // }
      // if (musicWorld === 3) {
      //   // dreaming
      //   materialYo.userData.shader.uniforms.music.value = 1 - touchTotal.high;
      //   materialYo.userData.shader.uniforms.musicTwo.value = touchTotal.high;
      // }
      // if (musicWorld === 4) {
      //   // dreaming
      //   materialYo.userData.shader.uniforms.music.value = 1 - aloneTotal.high;
      //   materialYo.userData.shader.uniforms.musicTwo.value = aloneTotal.high;
      // }
      // if (musicWorld === 5) {
      //   // dreaming
      //   materialYo.userData.shader.uniforms.music.value = 1 - placeTotal.high;
      //   materialYo.userData.shader.uniforms.musicTwo.value = placeTotal.high;
      // }
      // materialYo.userData.shader.uniforms.musicThree.value = synth2.avg;
      // console.log(materialYo.userData.shader.uniforms.musicTwo.value);
    }
    // console.log(data2.data.avg);
    // console.log(data.avg);
    // console.log(data2.avg);
    //   console.log(materialYo.userData.shader.uniforms.music.value);
    // }
    theta3 += delta / 2.5;
    theta4 += delta / 2.5;
    // console.log

    // ballRef.current.position.x = 0.5 * Math.sin(theta3);
    // ballRef.current.position.z = 0.5 * Math.cos(theta3);

    // ballRef2.current.position.x = 0.5 * Math.sin(theta4);
    // ballRef2.current.position.z = 0.5 * Math.cos(theta4);

    // sinFunc += delta;
    // console.log(Math.sin(sinFunc));
    // refYo.current.scale.x = Math.sin(sinFunc / 1.5) * 4;
    // refYo.current.scale.y = Math.cos(sinFunc / 3) * 4;
    // refYo.current.scale.z = Math.sin(sinFunc / 4) * 4;

    // test += delta / 3;
    // test2 = Math.sin(test) * 10;
    // console.log(props.test4);

    // console.log(Helicoid);

    // console.log(click);
    // while (refYo.current.scale.y <= 200) {
    // refYo.current.scale.y += delta * 4;
    // }
  });
  //   materialYo = new THREE.MeshNormalMaterial();

  // const geometryYo = new ParametricGeometry(Helicoid, 300, 300);
  const geometryYo = new ParametricGeometry(Teardrop, 300, 300);

  // const geometryBall = new IcosahedronBufferGeometry(0.26, 50);
  const geometryBall = new IcosahedronBufferGeometry(50, 50);

  return (
    <>
      <mesh
        ref={refYo}
        geometry={geometryYo}
        material={materialYo}
        rotation-x={-Math.PI / 2}
        castShadow
        scale={[1, 1, 1]}
      ></mesh>
    </>
  );
}
