import {
  createStyles,
  Tooltip,
  Group,
  Button,
  UnstyledButton,
  Text,
  ThemeIcon,
  Anchor,
  Divider,
  Burger,
  Drawer,
  Collapse,
  ScrollArea,
  Image,
  Stack,
  LoadingOverlay,
  ActionIcon,
} from "@mantine/core";
import { IconBrandTwitter, IconBrandInstagram } from "@tabler/icons";
import { useRef } from "react";
import * as THREE from "three";
import { extend } from "@react-three/fiber";
import { shaderMaterial } from "@react-three/drei";
import { httpsCallable } from "firebase/functions";
import styles from "../styles.module.css";
import { useDisclosure } from "@mantine/hooks";
import {
  IconNotification,
  IconCode,
  IconBook,
  IconChartPie3,
  IconFingerprint,
  IconCoin,
} from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Firebase/hooks";
import { fb } from "../Firebase/Shared/Service";
import { MdLogout } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { TheFooter } from "./TheFooter";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.md,
    cursor: "pointer",
    textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    "&:active": theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: -theme.spacing.md,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md}px ${theme.spacing.md * 2}px`,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
}));

const mockdata = [
  {
    icon: IconCode,
    title: "Open source",
    description: "This Pokémon’s cry is very loud and distracting",
  },
  {
    icon: IconCoin,
    title: "Free for everyone",
    description: "The fluid of Smeargle’s tail secretions changes",
  },
  {
    icon: IconBook,
    title: "Documentation",
    description: "Yanma is capable of seeing 360 degrees without",
  },
  {
    icon: IconFingerprint,
    title: "Security",
    description: "The shell’s rounded shape and the grooves on its.",
  },
  {
    icon: IconChartPie3,
    title: "Analytics",
    description: "This Pokémon uses its flying ability to quickly chase",
  },
  {
    icon: IconNotification,
    title: "Notifications",
    description: "Combusken battles with the intensely hot flames it spews",
  },
];

export default function NavBar(props) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  const location = useLocation();

  const navigate = useNavigate();

  const logout = () => {
    fb.auth
      .signOut()
      .then(() => {
        navigate("/");
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log("error occured while trying to log out", err);
      });
  };
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const { classes, theme } = useStyles();

  const links = mockdata.map((item) => (
    <UnstyledButton className={classes.subLink} key={item.title}>
      <Group noWrap align="flex-start">
        <ThemeIcon size={34} variant="default" radius="md">
          <item.icon size={22} color={theme.fn.primaryColor()} />
        </ThemeIcon>
        <div>
          <Text size="sm" weight={500}>
            {item.title}
          </Text>
          <Text size="xs" color="dimmed">
            {item.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ));
  const authUser = useAuth();
  const [liveHidden, setLiveHidden] = useState(false);
  useEffect(() => {
    if (location.pathname.includes("live")) {
      setLiveHidden(true);
    } else {
      setLiveHidden(false);
    }
  }, [location.pathname]);

  const [proButton, setProButton] = useState("Go PRO");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.checkSubscriptionProp === true) {
      setProButton("Manage Subscription");
    }
  }, [props.checkSubscriptionProp]);

  const proClick = () => {
    navigate("/pricing");
  };

  const portalNav = async () => {
    setLoading(true);

    const functionRef = httpsCallable(
      fb.functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );

    const { data } = await functionRef({
      returnUrl: window.location.origin,
      locale: "auto", // Optional, defaults to "auto"
      // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    });
    window.location.assign(data.url);
  };

  if (authUser.isAuthed) {
    return (
      <>
        {location.pathname.includes("live") || isMobile ? null : (
          <div
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              zIndex: 4,
            }}
          >
            <TheFooter />
          </div>
        )}

        {location.pathname.includes("live") ? null : (
          <>
            {" "}
            <div
              style={{
                width: "100%",
                height: "60px",
                position: "fixed",
                zIndex: "9999",
                padding: "10px",
                display: "flex",
                backdropFilter: "blur(10px)",
                webkitBackdropFilter: "blur(10px)",

                boxShadow: "0px 10px 15px 10px rgb(0 0 0 / 15%)",
                WebkitBoxShadow: "0px 10px 15px 10px rgb(0 0 0 / 15%)",
              }}
            >
              <Group position="apart" sx={{ height: "100%", width: "100%" }}>
                <Group
                  sx={{ height: "100%" }}
                  spacing={0}
                  className={classes.hiddenMobile}
                >
                  {authUser.authUser &&
                    fb.auth.currentUser.emailVerified &&
                    !location.pathname.includes("pricing") &&
                    props.checkSubscriptionProp === false && (
                      <>
                        <div className={styles.box2}>
                          {" "}
                          <Button
                            loading={loading}
                            color="gray"
                            variant="default"
                            radius="lg"
                            onClick={() => {
                              proClick();
                            }}
                          >
                            Go PRO
                          </Button>
                        </div>
                      </>
                    )}
                  {authUser.authUser &&
                    fb.auth.currentUser.emailVerified &&
                    location.pathname.includes("pricing") && (
                      <>
                        <div
                          style={{
                            boxShadow:
                              "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px     ",
                          }}
                        >
                          <Button
                            variant="subtle"
                            onClick={() => {
                              navigate("/");
                            }}
                            leftIcon={<BiArrowBack />}
                          >
                            Home
                          </Button>
                        </div>
                      </>
                    )}
                </Group>
                <div
                  style={{
                    width: "40px",
                    position: "absolute",
                    zIndex: "999999999999",
                    left: "0",
                    right: "0",
                    marginLeft: "auto",
                    marginRight: "auto",
                    cursor: "pointer",
                  }}
                >
                  <Image
                    onClick={() => {
                      navigate("/");
                    }}
                    src="/images/sounddropLogo.png"
                  />
                </div>

                {location.pathname.includes("login") ||
                location.pathname.includes("signup") ||
                location.pathname.includes("password-recovery") ? (
                  <>
                    <Group
                      position="left"
                      style={{
                        position: "absolute",
                        zIndex: 4,
                        cursor: "pointer",
                      }}
                    >
                      <Button
                        onClick={() => navigate("/")}
                        variant="subtle"
                        color="dark"
                        leftIcon={<BiArrowBack />}
                      >
                        Home
                      </Button>
                    </Group>
                  </>
                ) : (
                  <>
                    <Group className={classes.hiddenMobile}>
                      {authUser.isAuthed &&
                      fb.auth.currentUser.emailVerified ? (
                        <>
                          <Group>
                            <div
                              style={{
                                boxShadow:
                                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px     ",
                              }}
                            >
                              {props.checkSubscriptionProp ? (
                                <Button
                                  loading={loading}
                                  variant="subtle"
                                  onClick={() => {
                                    portalNav();
                                  }}
                                >
                                  Manage Subscription
                                </Button>
                              ) : (
                                <Button
                                  variant="subtle"
                                  onClick={() => {
                                    navigate("/pricing");
                                  }}
                                >
                                  Pricing
                                </Button>
                              )}
                            </div>
                            <div
                              style={{
                                boxShadow:
                                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px     ",
                              }}
                            >
                              <Tooltip label="contact@sounddrop.io" withArrow>
                                <Button
                                  onClick={() => {
                                    window.open("mailto:contact@sounddrop.io");
                                  }}
                                  variant="subtle"
                                >
                                  Contact
                                </Button>
                              </Tooltip>
                            </div>

                            <Button
                              onClick={logout}
                              variant="light"
                              color="orange"
                              sx={{
                                boxShadow:
                                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px     ",
                              }}
                            >
                              Logout
                              <MdLogout
                                size={11}
                                style={{ marginLeft: "8px", marginTop: "1px" }}
                              />
                            </Button>
                          </Group>
                        </>
                      ) : authUser.isAuthed === false ? (
                        <>
                          <Button
                            variant="default"
                            onClick={() => {
                              navigate("/login");
                            }}
                          >
                            Login
                          </Button>
                          <Button
                            onClick={() => {
                              navigate("/signup");
                            }}
                          >
                            Sign up
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </Group>
                  </>
                )}
                {authUser.isAuthed && fb.auth.currentUser.emailVerified ? (
                  <>
                    <Burger
                      style={{ minWidth: "40px", maxWidth: "40px" }}
                      opened={drawerOpened}
                      onClick={toggleDrawer}
                      className={classes.hiddenDesktop}
                    />

                    <div hidden={!isMobile}>
                      {" "}
                      {location.pathname.includes("pricing") ? (
                        <div
                          style={{
                            boxShadow:
                              "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px     ",
                          }}
                        >
                          <Button
                            variant="subtle"
                            onClick={() => {
                              navigate("/");
                            }}
                            leftIcon={<BiArrowBack />}
                          >
                            Home
                          </Button>
                        </div>
                      ) : props.checkSubscriptionProp ? (
                        <></>
                      ) : (
                        <div className={styles.box2}>
                          <Button
                            className={classes.hiddenDesktop}
                            loading={loading}
                            color="gray"
                            variant="default"
                            radius="lg"
                            onClick={() => {
                              proClick();
                            }}
                          >
                            Go PRO
                          </Button>
                        </div>
                      )}
                    </div>
                    {/* )} */}
                  </>
                ) : (
                  <>
                    {location.pathname.includes("login") ||
                    location.pathname.includes("signup") ||
                    location.pathname.includes("password-recovery") ? (
                      <></>
                    ) : (
                      <Button onClick={logout} variant="light" color="orange">
                        Logout
                        <MdLogout
                          size={11}
                          style={{ marginLeft: "8px", marginTop: "1px" }}
                        />
                      </Button>
                    )}
                  </>
                )}
              </Group>
            </div>
          </>
        )}

        {/* </Header> */}
        <Drawer
          opened={drawerOpened}
          onClose={closeDrawer}
          size="100%"
          padding="md"
          className={classes.hiddenDesktop}
          zIndex={1000000}
        >
          <div
            style={{
              fontSize: "18px",

              left: 0,
              top: 0,
              paddingLeft: "1rem",
              paddingTop: "1rem",

              position: "absolute",
            }}
            className={styles.gradientText10}
          >
            SoundDrop
          </div>
          <ScrollArea sx={{ height: "calc(100vh - 60px)" }} mx="-md">
            <Group position="center" mt={15} mb={15}>
              {props.checkSubscriptionProp ? (
                <></>
              ) : (
                <div className={styles.box2}>
                  <Button
                    loading={loading}
                    color="gray"
                    variant="default"
                    radius="lg"
                    onClick={() => {
                      proClick();
                      closeDrawer();
                    }}
                  >
                    Go PRO
                  </Button>
                </div>
              )}
            </Group>
            <Divider
              my="sm"
              color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
            />

            <Stack>
              <a
                onClick={() => {
                  navigate("/");
                  closeDrawer();
                }}
                className={classes.link}
              >
                Home
              </a>

              {props.checkSubscriptionProp ? (
                <>
                  <LoadingOverlay visible={loading} overlayBlur={2} />

                  <a
                    className={classes.link}
                    onClick={() => {
                      portalNav();
                    }}
                  >
                    Manage Subscription
                  </a>
                </>
              ) : (
                <a
                  className={classes.link}
                  onClick={() => {
                    navigate("/pricing");
                    closeDrawer();
                  }}
                >
                  Pricing
                </a>
              )}
              <a
                className={classes.link}
                onClick={() => {
                  window.open("mailto:contact@sounddrop.io");
                  closeDrawer();
                }}
              >
                Contact
              </a>
            </Stack>

            <Collapse in={linksOpened}>{links}</Collapse>

            <Divider
              my="sm"
              color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
            />
            <Group position="center" grow pb="xl" px="md">
              <>
                <Button
                  onClick={() => {
                    logout();
                    toggleDrawer();
                  }}
                  variant="light"
                  color="orange"
                >
                  Logout
                  <MdLogout
                    size={11}
                    style={{ marginLeft: "8px", marginTop: "1px" }}
                  />
                </Button>
              </>
            </Group>
            <Group position="center">
              <Anchor
                variant="light"
                color="blue.2"
                onClick={(event) => event.preventDefault()}
                size="xs"
                pr="sm"
              >
                Legal
              </Anchor>
              <ActionIcon
                onClick={() => {
                  window.open("https://twitter.com/SoundDrop_io", "_blank");
                }}
                color={"dimmed"}
                size="sm"
              >
                <IconBrandTwitter size={18} stroke={1.5} />
              </ActionIcon>

              <ActionIcon
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/sounddrop.io",
                    "_blank"
                  );
                }}
                color={"dimmed"}
                size="sm"
              >
                <IconBrandInstagram size={18} stroke={1.5} />
              </ActionIcon>
            </Group>
          </ScrollArea>
        </Drawer>
      </>
    );
  } else return <></>;
}
const hundredColors = require("./hundredColors.json");

let firstPalette = hundredColors[1];

firstPalette = firstPalette.map((color) => new THREE.Color(color));

const GradientMaterial = shaderMaterial(
  {
    time: 0,
    uColor: firstPalette,
    resolution: new THREE.Vector4(),
    opacity: 1.0,
    // u: 1,
    // amplitude: 0.5,
  },
  /* glsl */ `
  uniform float time;
  // varying vec2 vUv;
  varying vec3 vNormal;
  varying vec3 vPosition;
  uniform vec3 uColor[5];
  varying vec3 vColor;
  uniform vec2 pixels;
  float PI = 3.141592653589793238;
  //	Simplex 3D Noise 
  //	by Ian McEwan, Ashima Arts
  //
  vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
  vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
  
  float snoise(vec3 v){ 
    const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
    const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);
  
  // First corner
    vec3 i  = floor(v + dot(v, C.yyy) );
    vec3 x0 =   v - i + dot(i, C.xxx) ;
  
  // Other corners
    vec3 g = step(x0.yzx, x0.xyz);
    vec3 l = 1.0 - g;
    vec3 i1 = min( g.xyz, l.zxy );
    vec3 i2 = max( g.xyz, l.zxy );
  
    //  x0 = x0 - 0. + 0.0 * C 
    vec3 x1 = x0 - i1 + 1.0 * C.xxx;
    vec3 x2 = x0 - i2 + 2.0 * C.xxx;
    vec3 x3 = x0 - 1. + 3.0 * C.xxx;
  
  // Permutations
    i = mod(i, 289.0 ); 
    vec4 p = permute( permute( permute( 
               i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
             + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
             + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));
  
  // Gradients
  // ( N*N points uniformly over a square, mapped onto an octahedron.)
    float n_ = 1.0/7.0; // N=7
    vec3  ns = n_ * D.wyz - D.xzx;
  
    vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)
  
    vec4 x_ = floor(j * ns.z);
    vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)
  
    vec4 x = x_ *ns.x + ns.yyyy;
    vec4 y = y_ *ns.x + ns.yyyy;
    vec4 h = 1.0 - abs(x) - abs(y);
  
    vec4 b0 = vec4( x.xy, y.xy );
    vec4 b1 = vec4( x.zw, y.zw );
  
    vec4 s0 = floor(b0)*2.0 + 1.0;
    vec4 s1 = floor(b1)*2.0 + 1.0;
    vec4 sh = -step(h, vec4(0.0));
  
    vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
    vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;
  
    vec3 p0 = vec3(a0.xy,h.x);
    vec3 p1 = vec3(a0.zw,h.y);
    vec3 p2 = vec3(a1.xy,h.z);
    vec3 p3 = vec3(a1.zw,h.w);
  
  //Normalise gradients
    vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;
  
  // Mix final noise value
    vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
    m = m * m;
    return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                  dot(p2,x2), dot(p3,x3) ) );
  }
  
  void main() {
  
  
    
  
    vec3 noiseCoord = normal;
  
    float tilt = -0.8*normal.y;
  
    float incline  = normal.x*0.1;
    // float incline  = uv.x*10.;

  
    float offset = incline*mix(-.25,0.25,normal.x);
    // float offset = incline*mix(-2.,2.,normal.y);

  
  
  
    float noise = snoise(vec3(noiseCoord.x + time*3.,noiseCoord.y, time * 10.));
  
    noise = max(0.,noise);
  
    vec3 pos = vec3(position.x,position.y,position.z + noise * 0.3 +tilt + incline + offset);
    // vec3 pos = vec3(position.x,position.y,position.z + noise * 200. +tilt + incline + offset);

  
  
  
  
    vColor = uColor[4];
  
    for(int i = 0; i < 4; i++) {
  
      float noiseFlow  = 5. + float(i)*0.3;
      float noiseSpeed  = 10. + float(i)*0.3;
  
      float noiseSeed = 1. + float(i)*10.;
      vec2 noiseFreq = vec2(1.,1.4)*.4;
  
      float noiseFloor = 0.1;
      float noiseCeil = 0.6 + float(i)*0.07;
  
  
  
      float noise = smoothstep(noiseFloor,noiseCeil,
        snoise(
          vec3(
            noiseCoord.x*noiseFreq.x + time*noiseFlow,
            noiseCoord.y*noiseFreq.y, 
            time / 2.0 * noiseSpeed + noiseSeed
          )
        )
      );
  
      vColor = mix(vColor,uColor[i],noise);
  
      
    }
  
    // vUv = uv;
    vNormal = normal;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( pos, 1.0 );
  }
  
    `,
  /* glsl */ `
  uniform float time;
  uniform float progress;
  uniform sampler2D texture1;
  uniform vec4 resolution;
  uniform float opacity;
  // varying vec2  vUv;
  varying vec3 vNormal;
  varying vec3 vPosition;
  varying vec3 vColor;
  float PI = 3.141592653589793238;
  void main()	{
      // vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
      // gl_FragColor = vec4(vNormal,0.);
      gl_FragColor = vec4(vColor,1.);
      gl_FragColor.a = opacity;

  }
    `
);

extend({ GradientMaterial });
