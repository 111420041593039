import { useToggle, upperFirst } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Paper,
  Group,
  Button,
  Divider,
  Anchor,
  Stack,
  Center,
  Container,
  Title,
} from "@mantine/core";
import styles from "../styles.module.css";
import { GoogleButton } from "./SocialButtons";
import { fb } from "../Firebase/Shared/Service";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  sendEmailVerification,
} from "firebase/auth";

import { BiArrowBack } from "react-icons/bi";

export default function Login(props) {
  const auth = getAuth();

  const provider = new GoogleAuthProvider();

  const [error, setError] = useState("");

  const navigate = useNavigate();
  const [type, toggle] = useToggle(["login", "register"]);

  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      password: "",
      userName: "",
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      password: (val) =>
        val.length <= 6
          ? "Password should include at least 6 characters"
          : null,

      confirmPassword: (value, values) =>
        value !== values.password && type === "register"
          ? "Passwords did not match"
          : null,
    },
  });
  const submitForm = async (values) => {
    if (type === "login") {
      console.log(values);
      fb.auth
        .signInWithEmailAndPassword(values.email, values.password)
        .then((res) => {
          if (!res.user) {
            setError("We're having trouble logging you in. Please try again.");
          }
        })
        .then(() => {
          navigate("/dashboard");
        })
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          if (
            err.code === "auth/invalid-email" ||
            err.code === "auth/wrong-password"
          ) {
            setError("Invalid credentials");
          } else if (err.code === "auth/user-not-found") {
            setError("No account for this email");
          }
        });
      // }
    }
    if (type === "register") {
      fb.auth
        .createUserWithEmailAndPassword(values.email, values.password)

        .then(function () {
          let user = fb.auth.currentUser;
          user.sendEmailVerification();
        })
        .then(() => {
          navigate("/dashboard");
        })
        .catch((err) => {
          if (err.code === "auth/email-already-in-use") {
            setError("Email already in use, try logging in with Google");
          }
          console.log(err);
        });
    }
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // ...
      })
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  return (
    <>
      <Group
        onClick={() => navigate("/")}
        position="left"
        style={{
          position: "absolute",
          zIndex: "11",
          cursor: "pointer",
          top: "1rem",
          left: "1rem",
        }}
      >
        <Button
          onClick={() => navigate("/")}
          variant="subtle"
          color="dark"
          leftIcon={<BiArrowBack />}
        >
          Home
        </Button>
      </Group>
      <Center>
        <Container
          size={1000}
          style={{ position: "absolute", zIndex: "10", marginTop: "550px" }}
        >
          {isMobile ? (
            <>
              <Group mt={100}></Group>
              <Group hidden position="center">
                <div
                  style={{
                    fontSize: 40,
                    marginTop: "10px",
                    marginBottom: "10px",
                    paddingTop: "100px",
                    paddingBottom: "10px",
                  }}
                  className={styles.gradientText}
                >
                  SoundDrop
                </div>
              </Group>
            </>
          ) : (
            <>
              <Group position="center">
                <div
                  style={{
                    fontSize: 40,
                    marginTop: "10px",
                    marginBottom: "10px",
                    paddingTop: "100px",
                    paddingBottom: "10px",
                  }}
                  className={styles.gradientText}
                >
                  SoundDrop
                </div>
              </Group>
            </>
          )}

          <Paper
            radius="md"
            p="xl"
            withBorder
            {...props}
            style={{ backgroundColor: "transparent" }}
          >
            <Title color="white" mb={10} transform="capitalize" align="center">
              {type}
            </Title>

            <Group grow mb="md" mt="md">
              <GoogleButton radius="xl" onClick={signInWithGoogle}>
                Google
              </GoogleButton>
            </Group>

            <Divider
              label="Or continue with email"
              labelPosition="center"
              my="lg"
            />

            <form
              onSubmit={form.onSubmit((values) => {
                submitForm(values);
              })}
            >
              <Stack>
                <TextInput
                  size="md"
                  required
                  label="Email"
                  placeholder="email@address.com"
                  value={form.values.email}
                  onChange={(event) =>
                    form.setFieldValue("email", event.currentTarget.value)
                  }
                  error={form.errors.email && "Invalid email"}
                />

                <PasswordInput
                  size="md"
                  id="your-password"
                  required
                  label="Password"
                  placeholder="Your password"
                  value={form.values.password}
                  onChange={(event) =>
                    form.setFieldValue("password", event.currentTarget.value)
                  }
                  error={
                    form.errors.password &&
                    "Password must be at least 6 characters long"
                  }
                />
                {type === "login" && (
                  <Anchor
                    onClick={() => {
                      navigate("/password-recovery");
                    }}
                    sx={(theme) => ({
                      color:
                        theme.colors[theme.primaryColor][
                          theme.colorScheme === "dark" ? 4 : 6
                        ],
                      fontWeight: 500,
                      fontSize: theme.fontSizes.xs,
                    })}
                  >
                    Forgot your password?
                  </Anchor>
                )}

                {type === "register" && (
                  <>
                    <PasswordInput
                      required
                      size="md"
                      label="Confirm Password"
                      placeholder="Confirm password"
                      value={form.values.confirmPassword}
                      onChange={(event) =>
                        form.setFieldValue(
                          "confirmPassword",
                          event.currentTarget.value
                        )
                      }
                      error={
                        form.errors.confirmPassword && "Passwords do not match"
                      }
                    />
                  </>
                )}
              </Stack>

              <Group position="apart" mt="xl">
                <Anchor
                  component="button"
                  type="button"
                  color="dimmed"
                  onClick={() => {
                    toggle();
                    setError("");
                  }}
                  size="xs"
                >
                  {type === "register"
                    ? "Already have an account? Login"
                    : "Don't have an account? Register"}
                </Anchor>

                <div
                  style={{
                    boxShadow:
                      "rgba(255, 255, 255, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
                  }}
                >
                  <Button
                    onClick={submitForm}
                    color="orange"
                    type="submit"
                    variant="light"
                  >
                    {upperFirst(type)}
                  </Button>
                </div>
              </Group>
            </form>
            {error && (
              <div
                style={{
                  marginTop: "20px",
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {error}
              </div>
            )}
          </Paper>
        </Container>
      </Center>
    </>
  );
}
