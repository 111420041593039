import { fb } from "../Shared/Service";
import { useEffect, useState } from "react";

export const useUserPages = (username) => {
  const [pages, setPages] = useState();

  useEffect(() => {
    const unsubscribe = username
      ? fb.firestore
          .collection(username)
          //   .where("userId", "==", userId)
          .onSnapshot((snap) => {
            const _pages = [];
            snap.forEach((s) => {
              _pages.push({
                ...s.data(),
                id: s.id,
              });
            });
            setPages(_pages);
          })
      : undefined;
    return unsubscribe;
  }, [username]);
  return pages;
};
