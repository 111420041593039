import { Button, Group } from "@mantine/core";

import { FcGoogle } from "react-icons/fc";

export function GoogleButton(props) {
  return (
    <Button
      size="sm"
      leftIcon={<FcGoogle />}
      variant="default"
      color="gray"
      {...props}
    />
  );
}

export function SocialButtons() {
  return (
    <Group position="center" sx={{ padding: 15 }}>
      <FcGoogle />
    </Group>
  );
}
