import React, { useEffect, useRef, useState } from "react";
import { useToggle } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Text,
  Button,
  Group,
  createStyles,
  Container,
  Image,
} from "@mantine/core";
import "./stylesLanding.css";
import LandingPage2 from "./LandingPage2";
import styles from "../styles.module.css";
import useStore2 from "../store2";
import { BsMic } from "react-icons/bs";
import Segmented from "./ContentSections/Segmented";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";
import { IoMdClose } from "react-icons/io";
import { FiCheck } from "react-icons/fi";
import { ScrollToPlugin } from "gsap/all";

const BREAKPOINT = "@media (max-width: 755px)";

const useStyles = createStyles((theme) => ({
  wrapper: {
    marginTop: "100px",
    width: "100vw",

    position: "relative",
    boxSizing: "border-box",
  },
  wrapper2: {
    width: "100vw",

    position: "relative",
    boxSizing: "border-box",
  },

  inner: {
    position: "relative",
    paddingTop: 200,
    paddingBottom: 120,

    [BREAKPOINT]: {
      paddingBottom: 80,
      paddingTop: 80,
    },
  },
  inner11: {
    position: "relative",
    paddingTop: 50,
    paddingBottom: 120,

    [BREAKPOINT]: {
      paddingBottom: 80,
      paddingTop: 80,
    },
  },
  inner2: {
    position: "relative",
    paddingTop: 125,
    paddingBottom: 120,

    [BREAKPOINT]: {
      paddingBottom: 80,
      paddingTop: 80,
    },
  },
  inner9: {
    position: "relative",
    paddingTop: 100,

    [BREAKPOINT]: {
      paddingBottom: 80,
      paddingTop: 80,
    },
  },
  inner10: {
    position: "relative",

    [BREAKPOINT]: {
      paddingBottom: 80,
      paddingTop: 80,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 62,
    fontWeight: 900,
    lineHeight: 1.1,
    margin: 0,
    padding: 0,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,

    [BREAKPOINT]: {
      fontSize: 42,
      lineHeight: 1.2,
    },
  },
  title2: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 32,
    fontWeight: 750,
    lineHeight: 1.1,
    margin: 0,
    padding: 0,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,

    [BREAKPOINT]: {
      fontSize: 42,
      lineHeight: 1.2,
    },
  },
  title20: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 25,
    fontWeight: 750,
    lineHeight: 1.1,
    margin: 0,
    padding: 0,
    // color: theme.colorScheme === "dark" ? theme.white : theme.black,
    color: "dimmed",
    [BREAKPOINT]: {
      fontSize: 42,
      lineHeight: 1.2,
    },
  },
  title3: {
    textAlign: "right",
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 35,
    fontWeight: 800,
    lineHeight: 1.1,
    margin: 0,
    marginTop: "200px",
    padding: 0,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,

    [BREAKPOINT]: {
      fontSize: 42,
      lineHeight: 1.2,
    },
  },
  title4: {
    textAlign: "left",
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 35,
    fontWeight: 800,
    lineHeight: 1.1,
    margin: 0,
    marginTop: "200px",
    padding: 0,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,

    [BREAKPOINT]: {
      fontSize: 42,
      lineHeight: 1.2,
    },
  },
  title10: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 62,
    fontWeight: 900,
    lineHeight: 2,
    margin: 0,
    padding: 0,
    color: theme.colorScheme === "dark" ? theme.gray : theme.black,

    [BREAKPOINT]: {
      fontSize: 42,
      lineHeight: 1.2,
    },
  },

  description: {
    marginTop: theme.spacing.xl,
    fontSize: 24,

    [BREAKPOINT]: {
      fontSize: 18,
    },
  },
  description2: {
    textAlign: "right",
    marginTop: theme.spacing.xl,
    fontSize: 24,

    [BREAKPOINT]: {
      fontSize: 18,
    },
  },

  controls: {
    marginTop: theme.spacing.xl * 2,

    [BREAKPOINT]: {
      marginTop: theme.spacing.xl,
    },
  },
  controls2: {
    marginTop: theme.spacing.xl * 2,

    [BREAKPOINT]: {
      marginTop: theme.spacing.xl,
    },
  },

  control: {
    height: 54,
    paddingLeft: 38,
    paddingRight: 38,

    [BREAKPOINT]: {
      height: 54,
      paddingLeft: 18,
      paddingRight: 18,
      flex: 1,
    },
  },
}));

function Content() {
  const navigate = useNavigate();
  gsap.registerPlugin(ScrollToPlugin);

  const changeLandingImage = useStore2((state) => state.changeLandingImage);
  const { classes } = useStyles();

  const ref = useRef();
  useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
    });
  }, []);

  const [audioInput, setAudioInput] = useState(false);
  const changeEnabledAudio = useStore2((state) => state.changeEnabledAudio);

  return (
    <>
      <div
        style={{ position: "fixed", zIndex: "5", top: "15px", right: "15px" }}
      >
        <Button
          onClick={() => navigate("/login")}
          variant="light"
          color="orange"
          radius="lg"
        >
          Login / Sign Up
        </Button>
      </div>
      <div ref={ref} className="content">
        <div style={{ zIndex: "3", position: "absolute" }}>
          <section className="section-one">
            <div className={classes.wrapper}>
              <Container
                size={800}
                className={classes.inner}
                style={{
                  marginLeft: "60px",
                }}
              >
                <h1
                  className={classes.title}
                  data-aos="fade-zoom-in"
                  data-aoos-duration="2000"
                >
                  The most
                  <div
                    className={styles.slidingVertical}
                    style={{
                      fontSize: 62,
                      fontWeight: 900,
                      lineHeight: 1.1,
                      margin: 10,
                      padding: 10,
                    }}
                  >
                    <span>interactive</span>
                    <span>stunning</span>
                    <span>audio-reactive</span>
                    <span>cutting-edge</span>
                    <span>3D</span>
                  </div>
                  <Text sx={{ lineHeight: 1.2 }}>
                    way to promote your music
                  </Text>
                </h1>

                <Text
                  className={classes.description}
                  data-aos="fade-zoom-in"
                  data-aos-delay="500"
                  sx={{ fontWeight: 600 }}
                >
                  Upload your{" "}
                  <span style={{ color: "#c4c4c4", fontWeight: 800 }}>
                    cover art
                  </span>
                  , add your
                  <span style={{ color: "#c4c4c4", fontWeight: 800 }}>
                    {" "}
                    track
                  </span>
                  , and SoundDrop will create an entirely new way to share your
                  music with listeners.
                </Text>

                <Group
                  className={classes.controls}
                  data-aos="fade-zoom-in"
                  data-aos-delay="1000"
                >
                  <div className={styles.box2}>
                    <Button
                      className={classes.control}
                      size="xl"
                      color="gray"
                      variant="default"
                      onClick={() => {
                        gsap.to(window, { duration: 3, scrollTo: 2200 });
                      }}
                    >
                      Learn More
                    </Button>
                  </div>

                  <Button
                    color="orange"
                    size="lg"
                    variant="light"
                    sx={{ backgroundColor: "rgba(255, 255, 255, .2)" }}
                    className={classes.control}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Get Started
                  </Button>
                </Group>
                <div style={{ marginTop: "50px" }}>
                  <div style={{ marginLeft: "15px" }}>
                    <h6
                      data-aos="fade-right"
                      data-aos-duration="950"
                      data-aos-delay="2000"
                      className={classes.title20}
                    >
                      Try these examples
                    </h6>
                  </div>
                  <Group ml={"md"} mt={"md"}>
                    <div
                      data-aos="fade-right"
                      data-aos-duration="850"
                      style={{ cursor: "pointer" }}
                      data-aos-delay="2000"
                      onClick={() => {
                        changeLandingImage("images/rufus.jpeg");
                        setTimeout(function () {
                          gsap.to(window, { duration: 3, scrollTo: 2200 });
                        }, 1200);
                      }}
                    >
                      <Image
                        width={80}
                        radius="md"
                        src="/images/rufus.jpeg"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      data-aos="fade-right"
                      data-aos-duration="850"
                      style={{ cursor: "pointer" }}
                      data-aos-delay="1850"
                      onClick={() => {
                        changeLandingImage("images/floyd.jpeg");
                        setTimeout(function () {
                          gsap.to(window, { duration: 3, scrollTo: 2200 });
                        }, 1200);
                      }}
                    >
                      <Image width={80} radius="md" src="/images/floyd.jpeg" />
                    </div>
                    <div
                      data-aos="fade-right"
                      data-aos-duration="850"
                      style={{ cursor: "pointer" }}
                      data-aos-delay="1700"
                      onClick={() => {
                        changeLandingImage("images/disclosure.jpeg");
                        setTimeout(function () {
                          gsap.to(window, { duration: 3, scrollTo: 2200 });
                        }, 1200);
                      }}
                    >
                      <Image
                        width={80}
                        radius="md"
                        src="/images/disclosure.jpeg"
                      />
                    </div>
                    <div
                      data-aos="fade-right"
                      data-aos-duration="850"
                      style={{ cursor: "pointer" }}
                      data-aos-delay="1550"
                      onClick={() => {
                        changeLandingImage("images/tame.jpeg");
                        setTimeout(function () {
                          gsap.to(window, { duration: 3, scrollTo: 2200 });
                        }, 1200);
                      }}
                    >
                      <Image width={80} radius="md" src="/images/tame.jpeg" />
                    </div>
                    <div
                      data-aos="fade-right"
                      data-aos-duration="850"
                      data-aos-delay="1400"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        changeLandingImage("images/blond.jpeg");
                        setTimeout(function () {
                          gsap.to(window, { duration: 3, scrollTo: 2200 });
                        }, 1200);
                      }}
                    >
                      <Image width={80} radius="md" src="/images/blond.jpeg" />
                    </div>
                  </Group>
                </div>
              </Container>
            </div>
          </section>
          <section className="section-two"></section>
          <section className="section-three">
            <div className={classes.wrapper}>
              <Container
                size={450}
                className={classes.inner2}
                style={{
                  marginRight: "50px",
                }}
              >
                <h1
                  className={classes.title}
                  data-aos="fade-zoom-in"
                  data-aos-duration="750"
                  onClick={() => changeLandingImage("images/rufus.jpeg")}
                >
                  Bring your cover art to
                  <div
                    data-aos="fade-left"
                    data-aos-duration="2000"
                    className={styles.gradientText}
                  >
                    LIFE
                  </div>
                </h1>
                <Text
                  // align="center"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  data-aos-delay="750"
                  sx={{ fontWeight: 550 }}
                  className={classes.description}
                >
                  SoundDrop takes your cover art and morphs it into an
                  interactive point cloud that reacts to your track in real
                  time. Just add links to your streaming platforms and get your
                  own URL to share with fans
                </Text>
              </Container>

              <div style={{ marginTop: "40px" }}>
                <div style={{ marginLeft: "50px" }}>
                  <h6
                    data-aos="fade-right"
                    data-aos-duration="950"
                    data-aos-delay="500"
                    // className={classes.title2}
                    className={classes.title20}
                  >
                    Try these examples
                  </h6>
                </div>
                <Group ml={50} mt={"md"}>
                  <div
                    data-aos="fade-right"
                    data-aos-duration="850"
                    style={{ cursor: "pointer" }}
                    data-aos-delay="500"
                    onClick={() => changeLandingImage("images/rufus.jpeg")}
                  >
                    <Image
                      width={90}
                      radius="md"
                      src="/images/rufus.jpeg"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div
                    data-aos="fade-right"
                    data-aos-duration="850"
                    style={{ cursor: "pointer" }}
                    data-aos-delay="400"
                    onClick={() => changeLandingImage("images/floyd.jpeg")}
                  >
                    <Image width={90} radius="md" src="/images/floyd.jpeg" />
                  </div>
                  <div
                    data-aos="fade-right"
                    data-aos-duration="850"
                    style={{ cursor: "pointer" }}
                    data-aos-delay="300"
                    onClick={() => changeLandingImage("images/disclosure.jpeg")}
                  >
                    <Image
                      width={80}
                      radius="md"
                      src="/images/disclosure.jpeg"
                    />
                  </div>
                  <div
                    data-aos="fade-right"
                    data-aos-duration="850"
                    style={{ cursor: "pointer" }}
                    data-aos-delay="200"
                    onClick={() => changeLandingImage("images/tame.jpeg")}
                  >
                    <Image width={90} radius="md" src="/images/tame.jpeg" />
                  </div>
                  <div
                    data-aos="fade-right"
                    data-aos-duration="850"
                    data-aos-delay="100"
                    style={{ cursor: "pointer" }}
                    onClick={() => changeLandingImage("images/blond.jpeg")}
                  >
                    <Image width={90} radius="md" src="/images/blond.jpeg" />
                  </div>
                </Group>
              </div>
            </div>
          </section>
          <section className="section-four"></section>
          <section className="section-five">
            <div className={classes.wrapper}>
              <Container
                size={800}
                className={classes.inner11}
                style={{
                  marginRight: "60px",
                }}
              >
                <h1
                  className={classes.title3}
                  data-aos="fade-zoom-in"
                  data-aoos-duration="2000"
                >
                  Create your own shareable, interactive landing pages that
                  react to your track in
                  <div
                    data-aos="fade-left"
                    data-aos-duration="2000"
                    className={styles.gradientText}
                  >
                    real time
                  </div>
                </h1>
                <Text
                  className={classes.description2}
                  color="dimmed"
                  data-aos="fade-zoom-in"
                  data-aos-delay="400"
                >
                  The ultimate link in bio
                </Text>
                <Group
                  className={classes.controls2}
                  data-aos="fade-zoom-in"
                  data-aos-delay="700"
                  position="right"
                >
                  <div className={styles.box3}>
                    <Button
                      className={classes.control}
                      size="lg"
                      color="gray"
                      variant="default"
                      onClick={() => {
                        changeEnabledAudio(true);
                        showNotification({
                          icon: <IconCheck size={18} />,
                          title: "Audio Enabled",
                          message: "Make some noise or play a track",
                          color: "teal",
                        });
                      }}
                      rightIcon={<BsMic />}
                    >
                      Enable Audio Input
                    </Button>
                  </div>
                </Group>
              </Container>
            </div>
          </section>
          <section className="section-six"></section>
          <section className="section-seven">
            <div className={classes.wrapper}>
              <Container
                size={800}
                className={classes.inner}
                style={{
                  marginLeft: "60px",
                }}
              >
                <div style={{ marginTop: "100px" }}>
                  <h1
                    className={classes.title}
                    data-aos="fade-zoom-in"
                    data-aoos-duration="2000"
                  >
                    Make it
                    <div
                      data-aos="fade-left"
                      data-aos-duration="2000"
                      className={styles.gradientText}
                    >
                      YOU
                    </div>
                  </h1>

                  <Text
                    style={{ maxWidth: "500px" }}
                    className={classes.description}
                    color="dimmed"
                    data-aos="fade-zoom-in"
                    data-aos-delay="500"
                  >
                    Customize your pages with audio-reactive backgrounds and
                    filters
                  </Text>
                  <div
                    data-aos="fade-right"
                    data-aos-delay="600"
                    data-aos-duration="1000"
                  >
                    <Segmented />
                  </div>
                </div>
              </Container>
              <Group position="right">
                <div style={{ marginTop: "30px" }}>
                  <div style={{ marginRight: "15px" }}>
                    <Group position="right">
                      <h6
                        data-aos="fade-left"
                        data-aos-duration="950"
                        data-aos-delay="500"
                        className={classes.title2}
                      >
                        Select cover art
                      </h6>
                    </Group>
                  </div>
                  <Group ml={"md"} mt={"md"}>
                    <div
                      data-aos="fade-left"
                      data-aos-duration="850"
                      style={{ cursor: "pointer" }}
                      data-aos-delay="500"
                      onClick={() => changeLandingImage("images/rufus.jpeg")}
                    >
                      <Image
                        width={115}
                        radius="md"
                        src="/images/rufus.jpeg"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      data-aos="fade-left"
                      data-aos-duration="850"
                      style={{ cursor: "pointer" }}
                      data-aos-delay="400"
                      onClick={() => changeLandingImage("images/floyd.jpeg")}
                    >
                      <Image width={115} radius="md" src="/images/floyd.jpeg" />
                    </div>
                    <div
                      data-aos="fade-left"
                      data-aos-duration="850"
                      style={{ cursor: "pointer" }}
                      data-aos-delay="300"
                      onClick={() =>
                        changeLandingImage("images/disclosure.jpeg")
                      }
                    >
                      <Image
                        width={115}
                        radius="md"
                        src="/images/disclosure.jpeg"
                      />
                    </div>
                    <div
                      data-aos="fade-left"
                      data-aos-duration="850"
                      style={{ cursor: "pointer" }}
                      data-aos-delay="200"
                      onClick={() => changeLandingImage("images/tame.jpeg")}
                    >
                      <Image width={115} radius="md" src="/images/tame.jpeg" />
                    </div>
                    <div
                      data-aos="fade-left"
                      data-aos-duration="850"
                      data-aos-delay="100"
                      style={{ cursor: "pointer" }}
                      onClick={() => changeLandingImage("images/blond.jpeg")}
                    >
                      <Image width={115} radius="md" src="/images/blond.jpeg" />
                    </div>
                  </Group>
                </div>
              </Group>
            </div>
          </section>
          <section className="section-eight">
            <div className={classes.wrapper}>
              <Container
                size={800}
                className={classes.inner9}
                style={{
                  maxWidth: "100vw",
                }}
              >
                <h1
                  className={classes.title}
                  data-aos="fade-zoom-in"
                  data-aos-duration="750"
                  onClick={() => changeLandingImage("images/rufus.jpeg")}
                >
                  <Group position="center">
                    <div
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      className={styles.gradientText}
                    >
                      LIVE MODE
                    </div>
                  </Group>
                </h1>
                <Group position="center">
                  <div
                    data-aos="fade-down"
                    data-aos-duration="3000"
                    data-aos-delay="100"
                    style={{
                      fontFamily: `Greycliff CF`,
                      fontSize: 20,
                    }}
                  >
                    <Text>
                      Connect your microphone, DAW, or DJ output for immersive
                      live visuals
                    </Text>
                  </div>
                </Group>
              </Container>
              <Group>
                <Container
                  size={400}
                  className={classes.inner10}
                  style={{
                    marginLeft: "60px",
                  }}
                >
                  <h1
                    className={classes.title4}
                    data-aos="fade-left"
                    data-aos-duration="2000"
                  >
                    Perfect for reactive visuals during
                    <span style={{ color: "#6440A6" }}> Twitch </span> or
                    <span style={{ color: "#357EFC" }}> Zoom </span> streams
                  </h1>
                </Container>
                <Container
                  size={400}
                  className={classes.inner10}
                  style={{
                    marginRight: "60px",
                  }}
                >
                  <h1
                    className={classes.title3}
                    data-aos="fade-right"
                    data-aos-duration="2000"
                  >
                    Pair with a projector while DJing or performing
                  </h1>
                </Container>
              </Group>
            </div>
          </section>
          <section className="section-nine"></section>
          <section className="section-ten">
            <div className={classes.wrapper}>
              <Container
                size={700}
                className={classes.inner2}
                style={{
                  marginRight: "50px",
                }}
              >
                <h1 className={classes.title10}>
                  <Group data-aos="fade-down" data-aos-duration="1000">
                    <Text sx={{ fontWeight: 700, marginBottom: 20 }} size={45}>
                      SoundDrop replaces the need for
                    </Text>
                  </Group>
                  <Group data-aos="fade-down" data-aos-duration="2000">
                    <IoMdClose color="red" />
                    <Text>LinkTree</Text>
                  </Group>
                  <Group
                    data-aos="fade-down"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    <IoMdClose color="red" />
                    <Text>Graphic Designer</Text>
                  </Group>
                  <Group
                    data-aos="fade-down"
                    data-aos-duration="2000"
                    data-aos-delay="1000"
                  >
                    <IoMdClose color="red" />
                    <Text>Photoshop</Text>
                  </Group>
                  <Group
                    data-aos="fade-down"
                    data-aos-duration="2000"
                    data-aos-delay="1500"
                  >
                    <IoMdClose color="red" />
                    <Text>Live Visuals Artist</Text>
                  </Group>

                  <Group
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    data-aos-delay="2000"
                    className={styles.gradientText}
                  >
                    <FiCheck color="green" />
                    <div>SoundDrop</div>
                  </Group>
                </h1>
                <Group
                  className={classes.controls}
                  data-aos="fade-zoom-in"
                  data-aos-delay="2000"
                  position="center"
                >
                  <div className={styles.box2}>
                    <Button
                      className={classes.control}
                      size={"xl"}
                      color="gray"
                      variant="default"
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Get Started
                    </Button>
                  </div>
                </Group>
              </Container>
            </div>
          </section>
        </div>
      </div>

      <div className={styles.canvas3D}>
        <LandingPage2 audioInput={audioInput} />
      </div>
    </>
  );
}

export default Content;
