import { fb } from "../Shared/Service";
import { useEffect, useState } from "react";

export const useUsernames = (userName) => {
  const [pages, setPages] = useState();

  useEffect(() => {
    const unsubscribe = userName
      ? fb.firestore
          .collection("allUsers")
          .where("userId", "==", userName)
          .onSnapshot((snap) => {
            const _pages = [];
            snap.forEach((s) => {
              _pages.push({
                ...s.data(),
                // id: s.id,
                // userName: s.userName,
              });
            });
            setPages(_pages);
          })
      : undefined;
    return unsubscribe;
  }, [userName]);
  return pages;
};
